import { PremiumTiers } from '@bridebook/toolbox';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { Slug } from '@bridebook/toolbox/src/types';

const REAL_WEDDING_MIN_TIERS: Partial<Record<CountryCodes, Partial<Record<Slug, PremiumTiers>>>> = {
  [CountryCodes.GB]: {
    venue: PremiumTiers.Tier_1,
  },
};

export const getCreatorMinTier = (country: CountryCodes) =>
  REAL_WEDDING_MIN_TIERS[country] ?? undefined;
