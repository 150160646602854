import Router from 'next/router';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import Cookies from 'universal-cookie';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { COOKIE_BB_MARKET } from 'app-shared/lib/i18n/cookies';
import { appError } from 'lib/app/actions';
import { AuthActionTypes, ISignOutCompletedAction } from 'lib/auth/action-types';
import { onboardingFinishedStorage } from 'lib/onboarding-new/onboarding-finished-storage';
import { IEpicDeps } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';

export const signOutCompletedEpic = (
  action$: Observable<ISignOutCompletedAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(AuthActionTypes.SIGN_OUT_COMPLETED),
    withLatestFrom(state$),
    mergeMap(() => {
      /** Reset onboarding state in case of logout */
      onboardingFinishedStorage.remove();

      const cookies = new Cookies();

      cookies.remove(COOKIE_BB_MARKET, {
        path: '/',
      });

      /** Reset Sentry context */
      SentryMinimal().setUser(null);

      /** Get market from url, as there is no wedding data in store at this point */

      const market = gazetteer.getMarketByURL(Router.query.market as string);

      /** Redirect after sign out is completed */
      if (Router.pathname === UrlHelper.acceptCollaborationInvite) {
        Router.replace({
          pathname: UrlHelper.signup,
          query: Router.query,
        });
      } else {
        /**
         * Enforce pass-through in monkey patching by passing
         * `locale: false`. Otherwise we'd get a double [market]
         * parameter in the URL in cases where the user locale
         * is different from the market.
         */
        Router.push(
          {
            pathname: `/${market.prefix}${UrlHelper.signup}`,
            query: {
              mode: 'login',
            },
          },
          undefined,
          { locale: false },
        );
      }
      return of();
    }),
    catchError((error) => of(appError({ error, feature: 'Auth' }))),
  );
