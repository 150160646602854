import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { TranslatedError } from '@bridebook/toolbox/src';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { appError } from 'lib/app/actions';
import { getI18n } from 'lib/i18n/getI18n';
import { fetchSupplierCollaborationSuccess } from 'lib/shortlist/actions';
import { IEpicDeps } from 'lib/types';
import { FetchSupplierCollaborationAction, ShortlistActionTypes } from '../action-types';

export const fetchSupplierCollaborationEpic = (
  action$: Observable<FetchSupplierCollaborationAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ShortlistActionTypes.FETCH_SUPPLIER_COLLABORATION),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { supplierId } = action.payload;
      const {
        weddings: {
          profile: { id: profileId },
        },
      } = state;
      const i18n = getI18n();
      const promise = authenticatedFetch(
        `/api/supplier-collaboration/fetch/${profileId}/${supplierId}`,
        {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        },
      ).then((res) => {
        if (res.status !== 200) {
          throw new TranslatedError(
            'Error fetching supplier collaboration',
            i18n.t('shortlist:error.fetchCollaboration'),
          );
        }

        return res.json();
      });

      return from(promise).pipe(
        map((payload) => fetchSupplierCollaborationSuccess(payload)),
        catchError((error) => of(appError({ error, feature: 'Shortlist' }))),
      );
    }),
  );
