import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { MobileAppActionTypes } from 'lib/mobile-app/action-types';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { logDeviceInfoError } from 'lib/mobile-app/utils/log-device-info-error';
import { Action, DevicePlatform, IEpicDeps } from 'lib/types';
import { isCordovaApp } from 'lib/utils';

export const getAppBuildInfoEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType('APP_STARTED'),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const isCordovaDevice = getIsCordova(state) || isCordovaApp();

      if (!isCordovaDevice) {
        return of();
      }

      const getPromise = async () => {
        try {
          const platform = Capacitor.getPlatform();
          return platform;
        } catch (error) {
          logDeviceInfoError({
            error,
            location: 'getAppBuildInfoEpic',
          });
          throw error;
        }
      };

      return from(getPromise()).pipe(
        mergeMap((platform) =>
          of(
            {
              type: MobileAppActionTypes.SET_PLATFORM_INFO,
              payload: platform,
            },
            {
              type: MobileAppActionTypes.WHICH_HANDHELD_DEVICE_TYPE,
              payload: {
                isAndroid: platform === DevicePlatform.ANDROID,
                isiOS: platform === DevicePlatform.IOS,
              },
            },
          ),
        ),
      );
    }),
  );
