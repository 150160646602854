import { ICost } from '@bridebook/models/source/models/Countries/Costs.types';
import { BudgetAnalyticsActionTypes } from './action-types';

export const clickedDeleteBudgetItemAnalytics = (cost: ICost) => ({
  type: BudgetAnalyticsActionTypes.CLICKED_DELETE_BUDGET_ITEM_ANALYTICS,
  payload: cost,
});

export const clickedUpdateBudgetItemsAnalytics = (itemId: string) => ({
  type: BudgetAnalyticsActionTypes.CLICKED_UPDATE_BUDGET_ITEMS,
  payload: { itemId },
});

export const followedLinkOnBudgetAnalytics = (link: string, category: string, cost: ICost) => ({
  type: BudgetAnalyticsActionTypes.FOLLOWED_LINK_ON_BUDGET_ANALYTICS,
  payload: { link, category, cost },
});

export const setBudgetInitFormFieldAnalytics = ({ target: { name, value } }: any) => ({
  type: BudgetAnalyticsActionTypes.SET_BUDGET_INIT_FORM_FIELD_ANALYTICS,
  payload: { name, value },
});

export const setBudgetInitAnalytics = (enteredTargetBudgetMethod: string) => ({
  type: BudgetAnalyticsActionTypes.SET_BUDGET_INIT_ANALYTICS,
  payload: enteredTargetBudgetMethod,
});

export const clickedResetBudgetAnalytics = () => ({
  type: BudgetAnalyticsActionTypes.CLICKED_RESET_BUDGET_ANALYTICS,
});

export const resetBudgetAnalytics = () => ({
  type: BudgetAnalyticsActionTypes.RESET_BUDGET_ANALYTICS,
});
