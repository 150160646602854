import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { delay, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AddedToBookedSuppliersAction, ShortlistActionTypes } from 'lib/shortlist/action-types';
import { IEpicDeps } from 'lib/types';
import { triggerBookingConfirmationModal } from 'lib/venue-confirmation/actions';
import { hasShownPopupToday } from 'lib/venue-confirmation/utils';
import { getVenueBooked } from 'lib/weddings/selectors';

/**
 * Opens venue confirmation popup if user marked 2 non-venue suppliers as booked
 */
export const openVenuePopupOnMarkedTwoNonVenueAsBooked = (
  action$: Observable<AddedToBookedSuppliersAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(ShortlistActionTypes.ADDED_TO_BOOKED_SUPPLIERS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const venueBooked = getVenueBooked(state);
      const booked = action.payload.item;

      if (hasShownPopupToday()) return of();
      if (!venueBooked && booked.type !== 'venue') {
        // Delay needed to fix the issue with switching two modals at the same time
        return of(
          triggerBookingConfirmationModal({
            showIntro: true,
            location: 'booked2Suppliers',
            searchSupplierType: 'venue',
          }),
        ).pipe(delay(2500));
      }

      return of();
    }),
  );
