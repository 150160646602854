// please do not remove this file. The keys are generated dynamicaly
import { IBadge } from '@bridebook/models/source/models/Weddings/Badges.types';
import { achievements } from 'components/achievements/achievements-data';
import { IBadgeState } from './types';

export const badgerAdapter = (badges: Record<string, IBadge>): Record<string, IBadgeState> => {
  const getAchievement: Record<string, any> = achievements();

  for (const key in badges) {
    badges[key] = { ...badges[key], ...getAchievement[key]?.() };
  }
  return badges as Record<string, IBadgeState>;
};
