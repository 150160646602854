import { IUISupplier } from '@bridebook/toolbox/src/types';
import { TUIShortlistSupplier } from 'lib/types';

export enum LostLeadAnalyticsAction {
  OpenedLostLeadModal = '[Analytics] Opened lost lead modal',
  ClosedLostLeadModal = '[Analytics] Closed lost lead modal',
  SubmittedLostLeadFeedback = '[Analytics] Submitted feedback in lost lead modal',
  SelectedLostLeadFeedback = '[Analytics] Selected feedback in lost lead modal',
}

export const openedLostLeadModalAnalytics = (location: string, supplier: IUISupplier) => ({
  type: LostLeadAnalyticsAction.OpenedLostLeadModal,
  payload: { location, supplier },
});

export const closedLostLeadModalAnalytics = (payload: IUISupplier) => ({
  type: LostLeadAnalyticsAction.ClosedLostLeadModal,
  payload,
});

export const submittedLostLeadFeedbackAnalytics = (payload: IUISupplier) => ({
  type: LostLeadAnalyticsAction.SubmittedLostLeadFeedback,
  payload,
});

export const selectedLostLeadFeedbackAnalytics = (
  feedback: string,
  feedbackType: string,
  supplier: TUIShortlistSupplier,
) => ({
  type: LostLeadAnalyticsAction.SelectedLostLeadFeedback,
  payload: { feedback, feedbackType, supplier },
});

export type ISelectedLostLeadFeedbackAnalytics = ReturnType<
  typeof selectedLostLeadFeedbackAnalytics
>;
export type IOpenedLostLeadModalAnalytics = ReturnType<typeof openedLostLeadModalAnalytics>;
export type IClosedLostLeadModalAnalytics = ReturnType<typeof closedLostLeadModalAnalytics>;
export type ISubmittedLostLeadFeedbackAnalytics = ReturnType<
  typeof submittedLostLeadFeedbackAnalytics
>;
