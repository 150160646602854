import type { TrackEventBodyWeb } from '@bridebook/toolbox/src/types';
import getServerSideAnalyticsProps from 'lib/utils/get-server-side-analytics-props';
import type { IServerSideTrack } from 'lib/utils/server-side-track';
import { weddingDetailsPropertiesGeneric } from 'lib/weddings/analytics';

const collaboratingSupplierTrack = ({
  state,
  event,
  identifyProps,
  specificEventProps,
  category = '',
  collaboratingSupplierId,
}: IServerSideTrack): Promise<any> => {
  const body: TrackEventBodyWeb = {
    event,
    identifyProps,
    genericProps: getServerSideAnalyticsProps(state),
    specificEventProps: {
      ...weddingDetailsPropertiesGeneric(state),
      weddingId: state.weddings.profile?.id,
      userEmail: state.users.user?.contacts?.email,
      ...specificEventProps,
    },
    source: 'web',
    category,
    collaboratingSupplierId,
  };

  return fetch(`/api/a/t/collaborating`, {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    return response;
  });
};

export default collaboratingSupplierTrack;
