import { splitEvery } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { appError } from 'lib/app/actions';
import { resetBudget } from 'lib/budget/actions';
import { IEpicDeps } from 'lib/types';
import { ShortlistActionTypes, UnbookAllVenuesAction } from '../action-types';

export const unbookAllVenuesEpic = (
  action$: Observable<UnbookAllVenuesAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(ShortlistActionTypes.UNBOOK_ALL_VENUES),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { id: weddingId } = state.weddings.profile;
      const {
        payload: { venueIds },
      } = action;
      const getPromise = async () => {
        const chunkedVenues = splitEvery(10, venueIds);
        for (const chunk of chunkedVenues) {
          await Promise.all(
            chunk.map((venueId) =>
              Weddings._.getById(weddingId).Suppliers.getById(venueId).set({ booked: false }),
            ),
          );
        }
      };

      return of(getPromise()).pipe(mergeMap(() => of(resetBudget())));
    }),
    catchError((error) => of(appError({ error, feature: 'Shortlist' }))),
  );
