import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { appError } from 'lib/app/actions';
import { setSearchLocation } from 'lib/search/actions';
import { ChangeWeddingCountryAction, SettingsActionTypes } from 'lib/settings/action-types';
import {
  changeWeddingCountryCompleted,
  changeWeddingCountryFailed,
  showLocationChangeModal,
} from 'lib/settings/actions';
import { WeddingCountryChangedStorage } from 'lib/storage-manager/basic/wedding-country-changed';
import { Action, IEpicDeps } from 'lib/types';
import { getCurrentUserId, selectUserLocale } from 'lib/users/selectors';
import { getWeddingId } from 'lib/weddings/selectors';
import { deleteMicrositeSuccessAnalytics } from './analytics/actions';
import { deleteMicrosite } from './utils/microsites';

export const deleteMicrositeEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(SettingsActionTypes.DELETE_MICROSITE),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { id: micrositeId },
        },
        state,
      ]) => {
        const { id: weddingId } = state.weddings.profile;
        const promise = deleteMicrosite({ weddingId });
        return from(promise).pipe(
          mergeMap(() => of(deleteMicrositeSuccessAnalytics({ id: micrositeId }))),
          catchError((error) => of(appError({ error, feature: 'Microsite' }))),
        );
      },
    ),
  );

export const changeWeddingCountryEpic = (
  action$: Observable<ChangeWeddingCountryAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(SettingsActionTypes.CHANGE_WEDDING_COUNTRY),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { market },
        },
        state,
      ]) => {
        const weddingId = getWeddingId(state);
        const userId = getCurrentUserId(state);
        const userLocale = selectUserLocale(state);
        const wedding = Weddings._.getById(weddingId);
        const weddingCountryChangedStorage = WeddingCountryChangedStorage(userId as string);
        const hasSeenBudgetWarningModal = state.users.user?.actions.hasSeenBudgetWarningModal;
        const doesStorageExist = weddingCountryChangedStorage.get()?.countryChanged;

        const modalAlreadyPresentedToUser = doesStorageExist || hasSeenBudgetWarningModal;

        if (!modalAlreadyPresentedToUser) {
          weddingCountryChangedStorage.set({ countryChanged: true });
        }

        return from(wedding.updateCountry(market)).pipe(
          mergeMap(() => [
            setSearchLocation({
              type: 'autocomplete',
              text: Gazetteer.getCountryName(market.country, userLocale),
            }),
            showLocationChangeModal(),
            changeWeddingCountryCompleted(),
          ]),
          catchError((error) =>
            of(
              changeWeddingCountryFailed(),
              appError({ error, feature: 'Settings => Change wedding country' }),
            ),
          ),
        );
      },
    ),
  );
