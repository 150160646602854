import { getCountryCode } from 'lib/i18n/selectors';
import { mapEventToAppsFlyerEvent } from 'lib/track-utils/mappers/event-to-appsflyer-event';
import { ITrackingMapper } from 'lib/track-utils/tracking-mappers';

export const commonMapper: ITrackingMapper = ({ event }, state) => [
  mapEventToAppsFlyerEvent(event),
  {
    country_code: getCountryCode(state),
  },
];
