import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  mergeMap,
  pluck,
  withLatestFrom,
} from 'rxjs/operators';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { appError } from 'lib/app/actions';
import { EnquiriesActionTypes } from 'lib/enquiries/action-types';
import {
  getEnquiryConfirmationScreenShown,
  getEnquiryFormEditState,
  getOnPageEnquiry,
} from 'lib/enquiries/selectors';
import { ActionWithPayload, IEnquiryFormToggleArgs, IEpic } from 'lib/types';
import enquiriesServerSideTrack from '../../utils/enquiries-server-side-track';

type InputActions = ActionWithPayload<IEnquiryFormToggleArgs>;

const enquiryFormToggleAnalyticsEpic: IEpic<InputActions> = (action$, { state$ }) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.ENQUIRY_FORM_TOGGLE),
    pluck<ActionWithPayload<IEnquiryFormToggleArgs>, IEnquiryFormToggleArgs>('payload'),
    filter(({ track, supplier }) => !!track && !!supplier),
    withLatestFrom(state$),
    mergeMap(([payload, state]) => {
      const { show, infoProps, supplier } = payload;
      const contactedSupplier = supplier as IUISupplier;
      const enquiryFormEditState = getEnquiryFormEditState(state);
      const onPageEnquiry = getOnPageEnquiry(state);
      const enquiryConfirmationScreenShown = getEnquiryConfirmationScreenShown(state);

      const confirmationScreen =
        enquiryConfirmationScreenShown && !onPageEnquiry && !enquiryFormEditState;

      const event = show
        ? 'Triggered enquiry form'
        : confirmationScreen
        ? 'Closed confirmation screen'
        : 'Closed enquiry form';

      const promise = enquiriesServerSideTrack({
        state,
        contactedSupplier,
        event,
        infoProps,
      });

      return from(promise).pipe(
        ignoreElements(),
        catchError((error: Error) =>
          of(appError({ error, feature: 'enquiryFormToggleAnalyticsEpic' })),
        ),
      );
    }),
  );

export default enquiryFormToggleAnalyticsEpic;
