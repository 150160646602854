import { ofType } from 'redux-observable';
import { filter, mapTo, withLatestFrom } from 'rxjs/operators';
import { EnquiriesActionTypes, IOpenVenueConfirmationPopup } from 'lib/enquiries/action-types';
import { IApplicationState, IEpic } from 'lib/types';
import { triggerBookingConfirmationModal } from 'lib/venue-confirmation/actions';
import { getVenueBooked } from 'lib/weddings/selectors';

/**
 * Opens venue confirmation popup when user send first non venue enquiry
 */
export const openVenueConfirmationPopup: IEpic<IOpenVenueConfirmationPopup, any> = (
  action$,
  { state$ },
) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.OPEN_CONFIRMATION_MODAL),
    withLatestFrom(state$),
    filter(hasNoVenueBooked),
    mapTo(triggerBookingConfirmationModal({ showIntro: true, location: 'enquiryOther' })),
  );

const hasNoVenueBooked = ([, state]: [any, IApplicationState]) => !getVenueBooked(state);
