import { AnyAction } from 'redux';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, pluck, take } from 'rxjs/operators';
import { IViewerCountryCodeResponse } from 'pages/api/i18n/get-viewer-country';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { appError } from 'lib/app/actions';
import { Action, IEpic } from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';
import { updateUserViewerCountry } from 'lib/users/actions';
import { noopAction } from 'lib/utils';

/**
 * Epic that gets the viewer's country code and updates the user's l10n with it.
 * We are currently fetching this only once per app lifecycle, so that only a
 * hard reload or app restart will trigger a potential update.
 */
export const getAndUpdateViewerCountryEpic: IEpic<AnyAction, any> = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(UserActionTypes.ON_USER_LISTENER),
    pluck('payload'),
    mergeMap((user) => {
      const getPromise = async () => {
        if (!user) {
          return noopAction();
        }
        const { l10n } = user;
        const response = await fetch(ApiEndpoint.i18n.getViewerCountry);
        const viewerCountry: IViewerCountryCodeResponse = await response.json();

        if (!viewerCountry?.country || viewerCountry.country === l10n?.viewerCountry) {
          return noopAction();
        }

        return updateUserViewerCountry(viewerCountry.country);
      };

      return from(getPromise()).pipe(mergeMap((action) => of(action)));
    }),
    catchError((error) => of(appError({ error, feature: 'UserViewerCountry' }))),
    take(1),
  );
