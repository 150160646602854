import { ValidationError } from '@bridebook/toolbox';
import { getI18n } from 'lib/i18n/getI18n';
import { ReviewFormFields } from 'lib/types';

export const validateReviewFormFields = async (
  formFields: ReviewFormFields,
  recaptchaComplete: boolean,
  validate: (json: object) => any,
) => {
  const i18n = getI18n();
  await validate(formFields)
    .prop('stars')
    .positiveIntNotRequired()
    .required()
    .prop('title')
    .required()
    .prop('message')
    .required()
    .prop('weddingDate')
    .required() // date
    .prop('name')
    .required()
    .prop('email')
    .email().promise;

  if (!recaptchaComplete) {
    throw new ValidationError(
      'Tick the box to help us fight spambots ;-)',
      'captcha',
      undefined,
      i18n.t('supplier:form.review.error.captcha'),
    );
  }
};
