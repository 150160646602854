import { IUISupplier } from '@bridebook/toolbox/src/types';
import { mapEventToAppsFlyerEvent } from 'lib/track-utils/mappers/event-to-appsflyer-event';
import { ITrackingMapper } from 'lib/track-utils/tracking-mappers';

export const sendEnquiryMapper: ITrackingMapper<IUISupplier> = ({ event, payload }) => [
  mapEventToAppsFlyerEvent(event),
  {
    af_content_id: payload.id,
    af_country: payload.country || payload.address?.country,
    af_region: payload.county || payload.address?.adminArea[1],
    af_content_type: payload.type,
    af_currency: payload.l10n?.currency,
    af_revenue: 1,
  },
];
