import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { GetFilesParams, GetFilesResponse } from '@bridebook/toolbox/src/booking-files/utils';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { appError } from 'lib/app/actions';
import { getOpenedSupplierDetails } from 'lib/shortlist/selectors';
import { Action, IEpicDeps } from 'lib/types';
import { FileManagementActionTypes } from '../action-types';
import { fetchFilesSuccess } from '../actions';

export const fetchFilesEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(FileManagementActionTypes.FETCH_FILES),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const {
        weddings: {
          profile: { id: weddingId },
        },
      } = state;

      const supplierId = getOpenedSupplierDetails(state)?.id || '';

      const getPromise = async () => {
        const response = await authenticatedPOST<GetFilesParams, GetFilesResponse>(
          ApiEndpoint.collaboration.getFiles,
          {
            body: { supplierId, weddingId, owner: 'wedding' },
          },
        );
        return response.files;
      };

      return from(getPromise()).pipe(
        mergeMap((files) => of(fetchFilesSuccess({ files }))),
        catchError((error) =>
          of(appError({ error, feature: FileManagementActionTypes.FETCH_FILES })),
        ),
      );
    }),
  );
