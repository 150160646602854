import { AppleSignInResult, CapacitorFirebaseAuth } from 'capacitor-firebase-auth';
import { GoogleSignInResult } from 'capacitor-firebase-auth/alternative';
import { FacebookSignInResult } from 'capacitor-firebase-auth/dist/esm/definitions';
import {
  Auth,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
  getAuth,
  linkWithCredential,
  linkWithPopup,
  signInWithCredential,
} from 'firebase/auth';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import {
  AuthActionTypes,
  ILinkEmailAccountAction,
  ILinkSocialAccountAction,
} from 'lib/auth/action-types';
import { AuthBridebookError, AuthProviders } from 'lib/auth/types';
import { getAuthProvider } from 'lib/auth/utils/auth-provider-utils';
import authValidate from 'lib/auth/utils/auth-validate';
import { IEpicDeps } from 'lib/types';
import { isCordovaApp } from 'lib/utils';
import { linkAccountsError, linkAccountsSuccess } from '../actions';

const APPLE_PROVIDER_ID = 'apple.com';
const nativeSignIn = async (providerId: string) => {
  if (providerId === GoogleAuthProvider.PROVIDER_ID) {
    const result = await CapacitorFirebaseAuth.signIn<GoogleSignInResult>({
      providerId,
    });
    return GoogleAuthProvider.credential(result.idToken);
  } else if (providerId === FacebookAuthProvider.PROVIDER_ID) {
    const result = await CapacitorFirebaseAuth.signIn<FacebookSignInResult>({
      providerId,
    });
    return FacebookAuthProvider.credential(result.idToken);
  } else if (providerId === APPLE_PROVIDER_ID) {
    const result = await CapacitorFirebaseAuth.signIn<AppleSignInResult>({
      providerId,
    });
    const provider = new OAuthProvider(APPLE_PROVIDER_ID);
    provider.addScope('email');
    provider.addScope('name');
    return provider.credential(result);
  } else {
    throw new Error('nativeSignIn: Unsupported providerId');
  }
};

const cordovaLink = async (
  firebaseAuth: Auth,
  providerId: string,
): Promise<Pick<UserCredential, 'user'>> => {
  const previousUser = firebaseAuth.currentUser;

  if (!previousUser) throw new Error('User is not signed in, cannot be linked');
  const credential = await nativeSignIn(providerId);
  if (!credential)
    throw new Error('cordovaLink: Something went wrong with getting user credential from id token');

  const response = await linkWithCredential(previousUser, credential);
  const linkCredential = OAuthProvider.credentialFromResult(response);
  if (!linkCredential)
    throw new Error('cordovaLink: Something went wrong with getting credential from link result');
  const signInWithCredentialResult = await signInWithCredential(firebaseAuth, linkCredential);
  return { user: signInWithCredentialResult.user };
};

export const linkEmailAccountEpic = (
  action$: Observable<ILinkEmailAccountAction>,
  { state$, firebaseApp }: IEpicDeps,
) =>
  action$.pipe(
    ofType(AuthActionTypes.LINK_EMAIL_ACCOUNT),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { credentials },
        },
      ]) => {
        const getPromise = async () => {
          await authValidate(credentials);
          const { email, password } = credentials;
          const { currentUser } = getAuth(firebaseApp);
          const credential = EmailAuthProvider.credential(email, password);
          if (!currentUser) {
            return;
          }

          const { user } = await linkWithCredential(currentUser, credential);

          return user;
        };

        return from(getPromise()).pipe(
          mergeMap((payload) =>
            of(
              linkAccountsSuccess({
                user: payload,
                providerId: AuthProviders.PASSWORD,
              }),
            ),
          ),
          catchError((error: AuthBridebookError) =>
            of(linkAccountsError({ error, providerId: AuthProviders.PASSWORD })),
          ),
        );
      },
    ),
    catchError((error: Error) => of(appError({ error, feature: 'Auth' }))),
  );

export const linkSocialAccountEpic = (
  action$: Observable<ILinkSocialAccountAction>,
  { firebaseApp }: IEpicDeps,
) =>
  action$.pipe(
    ofType(AuthActionTypes.LINK_SOCIAL_ACCOUNT),
    mergeMap(({ payload: { providerId } }) => {
      const getPromise = async () => {
        try {
          const defaultAuth = getAuth(firebaseApp);
          const currentUser = defaultAuth.currentUser;
          if (!currentUser) {
            throw new Error('Current user is missing');
          }
          const provider = getAuthProvider(providerId);
          const firebaseCredential = isCordovaApp()
            ? await cordovaLink(defaultAuth, providerId)
            : await linkWithPopup(currentUser, provider);

          const { user } = firebaseCredential;

          return { user, providerId };
        } catch (error) {
          const errorPayload = { error, providerId };
          throw errorPayload;
        }
      };

      return from(getPromise()).pipe(
        mergeMap((payload) =>
          of(
            linkAccountsSuccess({
              user: payload.user,
              providerId: payload.providerId,
            }),
          ),
        ),
        catchError(
          ({ error, providerId }: { error: AuthBridebookError; providerId: AuthProviders }) =>
            of(linkAccountsError({ error, providerId })),
        ),
      );
    }),
    catchError((error: Error) => of(appError({ error, feature: 'Auth' }))),
  );
