import { isEmpty } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  mergeMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { Countries, Weddings } from '@bridebook/models';
import { appError } from 'lib/app/actions';
import { ChecklistActionTypes, IFetchUserChecklistSuccessAction } from 'lib/checklist/action-types';
import { fetchTasksInitialSuccess, fetchUserChecklistSuccess } from 'lib/checklist/actions';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';
import { mapCleanTimestamps } from '../utils';

export const initChecklistListener = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(ChecklistActionTypes.INIT_LISTENER),
    withLatestFrom(state$),
    filter(([_, state]: [any, IApplicationState]) => Boolean(state.weddings.profile.id)),
    mergeMap(([_, state]: [any, IApplicationState]) => {
      const weddingId = state.weddings.profile.id;
      return Weddings._.getById(weddingId)
        .Tasks.query()
        .observe(true, true)
        .pipe(
          mergeMap((data) =>
            of(
              fetchUserChecklistSuccess({
                result: mapCleanTimestamps(data.result),
                source: data.source,
              }),
            ),
          ),
          takeUntil(action$.pipe(ofType(ChecklistActionTypes.STOP_LISTENER))),
        );
    }),
    catchError((error: Error) => of(appError({ error, feature: 'Checklist Listener' }))),
  );

/**
 * Before we run checklist listener, we need to get task names
 * from Countries collection
 */
export const fetchTasksInitialEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(ChecklistActionTypes.FETCH_TASKS_INITIAL),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const { tasksInitial } = state.checklist;
      const {
        profile: { l10n },
      } = state.weddings;
      const { country } = l10n;
      if (!isEmpty(tasksInitial)) {
        return of();
      }

      const pTasksInitial = Countries._.getById(country).getAllTasks();
      return from(pTasksInitial).pipe(
        mergeMap((payload) =>
          of(
            // @ts-ignore FIXME
            fetchTasksInitialSuccess(payload),
            {
              type: ChecklistActionTypes.INIT_LISTENER,
            },
          ),
        ),
        catchError((error) => of(appError({ error, feature: 'Checklist' }))),
      );
    }),
  );

/**
 * After we first receive checklist data, we want to initialise identify call
 * to analytics. This should be triggered only once per user session.
 */
export const initChecklistAnalyticsEpic = (
  action$: Observable<IFetchUserChecklistSuccessAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ChecklistActionTypes.FETCH_USER_CHECKLIST_SUCCESS),
    withLatestFrom(state$),
    filter(([_, state]) => !!state.weddings.profile.id),
    distinctUntilChanged(
      ([, prevState], [, nextState]) =>
        prevState.weddings.profile.id === nextState.weddings.profile.id,
    ),
    mergeMap(() => of({ type: 'CHECKLIST_INITIAL_IDENTIFY' })),
  );
