import { ofType } from 'redux-observable';
import { ignoreElements, mergeMap, pluck, tap } from 'rxjs/operators';
import { OnboardingAction } from 'lib/onboarding-new/actions';
import { CreateEnquiryResponse } from 'lib/onboarding-new/types';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import { Action, IEpic } from 'lib/types';

type InputAction = Required<Action<CreateEnquiryResponse[]>>;

/**
 * An epic that sends a Cordova tracker event for each successful enquiry during onboarding.
 *
 * Listens to the `OnboardingAction.SendEnquiriesSuccess` action and processes it by
 * mapping over each successful enquiry, extracting the supplier information, and sending
 * a Cordova tracker event to track the successful enquiry.
 */
export const sendSuccessfulCordovaTrackerEventEpic: IEpic<InputAction> = (
  action$,
  { cordovaTracker },
) =>
  action$.pipe(
    ofType(OnboardingAction.SendEnquiriesSuccess),
    pluck('payload'),
    mergeMap((enquiries) => enquiries),
    tap(({ supplier }) => cordovaTracker.track(TrackingEvent.SendEnquiry, supplier)),
    ignoreElements(),
  );
