import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { filter, ignoreElements, mergeMap, pluck, withLatestFrom } from 'rxjs/operators';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { CriticalWebEvents } from 'lib/analyticsTypes';
import { getEnquiryFormIntents } from 'lib/enquiries/selectors';
import { AttemptToSendTalkJSMessagePayload, OnboardingAction } from 'lib/onboarding-new/actions';
import { CreateEnquiryResponse } from 'lib/onboarding-new/types';
import { isBudgetMatch } from 'lib/supplier/utils/budget-match';
import { ActionWithPayload, IEpic } from 'lib/types';
import enquirySupplierTrack from 'lib/utils/server-side-track/enquiry-supplier-track';
import { weddingDetailsPropertiesGeneric } from 'lib/weddings/analytics';
import { getWeddingBudget, getWeddingProfileId } from 'lib/weddings/selectors';

type InputAction =
  | ActionWithPayload<CreateEnquiryResponse[]>
  | ActionWithPayload<AttemptToSendTalkJSMessagePayload>;

/**
 * An epic that sends an analytics event for each successful enquiry during onboarding.
 *
 * Listens to the `OnboardingAction.SendEnquiriesSuccess` action and processes it by
 * mapping over each successful enquiry, extracting the enquiry and supplier information, and
 * sending an analytics event to track the successful enquiry.
 *
 * Specifically, it sends the event 'EMAILED_SUPPLIER' to the enquiry supplier tracker, with
 * the following specific event props:
 *
 * - `enquiryId`: The ID of the enquiry that was sent.
 * - `weddingId`: The ID of the wedding profile associated with the enquiry.
 * - `contactLocation`: The location at which the contact was made, in this case 'onboarding'.
 * - `contactType`: The type of contact made, in this case 'multipleEnquiry'.
 * - `contactMessage`: The message sent to the supplier in the enquiry.
 * - `contactMethod`: The method used to contact the supplier, in this case
 *   'onboardingMultiEnquiry'.
 * - `contactIntent`: An object describing the intent of the enquiry.
 * - `budgetMatch`: A boolean indicating whether the supplier's pricing matched the user's budget.
 * - `supplierCategory`: The category of the supplier contacted.
 * - `multipleEnquiryCount`: The total number of enquiries sent during onboarding.
 * - `inboxMessageSent`: A boolean indicating whether a message was also sent via TalkJS.
 */
export const sendEnquirySuccessfulAnalyticsEventEpic: IEpic<InputAction> = (action$, { state$ }) =>
  action$.pipe(
    ofType(OnboardingAction.SendEnquiriesSuccess),
    pluck<ActionWithPayload<CreateEnquiryResponse[]>, CreateEnquiryResponse[]>('payload'),
    mergeMap((enquiries) => enquiries),
    withLatestFrom(state$),
    mergeMap(([{ supplier, suppliersCount, message, id: supplierWeddingId }, state]) => {
      const sendAnalyticsSuccessEvent = async (inboxMessageSent: boolean) => {
        const weddingId = getWeddingProfileId(state);
        const intent = getEnquiryFormIntents(state);
        const budget = getWeddingBudget(state);

        const supplierCountryCode = supplier.countryCode ?? supplier.l10n?.country;
        const supplierCountryName = supplierCountryCode
          ? Gazetteer.getCountryName(supplierCountryCode)
          : undefined;

        return enquirySupplierTrack({
          event: CriticalWebEvents.EMAILED_SUPPLIER,
          state,
          contactedSupplier: supplier,
          category: 'Supplier Engagement',

          specificEventProps: {
            enquiryId: supplierWeddingId, // Keeping incorrect key for compatibility with other events
            weddingId,
            contactLocation: 'onboarding',
            contactType: 'multipleEnquiry',
            contactMessage: message,
            contactMethod: 'onboardingMultiEnquiry',
            contactIntent: intent,
            budgetMatch: isBudgetMatch(budget, supplier),
            ...weddingDetailsPropertiesGeneric(state),
            supplierCategory: supplier.type,
            multipleEnquiryCount: suppliersCount,
            inboxMessageSent,
            supplierCountryCode,
            supplierCountryName,
          },
        });
      };

      return action$.pipe(
        ofType(OnboardingAction.AttemptToSendTalkJSNotification),
        pluck<
          ActionWithPayload<AttemptToSendTalkJSMessagePayload>,
          AttemptToSendTalkJSMessagePayload
        >('payload'),
        filter(({ supplierId }) => supplier.id === supplierId),
        mergeMap(({ inboxMessageSent }) => from(sendAnalyticsSuccessEvent(inboxMessageSent))),
        ignoreElements(),
      );
    }),
  );
