import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { auditTime, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ISupplier } from '@bridebook/models/source/models/Weddings/Suppliers.types';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { appError } from 'lib/app/actions';
import { updateUserBudget } from 'lib/budget/actions';
import { Action, IEpicDeps } from 'lib/types';
import { saveSupplierInWeddingSuppliers } from '../utils';

interface IBookingEpicCreatorArgs<A extends Action> {
  actionType: A['type'];
  analyticsType: string;
  updateCreator: (item: IUISupplier | ISupplier) => IUISupplier | ISupplier;
  time?: number;
  auditTime?: number;
}

export const bookingEpicCreator =
  <A extends Action>({
    actionType,
    analyticsType,
    updateCreator,
    time = 0,
  }: IBookingEpicCreatorArgs<A>) =>
  (action$: Observable<A>, { state$ }: IEpicDeps) =>
    action$.pipe(
      ofType(actionType),
      auditTime(time),
      withLatestFrom(state$),
      mergeMap(
        ([
          {
            payload: { item, method },
          },
          state,
        ]) => {
          const { id: weddingId } = state.weddings.profile;
          const promise = saveSupplierInWeddingSuppliers(updateCreator(item), weddingId, true);

          return from(promise).pipe(
            mergeMap(() =>
              of(updateUserBudget(item), {
                type: analyticsType,
                payload: { item, method },
              }),
            ),
          );
        },
      ),
      catchError((error) => of(appError({ error, feature: 'Shortlist' }))),
    );
