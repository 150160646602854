import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { Badges } from '@bridebook/models/source/models/Weddings/Badges';
import { appError } from 'lib/app/actions';
import checklistConnectedActions from 'lib/connected-actions/checklist';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';
import { badgesConnectedActionTypes } from './badges/actions';
import { badgesActionsHandler, getConnectedAction } from './badges/handler';

const { checklistActionsHandler, checklistConnectedActionTypes } = checklistConnectedActions;

export const connectedActionsEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(...checklistConnectedActionTypes),
    withLatestFrom(state$),
    mergeMap(([action, state]: [Action, IApplicationState]) => {
      if (checklistConnectedActionTypes.includes(action.type)) {
        return of(...checklistActionsHandler(action, state));
      }

      return of();
    }),
  );

export const badgesConnectedActionsEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(...badgesConnectedActionTypes),
    withLatestFrom(state$),
    mergeMap(([action, state]: [Action, IApplicationState]) => {
      const getPromise = async () => {
        // old users without achievements
        if (!Object.keys(state.ui.achievements).length) return false;
        const weddingId = state.weddings.profile.id;
        const wedding = Weddings._.getById(weddingId);
        const { id: badgeId } = getConnectedAction(action);
        if (!badgeId) return false;
        const badge = await wedding.Badges.getById(badgeId).get();
        if (!Badges.achieved(badge)) {
          await wedding.Badges.increment(badgeId);
          const badge = await wedding.Badges.getById(badgeId).get();
          return Badges.achieved(badge);
        }
        return false;
      };

      return from(getPromise()).pipe(
        mergeMap((showBadgeModal) =>
          showBadgeModal === true ? [...badgesActionsHandler(action, state)] : of(),
        ),
        catchError((error) => of(appError({ error, feature: 'badges' }))),
      );
    }),
  );
