import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { AuthActionTypes, IResetPasswordAction } from 'lib/auth/action-types';
import { AuthBridebookError } from 'lib/auth/types';
import { IEpicDeps } from 'lib/types';
import { resetPasswordError, resetPasswordSuccess } from '../actions';

export const resetPasswordEpic = (
  action$: Observable<IResetPasswordAction>,
  { firebaseApp, validate }: IEpicDeps,
) =>
  action$.pipe(
    ofType(AuthActionTypes.RESET_PASSWORD),
    mergeMap(({ payload: { fields } }) => {
      const getPromise = async () => {
        await validate(fields).prop('email').required().email().promise;
        const validatedFields = {
          ...fields,
          email: fields.email,
        };
        const defaultAuth = getAuth(firebaseApp);

        /**
         * Set the language to what is used on the device/browser.
         * This affects which language is used in the email sent to the user.
         **/
        defaultAuth.useDeviceLanguage();

        const response = await sendPasswordResetEmail(defaultAuth, validatedFields.email);

        return { response, fields };
      };

      return from(getPromise()).pipe(
        mergeMap(({ fields }) => of(resetPasswordSuccess(fields))),
        catchError((error: AuthBridebookError) => of(resetPasswordError(fields, error))),
      );
    }),
    catchError((error: Error) => of(appError({ error, feature: 'Auth' }))),
  );
