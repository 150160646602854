import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, pluck } from 'rxjs/operators';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { failedToSendEnquiryAnalytics } from 'lib/enquiries/analytics/actions';
import { OnboardingAction } from 'lib/onboarding-new/actions';
import { Action } from 'lib/types';

type InputAction = Required<Action<IUISupplier[]>>;

/**
 * Handles the case when sending onboarding enquiries fails.
 *
 * This epic listens for the `OnboardingAction.SendEnquiriesFailure` action, which contains
 * the payload (an array of enquiries that have failed to be sent). It then flattens this array
 * into separate Observables using the `mergeMap` operator and dispatches a new action using the
 * `of` operator. This new action contains an error object and the supplier data for the failed
 * enquiry. This new action can be used to update the store state, trigger a notification banner,
 * or perform other relevant tasks.
 */

export const failureOnboardingEnquiriesEpic = (action$: Observable<InputAction>) =>
  action$.pipe(
    ofType(OnboardingAction.SendEnquiriesFailure),
    pluck('payload'),
    mergeMap((enquiries) => enquiries),
    mergeMap((supplier) =>
      of(failedToSendEnquiryAnalytics(new Error('Failed to send onboarding enquiry.'), supplier)),
    ),
  );
