import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { GuestlistActionTypes } from 'lib/guestlist/action-types';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';
import { triggerBookingConfirmationModal } from 'lib/venue-confirmation/actions';
import { hasShownPopupToday } from 'lib/venue-confirmation/utils';
import { getVenueBooked } from 'lib/weddings/selectors';

// Opens venue confirmation popup if user clicks to export guestlist
export const openVenuePopupOnGuestlistExport = (
  action$: any,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(GuestlistActionTypes.EXPORT_GUESTLIST_CSV),
    withLatestFrom(state$),
    mergeMap(([, state]: [Action, IApplicationState]) => {
      if (hasShownPopupToday()) return of();

      const venueBooked = getVenueBooked(state);

      if (!venueBooked) {
        return of(
          triggerBookingConfirmationModal({ showIntro: true, location: 'guestlistExport' }),
        );
      }

      return of();
    }),
  );
