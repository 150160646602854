import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { ScrapbookActionTypes } from 'lib/scrapbook/action-types';
import { onFirebaseData } from 'lib/scrapbook/actions';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';

export const initScrapbookListener = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(ScrapbookActionTypes.INIT_SCRAPBOOK_LISTENER),
    withLatestFrom(state$),
    filter(([_, state]) => Boolean(state.weddings.profile.id)),
    mergeMap(([_, state]: [any, IApplicationState]) => {
      const weddingId = state.weddings.profile.id;
      return Weddings._.getById(weddingId)
        .Notes.query()
        .observe()
        .pipe(
          mergeMap((data) => of(onFirebaseData(data))),
          takeUntil(action$.pipe(ofType(ScrapbookActionTypes.STOP_SCRAPBOOK_LISTENER))),
        );
    }),
  );
