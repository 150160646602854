import { Action, IApplicationState } from 'lib/types';
import { setAchievementUnlocked, triggerAchievementPopupAnalytics } from 'lib/ui/actions';
import { BadgesConnectedActions, IBadgesConnectedAction } from './actions';

export const getConnectedAction = (action: Action): IBadgesConnectedAction => {
  const connectedActions = BadgesConnectedActions[action.type] || {};
  return Array.isArray(connectedActions)
    ? connectedActions.find((a) => a.triggerOnlyIf(action)) || {}
    : connectedActions;
};

export const badgesActionsHandler = (action: Action, state: IApplicationState) => {
  const {
    ui: { achievements },
  } = state;
  const { id } = getConnectedAction(action);

  if (!id) return [];
  const achievement = achievements[id];

  const actions: unknown[] = [
    setAchievementUnlocked(achievement),
    triggerAchievementPopupAnalytics({ achievement }),
  ];

  return actions;
};
