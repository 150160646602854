import { getAuth } from 'firebase/auth';
import { getCurrentUser } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';

/**
 * User Scoped Storage Manager
 * User scoped and versionable interface to use Local Storage. Can be used in any place.
 */

interface IUserScopedVersionableItem<D> {
  data: D;
  uid: string;
  version: string;
}

export const UserScopedVersionableStorageManager = <T extends any>({
  storageKey,
  version,
}: {
  storageKey: string;
  version: string;
}) => ({
  set: (item: T): void => {
    if (typeof window === 'undefined' || !window.localStorage) {
      return;
    }
    const uid = getAuth()?.currentUser?.uid;
    if (uid) {
      const savedObject: IUserScopedVersionableItem<T> = {
        data: item,
        uid,
        version,
      };
      window.localStorage.setItem(storageKey, JSON.stringify(savedObject));
    } else {
      console.error(
        `UserScopedVersionableStorageManager used in not logged context! storageKey ${storageKey}`,
      );
    }
  },

  get: async (): Promise<T | null> => {
    // Check support for storage - this might fail if for example app tries to access localstorage from within iframe.
    // In that case, accessing window.localStorage will throw an error, that's why it needs to be wrapped within try/catch.
    let storageSupported = false;
    try {
      storageSupported = window.localStorage && true;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (!storageSupported || typeof window === 'undefined') {
      return null;
    }

    try {
      const item = window.localStorage.getItem(storageKey);
      if (item) {
        const currentUser = await getCurrentUser();
        const savedObject: IUserScopedVersionableItem<T> = JSON.parse(item);
        if (savedObject?.uid === currentUser?.uid && savedObject.version === version) {
          return savedObject?.data;
        }
      }
      return null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `UserScopedVersionableStorageManager Error parsing state from local storage; storageKey ${storageKey}`,
      );
      return null;
    }
  },
});
