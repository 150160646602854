import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { BudgetActionTypes } from 'lib/budget/action-types';
import { onFirebaseBudget } from 'lib/budget/actions/on-listener';
import { Action, IEpicDeps } from 'lib/types';
import { mapCleanTimestamps } from '../utils';

export const initBudgetListener = (action$: Observable<Action>, { state$ }: IEpicDeps) => {
  return action$.pipe(
    ofType(BudgetActionTypes.INIT_LISTENER),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const weddingId = state.weddings.profile.id;

      const costs$ = Weddings._.getById(weddingId).Costs.query().observe();

      return costs$.pipe(
        mergeMap((costs) => of(onFirebaseBudget(mapCleanTimestamps(costs)))),
        takeUntil(action$.pipe(ofType(BudgetActionTypes.STOP_LISTENER))),
      );
    }),
  );
};
