import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { logDeviceInfoError } from 'lib/mobile-app/utils/log-device-info-error';
import { Action, DevicePlatform, IEpicDeps } from 'lib/types';
import { isCordovaApp } from 'lib/utils';

/**
 * Android back button event
 */
export const backButtonEpic = (action$: Observable<Action>, deps: IEpicDeps): Observable<any> => {
  const watchBackButton$ = (isAndroid: boolean) => {
    if (!isAndroid) {
      return of();
    }

    return new Observable((observer) => {
      const backButtonHandler = () => {
        observer.next({
          type: 'ANDROID_BACK_BUTTON_CLICKED',
        });
      };

      try {
        // @ts-ignore FIXME
        App.addListener('backButton', backButtonHandler);
      } catch (error) {
        logDeviceInfoError({
          error,
          location: 'App.addListener',
        });
      }
    });
  };

  return action$.pipe(
    ofType('APP_STARTED'),
    withLatestFrom(deps.state$),
    switchMap(([, state]) => {
      const isCordovaDevice = getIsCordova(state) || isCordovaApp();

      if (!isCordovaDevice) {
        return of();
      }

      const getPromise = async () => {
        try {
          const platform = Capacitor.getPlatform();

          return platform === DevicePlatform.ANDROID;
        } catch (error) {
          logDeviceInfoError({
            error,
            location: 'backButtonEpic',
          });
          return !!state.app.device.isAndroid;
        }
      };
      return from(getPromise()).pipe(mergeMap((isAndroid) => watchBackButton$(isAndroid)));
    }),
  );
};
