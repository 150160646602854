import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { DeviceInfo } from '@capacitor/device';
import { Keyboard } from '@capacitor/keyboard';
import { MobileAppActionTypes } from 'lib/mobile-app/action-types';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { Action, DevicePlatform, IApplicationState, IEpicDeps } from 'lib/types';
import { isCordovaApp } from 'lib/utils';

/*
 * When keyboard focuses, scroll to active DOM input element
 */
export const mobileNativeKeyboardEpic = (
  action$: Observable<Action>,
  deps: IEpicDeps,
): Observable<any> => {
  const watchKeyboard$ = (state: IApplicationState, platform: DeviceInfo['platform']) => {
    const isCordovaDevice = getIsCordova(state) || isCordovaApp();

    if (!isCordovaDevice) {
      return of();
    }

    if (platform === DevicePlatform.IOS) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }

    return new Observable((observer) => {
      const keyboardShowHandler = () => {
        if (platform === DevicePlatform.ANDROID) {
          setTimeout(() => {
            // @ts-ignoreFIXME
            document.activeElement?.scrollIntoViewIfNeeded();
          }, 300);
        } else {
          setTimeout(() => {
            // @ts-ignoreFIXME
            document.activeElement?.scrollIntoViewIfNeeded('center');
          }, 300);
        }
      };
      const keyboardWillHideHandler = () => {
        observer.next({
          type: MobileAppActionTypes.MOBILE_NATIVE_KEYBOARD_TOGGLE,
          payload: false,
        });
      };

      const keyboardWillShowHandler = () => {
        observer.next({
          type: MobileAppActionTypes.MOBILE_NATIVE_KEYBOARD_TOGGLE,
          payload: true,
        });
      };

      document.addEventListener(
        'deviceready',
        () => {
          window.addEventListener('keyboardDidShow', keyboardShowHandler);
          window.addEventListener('keyboardWillShow', keyboardWillShowHandler);
          window.addEventListener('keyboardWillHide', keyboardWillHideHandler);
        },
        false,
      );
    });
  };

  return action$.pipe(
    ofType(MobileAppActionTypes.SET_PLATFORM_INFO),
    withLatestFrom(deps.state$),
    switchMap(([{ payload: platform }, state]) => watchKeyboard$(state, platform)),
  );
};
