export enum BudgetAnalyticsActionTypes {
  CLICKED_DELETE_BUDGET_ITEM_ANALYTICS = '[Analytics] Clicked delete budget item',
  CLICKED_UPDATE_BUDGET_ITEMS = '[Analytics] Clicked update budget item',
  UPDATED_BUDGET_ITEM_ANALYTICS = '[Analytics] Updated budget item',
  DELETE_SUPPLIER_BUDGET_ANALYTICS = '[Analytics] Delete supplier budget',
  FOLLOWED_LINK_ON_BUDGET_ANALYTICS = '[Analytics] Followed link on budget',
  INITIALISE_DYNAMIC_BUDGET_CALCULATED_ANALYTICS = '[Analytics] Initialise dynamic budget calculated',
  INITIALISE_DYNAMIC_BUDGET_FAILED_TO_CALCULATE_ANALYTICS = '[Analytics] Initialise dynamic budget failed to calculate',
  SET_BUDGET_INIT_ANALYTICS = '[Analytics] Set budget init analytics',
  EDIT_SUPPLIER_BUDGET_ADDED_ANALYTICS = '[Analytics] Added budget item',
  SET_BUDGET_INIT_FORM_FIELD_ANALYTICS = '[Analytics] Set budget init form field',
  CLICKED_RESET_BUDGET_ANALYTICS = '[Analytics] Clicked reset budget',
  RESET_BUDGET_ANALYTICS = '[Analytics] Reset budget',
}
