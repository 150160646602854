import type { TrackEventBody, TrackEventBodyWeb } from '@bridebook/toolbox/src/types';
import type { IApplicationState } from 'lib/types';
import getServerSideAnalyticsProps from 'lib/utils/get-server-side-analytics-props';

interface IReviewServerSideTrack extends TrackEventBody {
  state: IApplicationState;
  event: string;
  identifyProps?: Record<string, any>;
  specificEventProps: Record<string, any>;
  viewedSupplierId: string;
  category: string;
}

const reviewTrack = ({
  state,
  event,
  identifyProps,
  specificEventProps,
  viewedSupplierId,
  category = '',
}: IReviewServerSideTrack): Promise<any> => {
  const body: TrackEventBodyWeb = {
    event,
    identifyProps,
    genericProps: getServerSideAnalyticsProps(state),
    specificEventProps,
    source: 'web',
    viewedSupplierId,
    category,
  };

  return fetch(`/api/a/t/review`, {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response;
  });
};

export default reviewTrack;
