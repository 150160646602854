import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { extractLocationName } from 'app-shared/lib/search/utils/utils';
import { AuthActionTypes } from 'lib/auth/action-types';
import { setSearchbarFormField } from 'lib/search/actions';
import { Action, IEpicDeps } from 'lib/types';
import { IUpdateWeddingAction, WeddingActionTypes } from 'lib/weddings/action-types';

export const updateSearchbarLocationOnProfileChangeEpic = (
  action$: Observable<IUpdateWeddingAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType<Action>(WeddingActionTypes.UPDATE_WEDDING, AuthActionTypes.USER_SETUP_COMPLETED),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        search: { request },
      } = state;

      const { wedding } = action.payload;
      /* If we have user wedding location now, but didn't have it before,
      then populate searchbar with user's wedding location,
      but only if user did not select any location in searchbar during this session */
      if (wedding?.location?.name && !request.area) {
        const weddingLocation = extractLocationName(wedding.location);
        return new Observable((observer) => {
          observer.next(
            // @ts-ignore FIXME
            setSearchbarFormField(weddingLocation),
          );
        });
      }

      return of();
    }),
  );
