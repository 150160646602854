/**
 * Basic Keyed Storage Manager
 *
 * A simple interface to use Local Storage. Can be used in any place.
 *
 * @param storageKey
 * @param id - store data for different users under the same storageKey
 */
interface IProps {
  storageKey: string;
  id: string;
}

export class BasicKeyedStorageManager<T extends object | string | number | boolean> {
  storageKey: string;
  id: string;

  constructor({ storageKey, id }: IProps) {
    this.storageKey = storageKey;
    this.id = id;
  }

  /**
   *  Returns the whole storage object
   */
  getStorageObject = (): Record<string, T> | null => {
    if (!window || !window.localStorage) {
      return null;
    }
    const item = window.localStorage.getItem(this.storageKey);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  };

  /**
   * Get item for particular id
   */
  get = (): T | null => {
    const storage = this.getStorageObject();
    return storage?.[this.id] || null;
  };

  /**
   * Set item for particular id
   * @param item
   */
  set = (item: T): boolean => {
    if (!window || !window.localStorage) {
      return false;
    }

    const storage = this.getStorageObject() || {};
    const newStorage: Record<string, T> = {
      ...storage,
      [this.id]: item,
    };

    window.localStorage.setItem(this.storageKey, JSON.stringify(newStorage));

    return true;
  };
}
