import { getAuth, signOut } from 'firebase/auth';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';

export const expiredCollaborationInviteSnackbarEpic = (
  action$: Observable<Action>,
  { state$, firebaseApp }: IEpicDeps,
) => {
  const showSnackbar$ = (state: IApplicationState) =>
    new Observable((observer) => {
      const { inviteExpired, nonceId } = state.auth.collaboratorInvite;
      if (inviteExpired) {
        observer.next(
          toggleSnackbar(
            'info',
            'This invitation link has already been used, so no changes have been made to this profile.',
          ),
        );
      } else if (nonceId) {
        signOut(getAuth(firebaseApp));
      }

      observer.next({ type: 'COLLABORATOR_CHECK_INVITE_EXPIRY' });
    });
  return action$.pipe(
    ofType('APP_STARTED'),
    withLatestFrom(state$),
    switchMap(([, state]) => showSnackbar$(state)),
    catchError((error) => of(appError({ error, feature: 'Auth' }))),
  );
};
