import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { User } from 'talkjs/types/talk.types';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { ChatUserOptions } from '@bridebook/toolbox/src/inbox/types';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { appError } from 'lib/app/actions';
import { getCoupleChatUserOptions } from 'lib/inbox/selectors';
import { TalkJsManager } from 'lib/inbox/talkjs';
import { IEpic } from 'lib/types';
import { noopAction } from 'lib/utils';
import { MobileAppActionTypes, RegisterTalkJsDeviceAction } from '../action-types';

export const registerTalkJsDeviceEpic: IEpic<RegisterTalkJsDeviceAction> = (action$, { state$ }) =>
  action$.pipe(
    ofType(MobileAppActionTypes.REGISTER_TALKJS_DEVICE),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { token } = action.payload;
      const { platform } = state.mobileapp;
      const chatUserOptions = getCoupleChatUserOptions(state);

      if (!platform || !chatUserOptions) {
        return of(noopAction());
      }

      const getPromise = async () => {
        const talkManager = await TalkJsManager();
        const talkSession = await talkManager.get(chatUserOptions);
        /**
         * In order to always make sure the user is in TalkJS database, we need to
         * create it using TalkJS API. Creating it with SDK (this is what
         * essentially is done by TalkJSManager.get(chatUserOptions) method)
         * is not enough, because * it creates user only once Chatbox or Inbox
         * widget gets mounted.
         *
         * This is important specially for new users, because they won't have any
         * conversations, so the widget won't be mounted and user won't be created,
         * yet we will still call `setPushRegistration()` below.
         *
         * In case user already exists in TalkJS DB, this won't change anything
         * (for example user.updated event won't be triggered on TalkJS side).
         */
        await authenticatedPOST<ChatUserOptions, User>(
          ApiEndpoint.inbox.update(chatUserOptions.id),
          {
            body: chatUserOptions,
          },
        );

        /**
         * The `registerDevice()` is deprecated and, internally, will call `clearPushRegistrations()` before passing on to `setPushRegistration()`.
         * The `clearPushRegistrations()` method states the following in its documentation:
         * > Note that you must ensure that the user exists in the TalkJS database before you call this method.
         *
         * Failing to account for this will result in a request of the form:
         * > api/v0/-----//nyms/-----/push_registration
         *
         * Which will in turn result in the following error:
         * > [TalkJS], Cannot unregister this device. Please contact us to get more information.
         *
         * The consecutive forward slash in `//nyms/` represents an empty/missing internal User ID interpolation.
         * To maintain existing `registerDevice()` behaviour, we will call `setPushRegistration()` directly and conditionally invoke `clearPushRegistrations()`.
         */

        if (talkSession.me?.id) {
          try {
            await talkSession.clearPushRegistrations();
          } catch (error) {
            // Ignore
          }
        }

        await talkSession.setPushRegistration({
          provider: platform === 'ios' ? 'apns' : 'fcm',
          pushRegistrationId: token,
        });
      };

      return from(getPromise()).pipe(
        map(() => ({ type: MobileAppActionTypes.REGISTER_TALKJS_DEVICE_SUCCESS })),
        catchError((error) => of(appError({ error, feature: 'Register TalkJS Device' }))),
      );
    }),
  );
