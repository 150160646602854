import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, pluck, take } from 'rxjs/operators';
import { Action, IApplicationState } from 'lib/types';
import { IWeddingsState } from 'lib/weddings/types';

/**
 * Waits for/retrieve wedding locale data after it has been updated.
 * @param state$
 * @returns source action and wedding locale data
 */
export const withCurrentWeddingCountry =
  <A extends Action>(state$: StateObservable<IApplicationState>) =>
  (source: Observable<A>) =>
    source.pipe(
      mergeMap((action: A) =>
        state$.pipe(
          pluck<IApplicationState, IWeddingsState>('weddings'),
          filter(({ profile }) => !!profile.l10n?.country),
          map((wedding) => ({
            weddingCountry: wedding.profile.l10n.country,
            action,
            state: state$.value,
          })),
          take(1),
        ),
      ),
    );
