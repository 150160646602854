import Router from 'next/router';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  ignoreElements,
  map,
  pluck,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import gazetteer, { Market } from '@bridebook/toolbox/src/gazetteer';
import polyglot from '@bridebook/toolbox/src/polyglot';
import { getIsSupplierPreview } from 'app-shared/lib/supplier/utils/get-is-supplier-preview';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { appError } from 'lib/app/actions';
import { supplierContentTranslationsReady } from 'lib/content-translations/actions';
import { FetchSupplierSuccessAction, SupplierActions } from 'lib/supplier/actions-types';
import { IEpic } from 'lib/types';
import { withMarket } from 'lib/utils/operators/with-market';

export const triggerSupplierContentTranslationsEpicFactory: (
  ids: Set<string>,
) => IEpic<FetchSupplierSuccessAction> =
  (ids) =>
  (action$, { state$, muteActions }) =>
    action$.pipe(
      ofType(SupplierActions.FETCH_SUPPLIER_SUCCESS),
      pluck<FetchSupplierSuccessAction, ISupplier>('payload', 'supplier'),
      // Make sure it triggers only when supplier id changes
      distinctUntilKeyChanged('id'),
      // Make sure it triggers only once for each supplier, if it's not a preview and if supplier has
      // no translations
      filter((supplier) => !ids.has(supplier.id) && !getIsSupplierPreview(Router.query)),
      withMarket(state$),
      filter(shouldTriggerTranslations),
      switchMap(({ input: supplier, market }) => {
        // Promise that triggers the translations for the supplier. Returns the supplier and market.
        const getPromise = async (): Promise<{ supplier: ISupplier; market: Market }> => {
          await authenticatedPOST(ApiEndpoint.translations.supplier, {
            body: {
              supplierId: supplier.id,
              supplierCountry: supplier.l10n.country,
              targetLang: market.language,
            },
          });
          ids.add(supplier.id);
          return { supplier, market };
        };

        return from(getPromise()).pipe(
          switchMap(({ market }) =>
            // Once translations are triggered, we need to wait for the supplier to be updated with the new
            // translations. We listen for changes in the supplier document and wait for the translations to be
            // available in the target language.
            Suppliers._.getById(supplier.id)
              .observe()
              .pipe(
                filter((supplier) => {
                  const canonical = resolveCanonicalLanguage(supplier, market);
                  return supplier._translations?.[canonical.target] !== undefined;
                }),
                map(() => muteActions.dispatch(supplierContentTranslationsReady(supplier.id))),
                take(1),
                // Stop listening for supplier changes when route changes. We don't want anything to
                // happen after the route changes.
                takeUntil(action$.pipe(ofType('ROUTE_CHANGE_START'))),
                ignoreElements(),
              ),
          ),
          catchError((error: Error) =>
            of(
              appError({
                error,
                feature: `Trigger content translations for supplier ${supplier.id}`,
              }),
            ),
          ),
        );
      }),
    );

export const triggerSupplierContentTranslationsEpic = triggerSupplierContentTranslationsEpicFactory(
  new Set(),
);

const resolveCanonicalLanguage = (supplier: ISupplier, market: Market) => {
  const supplierMarket = gazetteer.getMarketByCountry(supplier.l10n.country);
  const supplierLanguage = supplier.l10n.language ?? supplierMarket.language;

  return polyglot.resolve({
    language: {
      target: market.language,
      source: supplierLanguage,
    },
  });
};

/**
 * Should trigger translations if:
 * - supplier language is different from market language
 * - supplier has no translations for market
 * - market supports supplier language
 */
const shouldTriggerTranslations = ({
  market,
  input: supplier,
}: {
  market: Market;
  input: ISupplier;
}) => {
  const supplierMarket = gazetteer.getMarketByCountry(supplier.l10n.country);
  const supplierLanguage = supplier.l10n.language ?? supplierMarket.language;

  // If the supplier language is the same as the market language, we shouldn't trigger translations.
  if (supplierLanguage === market.language) {
    return false;
  }

  // If the current market language is not supported by the supplier market, we shouldn't trigger translations.
  if (supplierMarket?.languages?.includes(market.language) !== true) {
    return false;
  }

  try {
    const canonical = resolveCanonicalLanguage(supplier, market);

    // If the supplier has already been translated to the canonical target language, we shouldn't trigger translations.
    if (supplier._translations?.[canonical.target] !== undefined) {
      return false;
    }
  } catch (error) {
    // If the target language is not supported by Polyglot, we shouldn't trigger translations.
    return false;
  }

  return true;
};
