import { TrackingEvent } from 'lib/track-utils/tracking-event';

export const mapEventToAppsFlyerEvent = (event: TrackingEvent) => eventToAppsFlyerEvent[event];

enum AppsflyerTrackingEvent {
  AfContentView = 'af_content_view',
  AfAddToCart = 'af_add_to_cart',
  SendEnquiry = 'af_purchase',
  InitializedChecklist = 'initialised_checklist',
  UpdatedMainTasks = 'updated_main_tasks',
  UpdatedSubtasks = 'updated_subtasks',
  InitializedGuestList = 'initialised_guest_list',
  AddedMultipleGuests = 'added_multiple_guests',
  AddedGuest = 'added_guest',
  CalculatedBudget = 'calculated_budget',
  UpdatedBudget = 'updated_budget',
  UpdatedBudgetItem = 'updated_budget_item',
}

const eventToAppsFlyerEvent = {
  [TrackingEvent.ViewedSupplierProfile]: AppsflyerTrackingEvent.AfContentView,
  [TrackingEvent.ShortlistedSupplier]: AppsflyerTrackingEvent.AfAddToCart,
  [TrackingEvent.InitializedChecklist]: AppsflyerTrackingEvent.InitializedChecklist,
  [TrackingEvent.UpdatedMainTasks]: AppsflyerTrackingEvent.UpdatedMainTasks,
  [TrackingEvent.UpdatedSubtasks]: AppsflyerTrackingEvent.UpdatedSubtasks,
  [TrackingEvent.InitializedGuestList]: AppsflyerTrackingEvent.InitializedGuestList,
  [TrackingEvent.AddedMultipleGuests]: AppsflyerTrackingEvent.AddedMultipleGuests,
  [TrackingEvent.AddedGuest]: AppsflyerTrackingEvent.AddedGuest,
  [TrackingEvent.CalculatedBudget]: AppsflyerTrackingEvent.CalculatedBudget,
  [TrackingEvent.UpdatedBudget]: AppsflyerTrackingEvent.UpdatedBudget,
  [TrackingEvent.SendEnquiry]: AppsflyerTrackingEvent.SendEnquiry,
  [TrackingEvent.UpdatedBudgetItem]: AppsflyerTrackingEvent.UpdatedBudgetItem,
};
