import { bookingEpicCreator } from 'lib/shortlist/utils/booking-creator';
import { ShortlistActionTypes, ShortlistUnbookedSupplierAction } from '../action-types';

export const shortlistUnbookSupplierEpic = bookingEpicCreator<ShortlistUnbookedSupplierAction>({
  actionType: ShortlistActionTypes.UNBOOK_SUPPLIER,
  analyticsType: ShortlistActionTypes.UNBOOK_SUPPLIER_ANALYTICS,
  updateCreator: (item) => ({
    ...item,
    booked: false,
  }),
});
