import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { Appsflyer } from '@ionic-native/appsflyer';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { Action, IEpicDeps } from 'lib/types';
import { isCordovaApp } from 'lib/utils';

export const appsFlyerPBTracking = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType('PB_STATUS_ANALYTICS'),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { pbStatus },
        },
        state,
      ]) => {
        const isCordovaDevice = getIsCordova(state) || isCordovaApp();

        if (isCordovaDevice && Appsflyer && !pbStatus) {
          Appsflyer.logEvent('af_nonpb_registration', {});
        }

        return of({
          type: 'APPSFLYER_PB_TRACKING',
        });
      },
    ),
  );
