import { Observable, from, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { getSupplierUrl } from '@bridebook/toolbox/src';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { appError } from 'lib/app/actions';
import { getWeddingVenue } from 'lib/shortlist/selectors';
import { Action, IEpicDeps } from 'lib/types';
import { withMarket } from 'lib/utils/operators/with-market';
import { getNearLocation } from '../selectors';
import {
  fetchRealWeddings as fetchRealWeddingsAction,
  fetchRealWeddingsError,
  fetchRealWeddingsSuccess,
} from '../slice';
import { ElasticRealWedding, RealWedding } from '../types';
import { getCreatorMinTier } from '../utils';

const feature = 'Real Weddings';

export const fetchRealWeddings = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    filter((action) => fetchRealWeddingsAction.match(action)),
    withMarket(state$),
    mergeMap(({ market, input, state }) => {
      const getPromise = async (): Promise<RealWedding[]> => {
        const supplier = state.supplier.supplier.data;
        const listType = input.payload;
        const defaultArea = market.getCountryName();

        const requestPayload = (() => {
          if (listType === 'near') {
            const location = getNearLocation(state) || market.getCountryName();

            return {
              county: location,
              creatorMinTier: getCreatorMinTier(market.country),
            };
          }

          if (listType === 'venue') {
            const weddingVenue = getWeddingVenue(state);
            if (!weddingVenue) return {};

            return {
              supplierId: weddingVenue.id,
            };
          }

          return {
            supplierId: supplier?.id,
          };
        })();

        if (!requestPayload.county && !requestPayload.supplierId) return [];

        if (requestPayload.county === defaultArea) {
          requestPayload.county = '';
        }

        let results: ElasticRealWedding[] = [];

        const size = listType === 'supplier' ? 10000 : 10;

        // latest appear first only on supplier profile, for home profile score (even if booked venue or not)
        const sort = listType === 'supplier' ? 'latestUploaded' : 'randomizedByProfileScore';

        try {
          const response = (await authenticatedFetch('/api/search/works', {
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            method: 'POST',
            body: JSON.stringify({ ...requestPayload, size, sort }),
          }).then((r) => r.json())) as { results: ElasticRealWedding[] };

          results = response.results || [];
        } catch {
          // Do nothing on error
        }

        return results
          .map((elasticRecord) => {
            const photoSupplier = elasticRecord.suppliers.find(
              (s) => s.supplierCategory === 'photo',
            );
            const venueSupplier = elasticRecord.suppliers.find(
              (s) => s.supplierCategory === 'venue',
            );

            const getUrl = (s: ElasticRealWedding['suppliers'][number]) =>
              getSupplierUrl({
                address: s.address,
                id: s.supplierId,
                name: s.supplierName,
                publicId: s.supplierPublicId,
                type: [s.supplierCategory],
                seo: {
                  urlSlug: s.seoUrlSlug,
                },
              } as unknown as Parameters<typeof getSupplierUrl>[0]);

            const photoUrl = photoSupplier ? getUrl(photoSupplier) : '';
            const venueUrl = venueSupplier ? getUrl(venueSupplier) : '';

            return {
              ...elasticRecord,
              photoUrl,
              venueUrl,
            } as RealWedding;
          })
          .filter(
            (realWedding) =>
              /**
               * Filter out RW which does not meet the current requirements.
               * Everything is based around our venues and potentially other
               * suppliers (therefore venue URL must be set). Since this is
               * about a RW, we require at least one photo to be shown in
               * the FE.
               */
              !!realWedding.url &&
              realWedding.suppliers?.length > 0 &&
              realWedding.photos?.length > 0 &&
              realWedding.venueUrl,
          );
      };

      return from(getPromise()).pipe(
        mergeMap((realWeddings) => of(fetchRealWeddingsSuccess(realWeddings))),
        catchError((error) => of(appError({ error, feature }), fetchRealWeddingsError(error))),
      );
    }),
  );
