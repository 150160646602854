import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, distinctUntilChanged, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Users } from '@bridebook/models';
import { updateAccessControlSuccess } from 'lib/access-control/actions';
import { appError } from 'lib/app/actions';
import { IEpicDeps } from 'lib/types';
import { IOnUserListenerAction, UserActionTypes } from 'lib/users/action-types';

export const readAccessControl = (
  action$: Observable<IOnUserListenerAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(UserActionTypes.ON_USER_LISTENER),
    withLatestFrom(state$),
    distinctUntilChanged(
      ([{ payload: previousUser }], [{ payload: nextUser }, state]) =>
        previousUser !== null &&
        nextUser !== null &&
        previousUser.id === nextUser.id &&
        !!state.weddings.profile.id,
    ),
    mergeMap(([{ payload: user }, state]) => {
      if (!user?.id) {
        return of();
      }

      const getPromise = async () => {
        const userEntity = Users._.getById(user.id);
        const activeWedding = await userEntity.getActiveWedding();
        const { profile } = state.weddings;
        const needToUpdateProfileState = !profile?.id;

        return { activeWedding, needToUpdateProfileState };
      };

      return from(getPromise()).pipe(
        mergeMap(({ needToUpdateProfileState, activeWedding }) => {
          if (needToUpdateProfileState && activeWedding) {
            return of(updateAccessControlSuccess(activeWedding));
          }

          return of();
        }),
        catchError((error: Error) => of(appError({ error, feature: 'Read Access Control' }))),
      );
    }),
    catchError((error: Error) => of(appError({ error, feature: 'Read Access Control' }))),
  );
