import Router from 'next/router';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import getAuthStatus from 'lib/auth/utils/get-auth-status';
import { Action, IEpicDeps } from 'lib/types';
import { UIActionTypes } from 'lib/ui/action-types';
import { UrlHelper } from 'lib/url-helper';
import { noopAction } from 'lib/utils';

export const debouncedGoBackHomeEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(UIActionTypes.DEBOUNCED_GO_BACK_HOME),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { back },
        },
        state,
      ]) => {
        const { previousQuery } = state.app;

        /**
         * Only pop the nav stack if the market is the same,
         * since we could get into weird issues (e.g double
         * market prefix in the URL) when further navigating
         * throughout the app.
         */
        if (back && Router.query?.market === previousQuery?.market) {
          Router.back();
        } else {
          const loggedIn = getAuthStatus({ user: state.users.user });
          Router.push(loggedIn ? UrlHelper.home : `${UrlHelper.index}${Router.query.market}`);
        }

        return of(noopAction());
      },
    ),
  );
