import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { getCurrentUser } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { AuthActionTypes } from 'lib/auth/action-types';
import { setSearchLocationEnd } from 'lib/search/actions';
import { SearchLocationStorage } from 'lib/search/search-location-storage';
import { TSearchLocationInitialized } from 'lib/types';
import { noopAction } from 'lib/utils';

const getInitialSearchLocation: () => Promise<TSearchLocationInitialized> = async () => {
  const initialSearchLocation: TSearchLocationInitialized = {
    selected: {
      area: '',
      placeId: '',
      searchText: '',
      searchType: 'autocomplete',
    },
    draft: {
      searchText: '',
      searchType: 'autocomplete',
    },
    status: 'initialized',
  };

  if (typeof window === 'undefined') return initialSearchLocation;

  try {
    const stored = await SearchLocationStorage.get();
    if (stored && Gazetteer.isValidCountryCode(stored.selected.countryCode)) return stored;
    else return initialSearchLocation;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error parsing SEARCH_LOCATION_STORAGE_KEY', e);
    SentryMinimal().captureException(e, {
      tags: {
        source: 'initSearchLocationEpic',
      },
    });
    return initialSearchLocation;
  }
};

export const initSearchLocation = (action$: Observable<any>) =>
  action$.pipe(
    ofType('APP_STARTED', AuthActionTypes.USER_SETUP_COMPLETED),
    mergeMap(() => {
      const getPromise = async () => {
        const currentUser = await getCurrentUser();
        if (!currentUser) {
          return noopAction();
        }
        const initialSearchLocation = await getInitialSearchLocation();
        return setSearchLocationEnd(initialSearchLocation);
      };

      return from(getPromise());
    }),
  );
