import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { GuestlistActionTypes } from 'lib/guestlist/action-types';
import { onFirebaseGuestlist } from 'lib/guestlist/actions';
import { Action, IEpicDeps } from 'lib/types';
import { mapCleanTimestamps } from '../utils';

export const initGuestlistListener = (action$: Observable<Action>, { state$ }: IEpicDeps) => {
  return action$.pipe(
    ofType(GuestlistActionTypes.INIT_LISTENER),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const weddingId = state.weddings.profile.id;

      const guests$ = Weddings._.getById(weddingId).Guests.query().observe();

      return guests$.pipe(
        mergeMap((guests) => {
          return of(onFirebaseGuestlist(values(mapCleanTimestamps(guests))));
        }),
        takeUntil(action$.pipe(ofType(GuestlistActionTypes.STOP_LISTENER))),
      );
    }),
  );
};
