import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { delay, mergeMap, withLatestFrom } from 'rxjs/operators';
import { BudgetActionTypes, ISaveBudgetSubitemAction } from 'lib/budget/action-types';
import { IEpicDeps } from 'lib/types';
import { saveVenueEstimate, triggerBookingConfirmationModal } from 'lib/venue-confirmation/actions';
import { hasShownPopupToday } from 'lib/venue-confirmation/utils';
import { getVenueBooked } from 'lib/weddings/selectors';

// opens venue confirmation popup when any budget item from budgetItemTriggers is filled
export const openVenuePopupOnBudgetChange = (
  action$: Observable<ISaveBudgetSubitemAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(BudgetActionTypes.SAVE_BUDGET_SUBITEM),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        payload: { supplierType: budgetSupplierType, actual },
      } = action;
      const venueBooked = getVenueBooked(state);

      if (hasShownPopupToday()) return of();

      const isVenue = budgetSupplierType === 'venue';

      if (!venueBooked && Math.floor(actual) > 0) {
        const analyticsLocation = `budget_${budgetSupplierType}`;

        const successActions = [];

        successActions.push(
          triggerBookingConfirmationModal({ showIntro: true, location: analyticsLocation }),
        );
        if (isVenue) successActions.push(saveVenueEstimate(Math.floor(actual)));

        // Delay needed to fix the issue with switching two modals at the same time
        return of(...successActions).pipe(delay(0));
      }

      return of();
    }),
  );
