import { IApplicationState } from 'lib/types';
import variationTest from './variationTest';

export default function testingPropertiesGeneric(getState: () => IApplicationState): {
  url: string;
} {
  const url = variationTest(getState);
  return {
    url,
  };
}
