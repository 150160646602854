import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { getCoupleChatUserOptions } from 'lib/inbox/selectors';
import { registerTalkJsDevice } from 'lib/mobile-app/actions';
import { IEpic } from 'lib/types';
import { WeddingActionTypes } from 'lib/weddings/action-types';

export const scheduleRegisterTalkJsDeviceEpic: IEpic = (action$, { state$ }) =>
  action$.pipe(
    ofType('PUSH_NOTIFICATIONS_REGISTER_DEVICE_SUCCESS'),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const token = action.payload;
      const chatUserOptions = getCoupleChatUserOptions(state);

      // If wedding loaded then register device with token
      if (chatUserOptions) {
        return of(registerTalkJsDevice({ token }));
      }
      // Otherwise, wait for the wedding update first
      return action$.pipe(
        ofType(WeddingActionTypes.UPDATE_WEDDING),
        take(1),
        withLatestFrom(state$),
        mergeMap(() => of(registerTalkJsDevice({ token }))),
      );
    }),
  );
