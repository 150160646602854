import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { setEnquiryFormField } from 'lib/enquiries/actions';
import { IFetchSearchSuccessAction, SearchActionTypes } from 'lib/search/action-types';
import { IEpicDeps } from 'lib/types';
import { updateWeddingField } from 'lib/weddings/actions';

export const updateWeddingLocationOnSearchEpic = (
  action$: Observable<IFetchSearchSuccessAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(SearchActionTypes.UPDATE_WEDDING_LOCATION_ON_SEARCH),
    withLatestFrom(state$),
    mergeMap(([{}, state]) => {
      const { user } = state.users;
      const weddingLocation = state.weddings?.profile?.location;
      const { results, totalResults } = state.search;

      if (totalResults > 0 && user && results.status === 'loaded' && !weddingLocation) {
        const adminArea0 = results.fields.addressComponents?.administrative_area_level_1 || '';
        const adminArea1 = results.fields.addressComponents?.administrative_area_level_2 || '';
        const adminArea = [adminArea1, adminArea0];
        const location: IWedding['location'] = {
          adminArea,
          country: results.fields.addressComponents?.country || CountryCodes.GB,
          name: results.fields.area,
        };

        return of(
          updateWeddingField('location', { location }),
          setEnquiryFormField('location', location),
        );
      }

      return of();
    }),
  );
