import { isToday } from 'date-fns';
import { VenueConfirmationStorage } from 'lib/storage-manager/basic/venue-confirmation';

/**
 * Returns true if the Venue Confirmation Popup has already been shown today
 */
export const hasShownPopupToday = () => {
  const popupShownDate = VenueConfirmationStorage.get();
  return popupShownDate && isToday(new Date(popupShownDate));
};
