import Router from 'next/router';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { mapSlugToFriendlyUrlSlug } from '@bridebook/toolbox/src/mapSlugToFriendlyUrlSlug';
import type { Slug } from '@bridebook/toolbox/src/types';
import { updateSearchCategory } from 'lib/search/actions';
import { isSearchResultsPage } from 'lib/search/utils';
import { Action, IEpicDeps } from 'lib/types';
import { toggleSearchBar } from 'lib/ui/actions';

/**
 * On supplier SEO pages, update searchbar category according to
 * SEO page category
 * @param action$
 * @param state$
 */
export const updateSearchbarOnRouteChangeEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    filter((action) => action.type === 'ROUTE_CHANGE_COMPLETE' || action.type === 'APP_STARTED'),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const {
        search: {
          request: { slug },
        },
        app: { previousPath, pathname },
      } = state;

      const isSuppliersSEOPage =
        pathname.includes('/wedding-suppliers') || pathname.includes('/supplier-category');

      let nextCategory = '';

      if (isSuppliersSEOPage) {
        // @ts-ignore FIXME
        const queryString = process.browser ? Router.query : {};
        nextCategory = mapSlugToFriendlyUrlSlug(queryString.id as Slug).oldSlug || slug;
      }

      if (nextCategory || previousPath !== pathname) {
        return new Observable<
          ReturnType<typeof toggleSearchBar> | ReturnType<typeof updateSearchCategory>
        >((observer) => {
          /* Show searchbar when route is changed */
          if (previousPath !== pathname) {
            observer.next(toggleSearchBar(true));
          }

          if (nextCategory && nextCategory !== slug) {
            observer.next(
              updateSearchCategory(nextCategory as Slug, isSearchResultsPage(pathname)),
            );
          }
        });
      }

      return of();
    }),
  );
