import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { toggleQuiz } from 'lib/quiz/slice';
import {
  AddedToBookedSuppliersAction,
  FetchVenueRecommendedSuppliersSuccessAction,
  ShortlistActionTypes,
} from 'lib/shortlist/action-types';
import { selectLastBookedPhotographer } from 'lib/shortlist/selectors';
import { IApplicationState, IElasticSupplier, IEpicDeps } from 'lib/types';
import { setNextAction, showNextAction } from 'lib/ui/actions';
import { withCurrentWeddingCountry } from 'lib/utils/operators/with-current-wedding-locale';

const shouldActivateDiscoverPhotographersNextAction = (state: IApplicationState) => {
  return false; // This is temp until we enable discover photographers in DE

  const lastBookedPhotographer = selectLastBookedPhotographer(state);
  if (lastBookedPhotographer) {
    return false;
  }
  return true;
};

const shouldDisplayLinkedSupplierQuiz = (
  state: IApplicationState,
  recommendations: IElasticSupplier[],
  weddingCountry: CountryCodes,
) => {
  const market = gazetteer.getMarketByCountry(weddingCountry as CountryCodes);
  const isUK = weddingCountry && [CountryCodes.GB].includes(market.country);
  const hasSeenRecommendedSuppliers = state.users.user?.actions.hasSeenRecommendedSuppliers;
  return isUK && recommendations.length > 0 && !hasSeenRecommendedSuppliers;
};

export const onVenueBookedSetDiscoverPhotographers = (
  action$: Observable<AddedToBookedSuppliersAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(ShortlistActionTypes.ADDED_TO_BOOKED_SUPPLIERS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const booked = action.payload.item;
      const isVenue = action.payload.item.type === 'venue';
      if (isVenue && booked && state.venueConfirmation.triggerLocation === 'countdownWidget') {
        return of(
          setNextAction({
            id: 'venueBooked',
            type: 'venueConfirmation',
            action: 'countdown',
            active: false, // do not show but just preload for better performance
          }),
        );
      }

      if (booked && isVenue) {
        if (!shouldActivateDiscoverPhotographersNextAction(state)) return of();

        return of(
          setNextAction({
            id: 'venueBooked',
            type: 'venueConfirmation',
            action: 'discoverPhotographers',
            active: false, // do not show but just preload for better performance
            triggerAction: action,
          }),
        );
      }

      return of();
    }),
  );

export const onVenueBookedConfirmedShowDiscoverPhotographers = (
  action$: Observable<FetchVenueRecommendedSuppliersSuccessAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(ShortlistActionTypes.FETCH_VENUE_RECOMMENDED_SUPPLIERS_SUCCESS),
    withCurrentWeddingCountry(state$),
    filter(({ state }) => !state.newonboarding.onboardingModalVisible),
    mergeMap(({ state, action, weddingCountry }) => {
      if (shouldDisplayLinkedSupplierQuiz(state, action.payload, weddingCountry)) {
        return of(toggleQuiz({ open: true, quizId: 'atYourVenue', quizSource: 'multiEnquiry' }));
      }

      if (!shouldActivateDiscoverPhotographersNextAction(state)) return of();

      return of(showNextAction());
    }),
  );
