import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import {
  auditTime,
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, Token } from '@capacitor/push-notifications';
import { appError } from 'lib/app/actions';
import { AuthActionTypes } from 'lib/auth/action-types';
import { BudgetActionTypes } from 'lib/budget/action-types';
import { ChecklistActionTypes } from 'lib/checklist/action-types';
import { EnquiriesActionTypes } from 'lib/enquiries/action-types';
import {
  receivedPushNotification,
  registerPushNotifications,
  registerPushNotificationsDeviceError,
  registerPushNotificationsError,
  savePushNotificationsRegistrationToken,
  setPushPrePromptShown,
} from 'lib/mobile-app/actions';
import { ShortlistActionTypes } from 'lib/shortlist/action-types';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';
import { togglePushNotificationOptIn } from 'lib/ui/actions';
import { UrlHelper } from 'lib/url-helper';
import { isCordovaApp, noopAction } from 'lib/utils';
import { matchesAny } from 'lib/utils/strings';
import { MobileAppActionTypes } from '../action-types';
import { PN_OPT_IN_SHOW_MAX, PN_OPT_IN_STORAGE_KEY } from '../constants';
import { isCordovaPluginAvailable } from '../utils/cordova-plugin-check';
import { logDeviceInfoError } from '../utils/log-device-info-error';
import { getPrefNumber, getPreferences } from '../utils/preferences-wrapper';

let pushNotificationsRegistered = false;

export const clearPushNotificationsRegisterFlag = () => {
  pushNotificationsRegistered = false;
};

/**
 * Entry point for requesting push notification permissions.
 */
const requestPushNotificationPermissions$ = (state: IApplicationState): Observable<Action> =>
  new Observable((observer) => {
    const asyncRequestPushNotificationPermissions = async () => {
      const {
        app: {
          device: { isCordova },
        },
        ui: { pushNotificationOptInShown },
      } = state;

      /**
       * First and foremost, check of we are running from within the app.
       */
      if (!isCordova) {
        return;
      }

      /**
       * If we did show the pre-prompt, hide it.
       */
      if (pushNotificationOptInShown) {
        observer.next(togglePushNotificationOptIn(false));
      }

      /**
       * Return, if the user denied or granted the permissions in an earlier session.
       **/
      const { receive: permissions } = await PushNotifications.checkPermissions();
      if (['granted', 'denied'].includes(permissions)) {
        return;
      }

      try {
        /**
         * Request permission to use push notifications:
         *  - Android/iOS will prompt user and return if they granted permission or not
         **/
        const result = await PushNotifications.requestPermissions();

        /**
         * We did not receive permissions to receive PNs, so we also
         * don't need to register for push notifictions.
         */
        if (result.receive !== 'granted') {
          return;
        }

        /** Once we received permissions, register the device */
        observer.next(registerPushNotifications());
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    (async () => {
      try {
        await asyncRequestPushNotificationPermissions();
      } catch (error) {
        logDeviceInfoError({
          error,
          location: 'requestPushNotificationPermissions',
        });
      }
    })();
  });

export const requestPushNotificationPermissionsOptInEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<Action> =>
  action$.pipe(
    ofType(MobileAppActionTypes.REQUEST_PUSH_NOTIFICATION_PERMISSIONS),
    withLatestFrom(state$),
    switchMap(([, state]) => requestPushNotificationPermissions$(state)),
  );

export const registerPushNotificationEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<Action> =>
  action$.pipe(
    ofType(MobileAppActionTypes.REGISTER_PUSH_NOTIFICATIONS),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const isCordova = state.app.device.isCordova || isCordovaApp();

      const getPromise = async () => {
        /**
         * If we are not in the app or the registration already has happened,
         * we stop the process.
         **/
        if (!isCordova || pushNotificationsRegistered) {
          return;
        }

        /**
         * If we don't have the necessary permissions, we don't need to register
         * for push notifiations.
         **/
        const { receive: permissions } = await PushNotifications.checkPermissions();

        if (permissions !== 'granted') {
          return;
        }

        /** Gate against multiple callings */
        pushNotificationsRegistered = true;

        await PushNotifications.register();
      };

      return from(getPromise()).pipe(
        mapTo(noopAction()),
        catchError((error) => of(registerPushNotificationsDeviceError(error))),
      );
    }),
  );

export const registerPushNotificationErrorEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action) =>
        action.type === 'REGISTER_PUSH_NOTIFICATIONS_ERROR' ||
        action.type === 'PUSH_NOTIFICATIONS_REGISTER_DEVICE_ERROR',
    ),
    mergeMap((action) => {
      logDeviceInfoError({
        error: action.payload,
        extra: { type: action.type },
        location: 'registerPushNotificationErrorEpic',
      });

      return of<Action>();
    }),
  );

/** Register all necessary app listeners on login */
export const registerPushNotificationsListenerEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<any> => {
  /**
   * We need to put the listeners into an observable and make sure
   * that the observer.next calls are contained within the callback
   * of the listener, so that events are emitted at the right point
   * in time.
   */
  const addPushListeners$ = () =>
    new Observable((observer) => {
      /**
       * Gate access for `removeAllDeliveredNotifications` which will fail
       * only for the first time calling, since the system prompt will
       * trigger the background state and the registration races in
       * this case with the listener, so that for some occurences
       * `removeAllDeliveredNotifications` will reject with an error
       * that the device is not yet registered.
       **/
      let registrationSuccessful = false;

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        registrationSuccessful = true;
        observer.next(savePushNotificationsRegistrationToken(token.value));
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        observer.next(registerPushNotificationsError(error));
      });

      // Method called when tapping on a notification
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (pushEvent: ActionPerformed) => {
          observer.next(receivedPushNotification(pushEvent.notification));
        },
      );

      /**
       * Called when the app transitions between foreground and background.
       * Have in mind that this is also triggered if the app is in foreground
       * and a system dialogue (e.g. asking for permissions) is shown.
       **/
      App.addListener('appStateChange', async (appState) => {
        if (appState.isActive && registrationSuccessful) {
          const { receive: permissions } = await PushNotifications.checkPermissions();
          if (permissions === 'granted') {
            PushNotifications.removeAllDeliveredNotifications();
          }
        }
      });

      observer.next(registerPushNotifications());
    });

  return action$.pipe(
    ofType(AuthActionTypes.USER_SETUP_COMPLETED),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const isCordova = state.app.device.isCordova || isCordovaApp();
      const { user } = state.users;

      if (!isCordova || !user?.id) {
        return of(noopAction());
      }

      /**
       * We preliminary call out for registering this device,
       * the calling site will check if all prerequisites are
       * met and depending on that NOPs or registers the device.
       **/
      return addPushListeners$();
    }),
  );
};

/* Save push device token to mixpanel api */
export const pushNotificationsSaveDeviceTokenEpics = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<Action> =>
  action$.pipe(
    filter((action) => action.type === 'PUSH_NOTIFICATIONS_REGISTER_DEVICE_START'),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { user } = state.users;
      const { token } = action.payload;

      if (!token || !user?.id) {
        return of<Action>();
      }

      const requestAddToken = async () => {
        const platform = Capacitor.getPlatform();

        const res = await authenticatedFetch(`/api/push-notification/add-device-token`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({
            token,
            platformId: platform,
          }),
        });

        return res.json();
      };

      return from(requestAddToken()).pipe(
        map(() => ({
          type: 'PUSH_NOTIFICATIONS_REGISTER_DEVICE_SUCCESS',
          payload: token,
        })),
        catchError((error) => of(registerPushNotificationsDeviceError(error))),
      );
    }),
  );

export const removePushNotificationTokenOnSignOutEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<Action> =>
  action$.pipe(
    filter((action) => action.type === AuthActionTypes.SIGN_OUT_COMPLETED),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const { isCordova } = state.app.device;

      const getPromise = async () => {
        if (
          isCordova &&
          (isCordovaPluginAvailable('Preferences') || isCordovaPluginAvailable('Storage'))
        ) {
          await PushNotifications.removeAllListeners();
          await getPreferences().remove({
            key: PN_OPT_IN_STORAGE_KEY,
          });
          clearPushNotificationsRegisterFlag();
        }
      };

      return from(getPromise()).pipe(
        mapTo(noopAction()),
        catchError((error) => of(appError({ error, feature: 'Push Notifications' }))),
      );
    }),
  );

/**
 * This is now the main entry point for granting push notifications in the app.
 * We have multiple considerations, when asking the user for permissions:
 *  1. We will always show the push pre-prompt and not only the system dialog
 *  2. We will show the permissions only once per session
 *  3. For now we will only ask the user twice for permissions
 */
export const showPushNotificationOptInPopupEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<Action> =>
  action$.pipe(
    withLatestFrom(state$),
    filter(
      ([action]) =>
        action.type === ChecklistActionTypes.UPDATE_SUBTASKS_SUCCESS ||
        action.type === ChecklistActionTypes.UPDATE_TASK_SUCCESS ||
        (action.type === BudgetActionTypes.TOGGLE_BUDGET_DRAWER && action.payload.show) ||
        action.type === EnquiriesActionTypes.SEND_ENQUIRY_SUCCESS ||
        action.type === ShortlistActionTypes.SAVE_TO_SHORTLIST ||
        (action.type === 'ROUTE_CHANGE_COMPLETE' &&
          /**
           * It might be questionable but this is the easiest way
           * to match a given URL within a pathname, which could
           * include query strings and is prefixed with a market.
           **/
          typeof action.payload?.url === 'string' &&
          [UrlHelper.home, UrlHelper.inbox.main, UrlHelper.advice].some((url) =>
            action.payload.url.includes(url),
          )),
    ),
    auditTime(process.env.NODE_ENV === 'test' ? 0 : 300),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const actions: Action[] = [];
      const getPromise = async () => {
        const {
          device: { isCordova },
          pathname,
        } = state.app;
        const { pushPrePromptShown } = state.mobileapp;

        /**
         * Return early if:
         * - User is not on mobile
         * - If the pre prompt was already shown in this session
         **/
        if (!isCordova || pushPrePromptShown) {
          return;
        }

        /**
         * Return, If we already either have all the necessary
         * permissions or if the user denied the permissions in
         * an earlier session.
         **/
        const { receive: permissions } = await PushNotifications.checkPermissions();
        if (['granted', 'denied'].includes(permissions)) {
          return;
        }

        /** Fetch data about how many times the pre prompt was shown already */
        const pushPrePromptCount = await getPrefNumber({
          key: PN_OPT_IN_STORAGE_KEY,
        });

        /** Do not show opt-in popup in those paths, e.g. onboarding */
        const OPT_IN_EXCLUDED_PATHS: Array<RegExp> = [/^[/]onboarding(?:[/]|$)/];
        const isExcludedPath = matchesAny(pathname, OPT_IN_EXCLUDED_PATHS);

        /** Don't show pre prompt if we already have reached our pre-prompt limit */
        const toShow = pushPrePromptCount < PN_OPT_IN_SHOW_MAX && !isExcludedPath;

        if (toShow) {
          actions.push(togglePushNotificationOptIn(true));
          actions.push(setPushPrePromptShown());
        }

        return;
      };

      return from(getPromise()).pipe(mergeMap(() => of(...actions)));
    }),
  );
