import { serverTimestamp } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { auditTime, catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { env } from 'lib/env';
import { ShortlistActionTypes } from 'lib/shortlist/action-types';
import { getWeddingVenue } from 'lib/shortlist/selectors';
import { Action, IApplicationState, IDeps, IEpic, IEpicDeps } from 'lib/types';
import { noopAction } from 'lib/utils';
import { appError } from '../../app/actions';
import {
  IFetchCountryBoundsStartAction,
  IUpdateWeddingFieldAction,
  IUpdateWeddingPreferencesAction,
  IWeddingProfileSaveDateAction,
  WeddingActionTypes,
} from '../action-types';
import { fetchCountryBoundsSuccess, pbStatusAnalytics, updateWeddingField } from '../actions';
import { getVenueBooked, getWeddingProfileId } from '../selectors';
import { ILatLongBounds } from '../types';

const saveDate = async (
  datePickerId: 'weddingDate' | 'engagementDate',
  getState: IDeps['getState'],
) => {
  const {
    datepicker: { instances },
  } = getState();

  const datePickerDate = instances[datePickerId].datePickerDate;

  return { datePickerDate };
};

export const weddingProfileSaveDateEpics = (
  action$: Observable<IWeddingProfileSaveDateAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(WeddingActionTypes.SAVE_WEDDING_DATE),
    auditTime(300),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { datePickerId },
        },
        state,
      ]) =>
        from(saveDate(datePickerId, () => state)).pipe(
          map(() => ({
            type: WeddingActionTypes.SAVE_WEDDING_DATE_SUCCESS,
            // for analytics
            payload: { name: datePickerId },
          })),
          catchError((error) =>
            of(appError({ error, feature: 'Wedding' }), {
              type: WeddingActionTypes.SAVE_WEDDING_DATE_ERROR,
              payload: error,
            }),
          ),
        ),
    ),
  );

/**
 * Function `updateBookingsVenueEpic`
 * Listens to shortlist actions in order to
 * update bookings.venue (previously 'venueBooked')
 *
 * @function updateBookingsVenueEpic
 * @param {action$, IDeps}
 */
export const updateBookingsVenueEpic: IEpic = (action$, { state$ }) =>
  action$.pipe(
    ofType(
      ShortlistActionTypes.ON_SUPPLIERS_SHORTLISTED_SUCCESS,
      ShortlistActionTypes.ON_REMOVED_SUPPLIERS_FROM_SHORTLIST,
    ),
    auditTime(250),
    withLatestFrom(state$),
    mergeMap(([, state]: [Action, IApplicationState]) => {
      const {
        app: { pathname },
      } = state;

      //We want to check if the shortlist is loaded to prevent changing the bookings value
      //when ON_REMOVED_SUPPLIERS_FROM_SHORTLIST or ON_SUPPLIERS_SHORTLISTED_SUCCESS
      //is emitted first and the state is not ready yet
      if (!state.shortlist.loaded) {
        return of(noopAction());
      }

      const weddingVenue = getWeddingVenue(state);
      const venueBooked = getVenueBooked(state);
      const isOnboarding = pathname.startsWith('/onboarding/venue');
      const actions: Action[] = [];
      if ((weddingVenue && !venueBooked) || isOnboarding) {
        actions.push(updateWeddingField('bookings', { bookings: { venue: true } }));
        actions.push(pbStatusAnalytics(false));
      } else if (!weddingVenue && venueBooked) {
        actions.push(updateWeddingField('bookings', { bookings: { venue: false } }));
        actions.push(pbStatusAnalytics(true));
      }

      return of(...actions);
    }),
  );

export const updateWeddingFieldEpic = (
  action$: Observable<IUpdateWeddingFieldAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(WeddingActionTypes.UPDATE_WEDDING_FIELD),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { name, value, extraAnalytics },
        },
        state,
      ]) => {
        const {
          weddings: { profile },
        } = state;

        if (!profile?.id) {
          return of();
        }

        return from(
          Weddings._.getById(profile.id).set(value, name === 'location' ? [name] : true),
        ).pipe(
          mergeMap(() =>
            of({
              type: WeddingActionTypes.UPDATE_WEDDING_FIELD_SUCCESS,
              payload: { name, value, extraAnalytics },
            }),
          ),
          catchError((error) =>
            of(appError({ error, feature: 'Wedding' }), {
              type: WeddingActionTypes.UPDATE_WEDDING_FIELD_ERROR,
              payload: { name, value, error },
            }),
          ),
        );
      },
    ),
  );

export const fetchCountryBoundsEpic = (action$: Observable<IFetchCountryBoundsStartAction>) =>
  action$.pipe(
    ofType(WeddingActionTypes.FETCH_PROFILE_COUNTRY_BOUNDS_START),
    mergeMap(({ payload }) => {
      // Temporary exception to show autocomplete suggestions for Illinois
      // @see https://bridebook.atlassian.net/browse/LIVE-12910
      const countryCode = payload === CountryCodes.US ? 'US_Illinois' : payload;

      const promise = fetch(
        `${env.STATIC}/static/country-lat-long-bounds/${countryCode}.json`,
      ).then((data) => data.json() as Promise<ILatLongBounds>);

      return from(promise).pipe(
        map((countryLatLongBounds) => fetchCountryBoundsSuccess(countryLatLongBounds)),
        catchError((error) =>
          of(appError({ error, feature: 'Wedding' }), {
            type: WeddingActionTypes.FETCH_PROFILE_COUNTRY_BOUNDS_ERROR,
            payload: error,
          }),
        ),
      );
    }),
  );

export const updateWeddingPreferencesEpic = (
  action$: Observable<IUpdateWeddingPreferencesAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(WeddingActionTypes.UPDATE_WEDDING_PREFERENCES),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const getPromise = async () => {
        const { id: preferenceId, property, value } = payload;
        const weddingId = getWeddingProfileId(state);
        const wedding = Weddings._.getById(weddingId);
        const preferences = await wedding.Preferences.getById(preferenceId).get();
        if (preferences) {
          wedding.Preferences.getById(preferenceId).set({ [property]: value });
        } else {
          wedding.Preferences.getById(preferenceId).set({
            createdAt: serverTimestamp(),
            id: preferenceId,
            [property]: value,
          });
        }
      };

      return from(getPromise()).pipe(
        mergeMap(() => of()),
        catchError((error: Error) =>
          of(appError({ error, feature: 'Updating wedding preferences' })),
        ),
      );
    }),
  );
