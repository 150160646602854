import { isEmpty } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, withLatestFrom } from 'rxjs/operators';
import { ValidationError } from '@bridebook/toolbox/src';
import { Action, IEpicDeps } from 'lib/types';
import { WeddingActionTypes } from '../weddings/action-types';
import {
  changeTabSuccess,
  nextTab,
  resetDatepickerDate,
  tabIndex,
  toggleDatePicker,
} from './actions';
import validateDatepickerDate from './utils/validate-datepicker-date';

export const changeTabEpics = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType('CHANGE_TAB_START'),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { datePickerDate, nextTab, toValidate, datePickerId } = action.payload;
      const datepickerInstance = state.datepicker.instances[datePickerId];
      const { datepicker } = datepickerInstance;

      const hasMonth =
        !isEmpty(datePickerDate.month) ||
        datePickerDate.monthUndecided ||
        !isEmpty(datePickerDate.season);

      const getPromise = async () => {
        if (tabIndex(nextTab) < tabIndex(datepicker.activeTab)) {
          return { tab: nextTab, datePickerId };
        } else if (datePickerDate && toValidate) {
          if (nextTab === 'day-screen' && !hasMonth) {
            // @ts-ignore FIXME
            throw new ValidationError('Please choose a month first', 'day-screen');
          }
          validateDatepickerDate(datePickerDate, datepicker);
        }
        return { tab: nextTab, datePickerId };
      };

      return from(getPromise()).pipe(
        map(changeTabSuccess),
        catchError((error) =>
          of({
            type: 'CHANGE_TAB_ERROR',
            payload: { error: error.params, datePickerId },
          }),
        ),
      );
    }),
  );

export const closeDatepickerOnProfileUpdateEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(WeddingActionTypes.UPDATE_WEDDING_FIELD_SUCCESS),
    mergeMap(({ payload }) => {
      const { name } = payload;
      if (name === 'date') {
        return of(toggleDatePicker('weddingDate', false));
      }
      return of();
    }),
  );

export const animeDatePickedEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType('DATEPICKER_CHANGE_TAB_SUCCESS'),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { datePickerId } = action.payload;
      const getPromise = async () => {
        const datepickerInstance = state.datepicker.instances[datePickerId];
        const { datepicker, datePickerDate } = datepickerInstance;
        return validateDatepickerDate(datePickerDate, datepicker);
      };
      return from(getPromise()).pipe(
        map(() => ({
          type: 'DATEPICKER_VALIDATE_CURRENT_TAB_SUCCESS',
          payload: { datePickerId },
        })),
        catchError((error) =>
          of({
            type: 'DATEPICKER_VALIDATE_CURRENT_TAB_ERROR',
            payload: { error, datePickerId },
          }),
        ),
      );
    }),
  );

export const nextTabEpics = (action$: Observable<Action>) =>
  action$.pipe(
    ofType('CHANGE_DATEPICKER_DATE'),
    mergeMap(({ payload: { datePickerId } }) => of(nextTab(datePickerId))),
  );

export const changeDatepickerDateEpics = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType('CHANGE_DATEPICKER_DATE_START'),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        payload: { datePickerId, name, value, method },
      } = action;
      const obs$ = of({
        type: 'CHANGE_DATEPICKER_DATE',
        payload: { name, value, datePickerId, method },
      });

      if (name === 'year' || name === 'yearUndecided') {
        return obs$.pipe(startWith(resetDatepickerDate(datePickerId, {})));
      }

      if (name === 'month' || name === 'season' || name === 'monthUndecided') {
        const datepickerInstance = state.datepicker.instances[datePickerId];

        const {
          datePickerDate: { year, yearUndecided },
        } = datepickerInstance;

        return obs$.pipe(startWith(resetDatepickerDate(datePickerId, { year, yearUndecided })));
      }

      return obs$;
    }),
  );
