import { __, compose, contains, prop } from 'ramda';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';

export const datepickerAnalyticsEpics = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    filter(
      compose(
        contains(__, [
          'START_DATEPICKER_DATE_EDIT',
          'CANCEL_DATEPICKER_EDIT',
          'DATEPICKER_CHANGE_TAB_SUCCESS',
          'CHANGE_DATEPICKER_DATE',
        ]),
        prop('type'),
      ),
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]: [Action, IApplicationState]) => {
      const { type, payload } = action;

      switch (type) {
        case 'START_DATEPICKER_DATE_EDIT':
        case 'CANCEL_DATEPICKER_EDIT': {
          const { track = true } = payload;
          if (!track) {
            return of();
          }
          return of({
            type: 'TOGGLED_DATEPICKER_ANALYTICS',
            payload: {
              ...payload,
              datepickerToggledStatus: type === 'START_DATEPICKER_DATE_EDIT',
            },
          });
        }
        case 'DATEPICKER_CHANGE_TAB_SUCCESS': {
          const { tabClickedByUser } = state.datepicker;
          return tabClickedByUser
            ? of(
                {
                  type: 'TOGGLED_SCREEN_ON_DATEPICKER_ANALYTICS',
                  payload,
                },
                { type: 'TAB_CLICKED_BY_USER_RESET' },
              )
            : of();
        }
        case 'CHANGE_DATEPICKER_DATE':
          return payload.value
            ? of({
                type: 'UPDATED_DATEPICKER_ANALYTICS',
                payload,
              })
            : of();
        default:
          return of();
      }
    }),
  );

type DatepickerDateType = 'engagementDate' | 'weddingDate';

interface DatepickerPropertiesGeneric {
  datepickerDateType: DatepickerDateType;
  datepickerCurrentScreen: string;
  // agreed with Ferg to not use it for now
  // datepickerCurrentScreenConfig: Object,
  datepickerDateValue: Object;
}

type DatePickerId = 'engagementDate' | 'weddingDate' | 'venue-confirm';

const getDatepickerDateType = (datePickerId: string): DatepickerDateType =>
  datePickerId === 'weddingDate' || datePickerId === 'engagementDate'
    ? datePickerId
    : 'weddingDate';

type DatepickerLocation =
  | 'onboarding'
  | 'settings'
  | 'venueConfirmationPopup'
  | 'enquiry'
  | 'checklist'
  | null;

const getDatepickerLocation = (getState: () => IApplicationState): DatepickerLocation | null => {
  const {
    venueConfirmation: { showVenueConfirm },
    app: { pathname },
    enquiries: { showEnquiryForm },
  } = getState();
  let location: DatepickerLocation = null;
  if (showVenueConfirm) {
    location = 'venueConfirmationPopup';
  } else if (showEnquiryForm) {
    location = 'enquiry';
  } else if (pathname.indexOf('/onboarding') === 0) {
    location = 'onboarding';
  } else if (pathname.indexOf('/settings') === 0) {
    location = 'settings';
  } else if (pathname.indexOf('/checklist') === 0) {
    location = 'checklist';
  }

  return location;
};

export default function datepickerAnalytics(
  { type, payload }: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const getDatepicker = (datePickerId: DatePickerId) =>
    getState().datepicker.instances[datePickerId];
  const getScreen = (activeTab: string): string => activeTab.replace(/-.*/, '');

  const datepickerPropertiesGeneric = (datePickerId: DatePickerId): DatepickerPropertiesGeneric => {
    const {
      datePickerDate,
      // datePickerDate: { year, month },
      datepicker: { activeTab },
    } = getDatepicker(datePickerId);

    // const getConfig = () => {
    //   const calendarMode = !!year && !!month;
    //   const valuesMap = {
    //     engagementDate: {
    //       'year-screen': 'specific',
    //       'month-screen': 'specific',
    //       'day-screen': 'specific',
    //     },
    //     weddingDate: {
    //       'year-screen': 'specific',
    //       'month-screen': 'both',
    //       'day-screen': calendarMode ? 'both' : 'vague',
    //     },
    //   };
    //   return valuesMap[datePickerId][activeTab];
    // };

    return {
      datepickerDateType: getDatepickerDateType(datePickerId),
      datepickerCurrentScreen: getScreen(activeTab),
      // agreed with Ferg to not use it for now
      // datepickerCurrentScreenConfig: getConfig(),
      datepickerDateValue: datePickerDate,
    };
  };
  const updatedDatepickerPropertiesGeneric = () => {
    const { name, value, method } = payload;
    return {
      datepickerField: name,
      datepickerMethod: /.*ndecided.*/.test(name) ? 'checkbox' : method,
      datepickerValue: value,
    };
  };
  const filterLabelProps = (props: Record<string, any>) => {
    const newProps = { ...props };

    delete newProps.datepickerToggledStatus;
    delete newProps.datepickerDateValue;

    return newProps;
  };
  const { track } = bridebookAnalytics.getMethods('Datepicker', filterLabelProps);

  switch (type) {
    case 'TOGGLED_SCREEN_ON_DATEPICKER_ANALYTICS': {
      const { datePickerId } = payload;
      track({
        event: 'Toggled screen on datepicker',
        ...datepickerPropertiesGeneric(datePickerId),
      });
      break;
    }
    case 'UPDATED_DATEPICKER_ANALYTICS': {
      const { datePickerId } = payload;
      track({
        event: 'Updated datepicker',
        ...datepickerPropertiesGeneric(datePickerId),
        ...updatedDatepickerPropertiesGeneric(),
        datepickerLocation: getDatepickerLocation(getState),
      });
      break;
    }
    case 'TOGGLED_DATEPICKER_ANALYTICS': {
      const { datepickerToggledStatus, datePickerId } = payload;
      track({
        event: 'Toggled datepicker',
        ...datepickerPropertiesGeneric(datePickerId),
        datepickerToggledStatus,
      });
      break;
    }
    default:
      break;
  }
}
