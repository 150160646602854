import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { concatMap, delay, map, mergeMap, pluck, withLatestFrom } from 'rxjs/operators';
import { sendInboxMessageForEnquiry } from 'lib/enquiries/utils/send-inbox-message-for-enquiry';
import { DELAY_MS } from 'lib/inbox/constants';
import { OnboardingAction, attemptToSendTalkJSMessage } from 'lib/onboarding-new/actions';
import { CreateEnquiryResponse } from 'lib/onboarding-new/types';
import { ActionWithPayload, IEpic } from 'lib/types';

type InputAction = ActionWithPayload<CreateEnquiryResponse[]>;

/**
 * An epic that attempts to send a TalkJS notification for each successful enquiry during
 * onboarding.
 *
 * Listens to the `OnboardingAction.SendEnquiriesSuccess` action and processes it by
 * mapping over each successful enquiry, extracting the supplier information and the enquiry
 * message, and attempting to send a TalkJS notification if the user's inbox is available for the
 * supplier's location. Sends a `OnboardingAction.AttemptToSendTalkJSNotification` action to
 * report the success or failure of the notification attempt.
 */
export const attemptToSendTalkJSMessageEpic: IEpic<InputAction> = (action$, { state$ }) => {
  const enquiries$ = action$.pipe(
    ofType(OnboardingAction.SendEnquiriesSuccess),
    pluck('payload'),
    concatMap((enquiries) =>
      from(enquiries).pipe(concatMap((enquiry) => of(enquiry).pipe(delay(DELAY_MS)))),
    ),
  );

  return enquiries$.pipe(
    withLatestFrom(state$),
    mergeMap(([enquiry, state]) =>
      from(sendInboxMessageForEnquiry(enquiry, state)).pipe(map(attemptToSendTalkJSMessage)),
    ),
  );
};
