import { IUISupplier } from '@bridebook/toolbox/src/types';

export enum VenueConfirmSlides {
  intro = 'intro',
  venueSelect = 'venueSelect',
  supplierInterest = 'supplierInterest',
  lostLead = 'lostLead',
  weddingMonth = 'weddingMonth',
  weddingYear = 'weddingYear',
  weddingDay = 'weddingDay',
  customSupplier = 'customSupplier',
}

export enum VenueFoundAnswers {
  yes = 'yes',
  no = 'no',
  unselected = '',
}

export interface IVenueConfirmState {
  showVenueConfirm: boolean;
  venueToBook: IUISupplier | null;
  currentSlide: VenueConfirmSlides;
  hasFoundVenue: VenueFoundAnswers;
  triggerLocation: string;
  venuePrice: number;
  weddingYear: number | null;
  weddingMonth: number | null;
  weddingDay: number | null;
  // Required for Are you still interested in this supplier? modal
  supplierIdToConfirm?: string | null;
  supplierToBook: IUISupplier | null;
  showIntro: boolean;
  searchSupplierType?: IUISupplier['type'] | null;
}
