import { ofType } from 'redux-observable';
import { from, merge } from 'rxjs';
import { filter, map, mergeMap, pluck, share, withLatestFrom } from 'rxjs/operators';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { getEnquiryDates, getEnquiryFormIntents } from 'lib/enquiries/selectors';
import createEnquiry from 'lib/enquiries/utils/create-enquiry';
import { filterOutUrls, isSpam } from 'lib/enquiries/utils/spam-check';
import { env } from 'lib/env';
import {
  OnboardingAction,
  SendEnquiriesPayload,
  sendEnquiriesFailure,
  sendEnquiriesSuccess,
} from 'lib/onboarding-new/actions';
import { CreateEnquiryResponse } from 'lib/onboarding-new/types';
import { isBudgetMatch } from 'lib/supplier/utils/budget-match';
import { Action, IApplicationState, IEpic } from 'lib/types';
import { getCurrentUserId } from 'lib/users/selectors';
import { getWeddingBudget, getWeddingProfileId } from 'lib/weddings/selectors';

type InputAction = Required<Action<SendEnquiriesPayload>>;

/**
 * An epic that sends enquiries to suppliers during onboarding.
 *
 * Listens to the `OnboardingAction.SendEnquiries` action and processes it by
 * creating an array of enquiry promises that contact each supplier and sending
 * them to the server. The server responds with either a successful response or
 * an unsuccessful response.
 *
 * Filters out spam messages, checks if the user has reached the enquiries limit,
 * and has a valid user ID. Maps over the successful and unsuccessful enquiries
 * and dispatches the appropriate actions based on whether or not there were any
 * successful enquiries.
 */
export const sendOnboardingEnquiriesEpic: IEpic<InputAction> = (action$, { state$ }) => {
  const enquirie$ = action$.pipe(
    ofType(OnboardingAction.SendEnquiries),
    pluck('payload'),
    withLatestFrom(state$),
    filter(hasNotReachedEnquiriesLimit),
    filter(hasUserId),
    filter(([{ message }]) => {
      const isSpammyMessage = isSpam(message);
      if (isSpammyMessage) {
        SentryMinimal().captureMessage(`[ENQUIRY_SPAM_DETECTED]`, {
          extra: { message },
        });
      }

      return !isSpammyMessage;
    }),
    mergeMap(([{ suppliers, message, executeRecaptcha }, state]) => {
      const sanitizedMessage = filterOutUrls(message);
      const budget = getWeddingBudget(state);
      const userId = getCurrentUserId(state) as string;
      const profileId = getWeddingProfileId(state);
      const intent = getEnquiryFormIntents(state);
      const sendEnquiries = async () => {
        const enquiries = await Promise.allSettled<CreateEnquiryResponse>(
          suppliers.map(async (contactedSupplier) => {
            const captchaToken = await executeRecaptcha();
            const budgetMatch = isBudgetMatch(budget, contactedSupplier);
            const result = await createEnquiry({
              message: sanitizedMessage,
              userId,
              profileId,
              captchaToken,
              intent,
              budgetMatch,
              contactedSupplier,
              source: 'concierge',
            }).catch(() => {
              throw contactedSupplier;
            });

            return { ...result, supplier: contactedSupplier, message };
          }),
        );

        const successfulEnquiries = enquiries
          .filter(isSuccessfulEnquiry)
          .map((result) => (result as PromiseFulfilledResult<CreateEnquiryResponse>).value)
          .map((enquiry, _, array) => ({ ...enquiry, suppliersCount: array.length }));

        const unsuccessfulEnquiries = enquiries
          .filter(isUnsuccessfulEnquiry)
          .map((result) => (result as PromiseRejectedResult).reason as IUISupplier);

        return {
          successfulEnquiries,
          unsuccessfulEnquiries,
        };
      };

      return from(sendEnquiries());
    }),
    share(),
  );

  const successfulEnquirie$ = enquirie$.pipe(
    map(({ successfulEnquiries }) => successfulEnquiries),
    filter((data) => !!data.length),
    map(sendEnquiriesSuccess),
  );

  const unsuccessfulEnquirie$ = enquirie$.pipe(
    map(({ unsuccessfulEnquiries }) => unsuccessfulEnquiries),
    filter((data) => !!data.length),
    map(sendEnquiriesFailure),
  );

  return merge(successfulEnquirie$, unsuccessfulEnquirie$);
};

const hasNotReachedEnquiriesLimit = ([{ suppliers }, state]: [
  SendEnquiriesPayload,
  IApplicationState,
]) => {
  const enquiryDates = getEnquiryDates(state);
  const category = suppliers[0].type;
  const enquiriesCount = enquiryDates[category];
  const enquiryLimitReached = enquiriesCount >= env.ENQUIRIES_USER_LIMIT;
  return !(enquiriesCount && enquiryLimitReached);
};

const hasUserId = ([, state]: [SendEnquiriesPayload, IApplicationState]) => {
  const userId = getCurrentUserId(state);
  if (!userId) {
    SentryMinimal().captureMessage(`[NEW ONBOARDING]: Attempt to send enquiries without user id.`);
  }
  return !!userId;
};

const isSuccessfulEnquiry = (result: PromiseSettledResult<any>) => result.status === 'fulfilled';
const isUnsuccessfulEnquiry = (result: PromiseSettledResult<any>) => !isSuccessfulEnquiry(result);
