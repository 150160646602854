import { EnquiryFormInfoProps, IApplicationState } from 'lib/types';

const getEnquiriesAnalyticsProps = ({
  getState,
  infoProps = {},
}: {
  getState: () => IApplicationState;
  infoProps?: EnquiryFormInfoProps;
}) => {
  const {
    locationType = '',
    actionLabel = '',
    method = '',
    contactGalleryCTA,
    cta,
    contactSection,
  } = infoProps;
  const {
    enquiries: {
      userEnquiryAnalyticsMethod,
      userEnquiryAnalyticsActionLabel,
      userEnquiryAnalyticsLocationType,
      intent: contactIntent,
    },
    supplier: { showReviewsPage },
    app: { pathname },
  } = getState();

  const contactActionLabel: string = actionLabel || userEnquiryAnalyticsActionLabel || '';
  const contactLocationType: string = locationType || userEnquiryAnalyticsLocationType || 'main';
  const rootUrlRegex = /\/?((?:\w+-?)+)/.exec(pathname || '');

  let contactLocation: string = method || userEnquiryAnalyticsMethod || 'supplierProfile_sideBar';

  if (infoProps.method === 'loggedOutProfile') {
    contactLocation = 'loggedOutProfile';
  } else if (rootUrlRegex) {
    const pageName: string = rootUrlRegex[1] || '';
    if (pageName === 'shortlist' || pageName === 'search') {
      contactLocation = pageName;
    } else if (showReviewsPage) {
      contactLocation = 'reviews-page';
    }
  }

  return {
    contactLocation,
    contactLocationType,
    contactActionLabel,
    contactIntent,
    contactGalleryCTA,
    contactSection,
    cta,
  };
};

export default getEnquiriesAnalyticsProps;
