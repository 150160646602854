import { keys } from 'ramda';
import { AccessControlActionTypes } from 'lib/access-control';
import { ChecklistActionTypes } from 'lib/checklist/action-types';
import { EnquiriesActionTypes } from 'lib/enquiries/action-types';
import { GuestlistActionTypes } from 'lib/guestlist/action-types';
import { SettingsAnalyticsActionTypes } from 'lib/settings/analytics/action-types';
import { ShortlistActionTypes } from 'lib/shortlist/action-types';
import { slugEquals } from '../checklist/helpers';
import { halfChecklistChecked, tenTaskChecked } from './helpers';

export type badgeId =
  | 'firstMessageSent'
  | 'guestAdded'
  | 'profileImageAdded'
  | 'halfChecklistChecked'
  | 'addedToFavourites'
  | 'photographerBooked'
  | 'checkedTenTasks'
  | 'collaboratorAdded'
  | 'venueBooked';

export interface IBadgesConnectedAction {
  id?: badgeId;
}

interface IBadgesConnectedActionMultiple extends IBadgesConnectedAction {
  // If the same action can trigger many tasks, add an extra condition
  // when the task should be triggered based on the action's payload.
  // Trigger must be unique within one parent action.
  triggerOnlyIf: (action: any) => boolean;
}
export const BadgesConnectedActions: Record<
  string,
  IBadgesConnectedAction | Array<IBadgesConnectedActionMultiple>
> = {
  [EnquiriesActionTypes.SEND_ENQUIRY_SUCCESS_ANALYTICS]: {
    id: 'firstMessageSent',
  },
  [GuestlistActionTypes.UPDATED_GUESTS_COUNT]: {
    id: 'guestAdded',
  },
  [SettingsAnalyticsActionTypes.USER_UPLOAD_PROFILE_PHOTO_ANALYTICS]: {
    id: 'profileImageAdded',
  },
  [ShortlistActionTypes.SAVE_TO_SHORTLIST]: {
    id: 'addedToFavourites',
  },
  [ShortlistActionTypes.ADDED_TO_BOOKED_SUPPLIERS]: [
    {
      id: 'venueBooked',
      triggerOnlyIf: slugEquals('venue'),
    },
    {
      id: 'photographerBooked',
      triggerOnlyIf: slugEquals('photo'),
    },
  ],
  [ChecklistActionTypes.UPDATED_CHECKLIST_TOTALS]: [
    {
      id: 'halfChecklistChecked',
      triggerOnlyIf: halfChecklistChecked(),
    },
    {
      id: 'checkedTenTasks',
      triggerOnlyIf: tenTaskChecked(),
    },
  ],
  [AccessControlActionTypes.INVITE_COLLABORATOR_SUCCESS]: {
    id: 'collaboratorAdded',
  },
};

export const badgesConnectedActionTypes = keys(BadgesConnectedActions);
