import { IUISupplier } from '@bridebook/toolbox/src/types';
import { IElasticSupplier } from 'lib/supplier/types';

export const pickMaxThreeSuppliersPerType = (
  suppliers: IElasticSupplier[],
  selectedTypes: Set<string>,
): IUISupplier[] => {
  const typeCounts: { [key: string]: number } = {};

  suppliers.forEach((supplier) => {
    if (
      selectedTypes.has(supplier.type) &&
      (!typeCounts[supplier.type] || typeCounts[supplier.type] < 3)
    ) {
      typeCounts[supplier.type] = (typeCounts[supplier.type] || 0) + 1;
    }
  });

  return suppliers.filter((supplier) => {
    if (selectedTypes.has(supplier.type) && typeCounts[supplier.type] > 0) {
      typeCounts[supplier.type]--;
      return true;
    }
    return false;
  });
};
export const shuffleRecommendedSuppliers = (
  suppliers: IUISupplier[],
  skipShuffleTypes: IUISupplier['type'][] = [],
): IUISupplier[] => {
  const shouldShuffle = (item: IUISupplier) => !skipShuffleTypes.includes(item.type);

  const nonShuffledItems: IUISupplier[] = [];
  const shuffledItems: IUISupplier[] = [];

  suppliers.forEach((item) => {
    if (shouldShuffle(item)) {
      shuffledItems.push(item);
    } else {
      nonShuffledItems.push(item);
    }
  });

  for (let i = shuffledItems.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledItems[i], shuffledItems[j]] = [shuffledItems[j], shuffledItems[i]];
  }

  return [...shuffledItems, ...nonShuffledItems];
};

export const filterVenueRecommendedSupplierOnly = (id: string) => (supplier: IElasticSupplier) =>
  supplier.connections?.recommendedBy?.includes(id) ||
  supplier.connections?.worksWith?.includes(id) ||
  supplier.connections?.relatedBy?.includes(id) ||
  supplier.connections?.relatesWith?.includes(id);
