import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Action } from '@bridebook/toolbox/src/types';
import { SettingsActionTypes } from 'lib/settings/action-types';
import { recentLocationSearchesStorage } from 'lib/storage-manager/hooks';

export const resetRecentSearchesOnCountryChangeEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(SettingsActionTypes.CHANGE_WEDDING_COUNTRY_COMPLETED),
    switchMap(() => {
      recentLocationSearchesStorage.set([]);
      return of();
    }),
  );
