import {
  createNote,
  deleteNoteFromDB,
  deleteNotePhoto,
  deletePhotoSuccess,
  deleteSuccess,
  editField,
  firebaseSaveSuccess,
  multiUploadFinish,
  onFirebaseData,
  openNotePopup,
  validateAndSaveNote,
  validitionSuccess,
} from 'lib/scrapbook/actions';

export enum ScrapbookActionTypes {
  INIT_SCRAPBOOK_LISTENER = '[Scrapbook] Init listener',
  STOP_SCRAPBOOK_LISTENER = '[Scrapbook] Stop listener',
  ON_FIREBASE_SCRAPBOOK_NOTES = '[Scrapbook] On scrapbook Firebase',
  CREATE_EMPTY_NOTE = '[Scrapbook] Create empty note',
  OPEN_NOTE_POPUP = '[Scrapbook] Open note popup',
  DELETE_NOTE_PHOTO_FROM_S3 = '[Scrapbook] Delete note photo from s3',
  CLOSE_NOTE_POPUP = '[Scrapbook] Close note popup',
  SCRAPBOOK_VALIDATION_ERROR = '[Scrapbook] Scrapbook validation error',
  SCRAPBOOK_NOTE_VALIDATE = '[Scrapbook] Scrapbook note validate',
  SCRAPBOOK_NOTE_VALIDATE_SUCCESS = '[Scrapbook] Scrapbook note validate success',
  ADDED_NOTE_ANALYTICS = '[Scrapbook] Added note analytics',
  EDITED_NOTE_ANALYTICS = '[Scrapbook] Edited note analytics',
  SAVE_NOTE_TO_FIREBASE_SUCCESS = '[Scrapbook] Save note to firebase success',
  DELETE_NOTE_ANALYTICS = '[Scrapbook] Delete note analytics',
  DELETE_NOTE_FROM_FIREBASE = '[Scrapbook] Delete note from firebase',
  DELETE_NOTE_FROM_FIREBASE_SUCCESS = '[Scrapbook] Delete note from firebase success',
  EDIT_FIELD = '[Scrapbook] Edit field',
  ADD_NOTE_TRIGGER_ANALYTICS = '[Scrapbook] Add note trigger analytics',
  ADD_NOTE_TRIGGER = '[Scrapbook] Add note trigger',
  OPEN_NOTE_TRIGGER_ANALYTICS = '[Scrapbook] Open note trigger analytics',
  OPEN_NOTE_TRIGGER = '[Scrapbook] Open note trigger',
  EDIT_NOTE_TRIGGER_ANALYTICS = '[Scrapbook] Edit note trigger analytics',
  EDIT_NOTE_TRIGGER = '[Scrapbook] Edit note trigger',
  SWITCH_TO_VIEW = '[Scrapbook] Switch to view',
  SAVE_NOTE_TRIGGER = '[Scrapbook] Save note trigger',
  DELETE_NOTE_TRIGGER_ANALYTICS = '[Scrapbook] Delete note trigger analytics',
  DELETE_NOTE_TRIGGER = '[Scrapbook] Delete note trigger',
  ADD_PHOTO_TO_NOTE_ANALYTICS = '[Scrapbook] Add photo to note analytics',
  ADD_PHOTO_TO_NOTE_ERROR_ANALYTICS = '[Scrapbook] Add photo to note error analytics',
  DELETE_PHOTO_FROM_NOTE_ANALYTICS = '[Scrapbook] Delete photo from note analytics',
  DELETE_PHOTO_FROM_NOTE_SUCCESS = '[Scrapbook] Delete photo from note success',
  DELETE_PHOTO_FROM_NOTE_ERROR = '[Scrapbook] Delete photo from note error',
  SCRAPBOOK_MULTIUPLOAD_FINISH = '[Scrapbook] Scrapbook multiupload finish',
  UPDATE_PROFILE_NOTES_NUMBER_SUCCESS = '[Scrapbook] Update profile notes number success',
  UPDATE_PROFILE_NOTES_NUMBER_ERROR = '[Scrapbook] Update profile notes number error',
}

export type IOnFirebaseDataAction = ReturnType<typeof onFirebaseData>;

export type ICreateNoteAction = ReturnType<ReturnType<typeof createNote>>;

export type IOpenNotePopupAction = ReturnType<typeof openNotePopup>;

export type IDeleteNotePhotoAction = ReturnType<typeof deleteNotePhoto>;

export type IValidateAndSaveNoteAction = ReturnType<typeof validateAndSaveNote>;

export type IValiditionSuccessAction = ReturnType<typeof validitionSuccess>;

export type IFirebaseSaveSuccessAction = ReturnType<ReturnType<typeof firebaseSaveSuccess>>;

export type IDeleteNoteFromDBAction = ReturnType<typeof deleteNoteFromDB>;

export type IDeleteSuccessAction = ReturnType<typeof deleteSuccess>;

export type IEditFieldAction = ReturnType<typeof editField>;

export type IDeletePhotoSuccessAction = ReturnType<ReturnType<typeof deletePhotoSuccess>>;

export type IMultiUploadFinishAction = ReturnType<typeof multiUploadFinish>;
