import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  mergeMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { achievements } from 'components/achievements/achievements-data';
import { appError } from 'lib/app/actions';
import { AuthActionTypes } from 'lib/auth/action-types';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';
import { WeddingActionTypes } from 'lib/weddings/action-types';
import { fetchBadgesSuccess } from '../actions';
import { badgerAdapter } from '../badges-utils';

export const fetchBadges = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(AuthActionTypes.USER_SETUP_COMPLETED),
    mergeMap(() =>
      action$.pipe(
        ofType(WeddingActionTypes.UPDATE_WEDDING),
        withLatestFrom(state$),
        distinctUntilChanged(
          ([prevAction], [nextAction]) =>
            prevAction.payload.wedding.id === nextAction.payload.wedding.id,
        ),
        mergeMap(([, state]: [Action, IApplicationState]) => {
          const { id } = state.weddings.profile;

          const getPromise = async () => {
            const wedding = Weddings._.getById(id);

            const weddingBadges = await wedding.Badges.query().get();
            const allSupportedBadges = achievements();
            const weddingSupportedBadges = Object.entries(weddingBadges).filter(
              ([badgeId]) => allSupportedBadges[badgeId],
            );
            const adapterBadges = badgerAdapter(Object.fromEntries(weddingSupportedBadges));
            return adapterBadges;
          };
          return from(getPromise()).pipe(
            mergeMap((payload) => of(fetchBadgesSuccess(payload))),
            catchError((error) => of(appError({ error, feature: 'App fetchBadges' }))),
          );
        }),
        takeUntil(action$.pipe(ofType(AuthActionTypes.ON_FIREBASE_SIGN_OUT))),
      ),
    ),
  );
