import { AnyAction } from 'redux';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, pluck, take } from 'rxjs/operators';
import { IApplicationState, IUserSerialized } from 'lib/types';

export const withCurrentUser =
  <A extends AnyAction>(state$: StateObservable<IApplicationState>) =>
  (source: Observable<A>) =>
    source.pipe(
      mergeMap((action: A) =>
        state$.pipe(
          pluck<IApplicationState, IUserSerialized>('users', 'user'),
          filter((user) => !!user),
          map((user) => ({ user, action })),
          take(1),
        ),
      ),
    );
