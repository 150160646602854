import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ValidationError } from '@bridebook/toolbox/src';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { getI18n } from 'lib/i18n/getI18n';
import { getCountryCodeWithFallback } from 'lib/i18n/selectors';
import { Action, IApplicationState, IDeps, IEpicDeps } from 'lib/types';
import {
  CreateCustomSupplierAction,
  CreateCustomSupplierSuccessAction,
  ShortlistActionTypes,
} from './action-types';
import {
  bookedSupplierAnalytics,
  createCustomSupplierSuccess,
  customSupplierFormShowNextError,
  customSupplierFormValid,
  toggleCustomSupplierFormModal,
  toggleGoogleSearchModal,
  toggleSupplierAddModal,
} from './actions';
import { CustomSupplierFormType } from './reducer';
import { createCustomSupplierObj } from './utils';

const validateCustomSupplierForm = async ({
  fields,
  validate,
  countryCode,
}: {
  fields: CustomSupplierFormType['fields'];
  validate: (agrs: any) => any;
  countryCode: CountryCodes;
}) => {
  const i18n = getI18n();

  if (countryCode === CountryCodes.GB) {
    return validate(fields)
      .prop('name')
      .required()
      .prop('type')
      .required()
      .prop('email')
      .validEmail()
      .prop('website')
      .urLink()
      .prop('postcode')
      .postcodeNotRequired().promise;
  }

  return validate(fields)
    .prop('name')
    .required()
    .prop('type')
    .required()
    .prop('email')
    .custom((value: string, prop: string) => {
      if (value === '') {
        throw new ValidationError(i18n.t('common:error.supplierEmailMissingError'), prop);
      }
    })
    .email()
    .prop('website')
    .urLink().promise;
};

export const createCustomSupplierError =
  (error: ValidationError) =>
  ({ dispatch }: IDeps) => {
    let errorMessage = '';
    if (error) {
      const i18n = getI18n();
      const fieldsMissingText = i18n.t('common:error.fieldsMissing');

      if (error.message === 'name AND (email OR website OR phone number)') {
        errorMessage = i18n.t('common:error.profileExists');
      } else if (
        error.prop &&
        (error.prop === 'email' ||
          error.prop === 'phone' ||
          error.prop === 'postcode' ||
          error.prop === 'website')
      ) {
        errorMessage = error.messageI18n || error.message;
      } else if (error instanceof ValidationError && error.prop) {
        errorMessage = fieldsMissingText;
      } else {
        errorMessage = fieldsMissingText;
      }
    }

    if (errorMessage) {
      dispatch(toggleSnackbar('alert', errorMessage));
    }

    return {
      type: ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER_ERROR,
      payload: error,
    };
  };

export const validateFormEpics = (action$: Observable<Action>, { validate, state$ }: IEpicDeps) =>
  action$.pipe(
    withLatestFrom(state$),
    filter(
      ([action, state]: [Action, IApplicationState]) =>
        action.type === ShortlistActionTypes.SET_CUSTOM_SUPPLIER_FORM_FIELD &&
        state.shortlist.customSupplier.useValid,
    ),
    mergeMap(([, state]: [Action, IApplicationState]) => {
      const {
        shortlist: {
          customSupplier: { fields },
        },
      } = state;

      const countryCode = getCountryCodeWithFallback(state);

      return from(validateCustomSupplierForm({ fields, validate, countryCode })).pipe(
        map(() => customSupplierFormValid()),
        catchError((error) => {
          const { hideFirstError } = state.shortlist.customSupplier;
          return hideFirstError
            ? of(customSupplierFormShowNextError())
            : of(createCustomSupplierError(error));
        }),
      );
    }),
  );

export const createCustomSupplierEpic = (
  action$: Observable<CreateCustomSupplierAction>,
  { validate, state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        shortlist: {
          customSupplier: { fields },
        },
      } = state;

      const countryCode = getCountryCodeWithFallback(state);

      const getPromise = async () => {
        if (action.payload.supplier) return action.payload.supplier;
        await validateCustomSupplierForm({ fields, validate, countryCode });
        return createCustomSupplierObj({
          ...fields,
          countryCode,
        });
      };

      const { shortlistedLocation, showSnackbar } = action.payload;

      return from(getPromise()).pipe(
        map((supplier) =>
          createCustomSupplierSuccess(
            supplier as IUISupplier,
            action.payload.method,
            action.payload.booked,
            shortlistedLocation,
            showSnackbar,
          ),
        ),
        catchError((error) => of(createCustomSupplierError(error))),
      );
    }),
  );

export const customSupplierSuccessEpic = (
  action$: Observable<CreateCustomSupplierSuccessAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ShortlistActionTypes.CREATE_CUSTOM_SUPPLIER_SUCCESS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        shortlist: {
          addToBooked,
          showSearchGoogleModal,
          showCustomSupplierFormModal,
          addSupplierModalOpened,
        },
      } = state;
      const actions = [];

      if (showCustomSupplierFormModal) {
        actions.push(toggleCustomSupplierFormModal(false, true));
      }

      if (showSearchGoogleModal) {
        actions.push(toggleGoogleSearchModal({ show: false }));
      }

      if (addSupplierModalOpened) {
        actions.push(toggleSupplierAddModal(false));
      }

      if (addToBooked) {
        /** dispatching this first, to not risk side effects from closing modals */
        actions.unshift(
          bookedSupplierAnalytics({
            supplier: action.payload.supplier,
            method: action.payload.method,
          }),
        );
      }

      return of(...actions);
    }),
  );
