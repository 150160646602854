import { ofType } from 'redux-observable';
import { pathOr } from 'remeda';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { Users } from '@bridebook/models';
import { Action, IEpicDeps } from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';

export const markGuestlistAsViewedEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType('ROUTE_CHANGE_COMPLETE'),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { url },
        },
        state,
      ]) => {
        const isGuestlistPage = url === '/guestlist';

        const user = state.users.user;
        const hasViewedGuestlist = pathOr(user, ['actions', 'hasViewedGuestlist'], false);

        if (!hasViewedGuestlist && isGuestlistPage && user) {
          Users._.getById(user.id).set({
            actions: { hasViewedGuestlist: true },
          });
          return of({ type: UserActionTypes.MARK_GUESTLIST_AS_VIEWED });
        }

        return of();
      },
    ),
  );
