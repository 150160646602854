import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { IApplicationState } from 'lib/types';

export const withMarket =
  <A>(state$: StateObservable<IApplicationState>) =>
  (source: Observable<A>) =>
    source.pipe(
      mergeMap((input: A) =>
        state$.pipe(
          filter(({ weddings, users }) => Boolean(weddings.profile.l10n && users.user?.l10n)),
          map(({ weddings, users }) => ({
            weddingCountry: weddings.profile.l10n.country,
            userL10n: users.user?.l10n,
            input,
          })),
          map(({ weddingCountry, userL10n }) => {
            const market = gazetteer.getMarketByCountry(weddingCountry);
            if (userL10n?.locale) {
              market.locale = userL10n.locale;
            }

            if (userL10n?.currency) {
              market.currency = userL10n?.currency;
            }

            return { market, input, state: state$.value };
          }),
          take(1),
        ),
      ),
    );
