import { ofType } from 'redux-observable';
import { from, merge, partition } from 'rxjs';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { PremiumTiers } from '@bridebook/toolbox';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { getI18n } from 'lib/i18n/getI18n';
import { getShortlistedVenuesList } from 'lib/shortlist/selectors';
import {
  ISupplierInterestConfirmationStorage,
  SupplierInterestConfirmationStorage,
} from 'lib/storage-manager/basic/supplier-interest-confirmation';
import { IApplicationState, IEpic, TUIShortlistSupplier, VenueConfirmSlides } from 'lib/types';
import {
  VenueConfirmActionTypes,
  VenueConfirmChangeSlideAction,
} from 'lib/venue-confirmation/action-types';
import {
  closeBookingConfirmationModal,
  saveSupplierIdToConfirm,
  venueConfirmChangeSlide,
} from 'lib/venue-confirmation/actions';
import { triggerSupplierInterestConfirmationAnalytics } from 'lib/venue-confirmation/analytics/actions';

type InputActions = VenueConfirmChangeSlideAction;

/**
 * An Epic that handles the logic for showing the supplier interest confirmation modal.
 */
export const openSupplierInterestConfirmationEpic: IEpic<InputActions, any> = (
  action$,
  { state$ },
) => {
  const [hasVPEInShortlistedSuppliers$, hasNotVPEInShortlistedSuppliers$] = partition(
    action$.pipe(
      ofType(VenueConfirmActionTypes.TRIGGER_SUPPLIER_CONFIRMATION),
      withLatestFrom(state$),
      mergeMap((data) =>
        from(SupplierInterestConfirmationStorage.get()).pipe(
          map<
            ISupplierInterestConfirmationStorage | null,
            [[InputActions, IApplicationState], ISupplierInterestConfirmationStorage | null]
          >((storedData) => [data, storedData]),
        ),
      ),
    ),
    hasVPEInShortlistedSuppliers,
  );

  const [hasNotBeenShown$, hasBeenShown$] = partition(
    hasVPEInShortlistedSuppliers$,
    hasNotBeenShownInLast24Hours,
  );

  return merge(
    hasNotBeenShown$.pipe(
      tap(([, storageData]) => {
        // Update lastShown timestamp in storageData
        SupplierInterestConfirmationStorage.set({
          ...storageData,
          lastShown: new Date().toISOString(),
        });
      }),
      map(toShortlistedVPESupplierId),
      mergeMap((supplierId) => [
        saveSupplierIdToConfirm(supplierId),
        venueConfirmChangeSlide(VenueConfirmSlides.supplierInterest),
        triggerSupplierInterestConfirmationAnalytics(supplierId),
      ]),
    ),
    merge(hasBeenShown$, hasNotVPEInShortlistedSuppliers$).pipe(
      mergeMap(() => [
        toggleSnackbar('success', getI18n().t('venueConfirmation:happyHunting')),
        closeBookingConfirmationModal(),
      ]),
    ),
  );
};

/**
 * Checks if a tier_3 supplier is present in the shortlisted suppliers.
 */

const hasVPEInShortlistedSuppliers = ([[, state], storedData]: [
  [InputActions, IApplicationState],
  ISupplierInterestConfirmationStorage | null,
]) => {
  const shortlistedSuppliers = getShortlistedVenuesList(state) || [];
  const availableSuppliers = shortlistedSuppliers
    .filter(isVPESupplier)
    .filter(hasNotBeenShownYet(storedData));

  return availableSuppliers.some(isVPESupplier);
};

/**
 * Checks if the supplier interest confirmation modal has not been shown in the last 24 hours.
 */
const hasNotBeenShownInLast24Hours = ([, storedData]: [
  [InputActions, IApplicationState],
  ISupplierInterestConfirmationStorage | null,
]) => {
  if (!storedData || !storedData.lastShown) {
    return true;
  }

  const requiredDiff = 86400000; // 24 hours in milliseconds
  const lastShown = new Date(storedData.lastShown).getTime();
  const now = new Date().getTime();
  return now - lastShown > requiredDiff;
};

/**
 * Extracts the supplier ID of the first available tier_3 supplier from shortlisted suppliers.
 */
const toShortlistedVPESupplierId = ([[, state], storedData]: [
  [InputActions, IApplicationState],
  ISupplierInterestConfirmationStorage | null,
]) => {
  const shortlistedSuppliers = getShortlistedVenuesList(state) || [];
  const availableSuppliers = shortlistedSuppliers
    .filter(isVPESupplier)
    .filter(hasNotBeenShownYet(storedData));

  return availableSuppliers[0].id;
};

/**
 * Checks if a supplier is a tier_3 supplier.
 */
const isVPESupplier = (supplier: TUIShortlistSupplier) =>
  supplier?.tier && supplier.tier >= PremiumTiers.Tier_3;

/**
 * Creates a function that checks if a supplier has not been shown yet in stored data.
 */
const hasNotBeenShownYet =
  (storedData: ISupplierInterestConfirmationStorage | null) => (supplier: TUIShortlistSupplier) =>
    !(storedData && storedData.suppliersShown && storedData.suppliersShown[supplier.id]);
