export const triggeringMainTaskIds = [
  'efdabbd1-d29b-4714-a762-add2e153c7c2',
  '577a3fb5-b544-4b78-a3e8-656f46b7373a',
  'e3d64d98-0c51-4291-bc84-a24d537e05f3',
  '32d950b2-6f09-4f01-9de1-d7af0dd3d007',
  'ec371c84-7a08-4c11-88ae-b02ceb8b34f1',
  '1255055a-370e-4ca9-8930-ee8aed3109e2',
  'b44c6063-118c-4225-84f0-6b40dc64063c',
  'fdb085ca-6c68-4879-998d-c652983b5c70',
  'cafc879d-79b7-42cf-afb4-9cedbeae3866',
  '8614f58b-4107-4e62-af55-71a7df0c5a1a',
  'cc062883-e803-4c9f-9994-85adbb011a7d',
  '2046d975-6f22-411f-890b-c016d5d8b239',
  '4fb727b2-991a-43d5-8146-2b82b8e607a1',
  '230fff48-cd95-4f88-b62b-1b50aefabdfe',
  'dfb5c55b-6ae1-4e3f-9834-090a03a99467',
  '438b8e6b-abb5-4558-b4a8-6e85b86bda08',
  '7bb6c011-1ec7-4e29-a470-df7e702add54',
  'a846b10e-1183-4cbd-b160-70d5de8d1b1c',
  '9c09be02-6189-4556-99d2-8535af79cc86',
  '20e11b4f-374b-40bc-8e82-a7e66fe05acd',
  'cd1653ac-7db5-482e-91ce-d9a3e3cbbd8e',
  '8a232fdb-88fc-41ee-90fc-813d2ada1992',
  'd34716ef-5543-4893-8a00-9facdb86a302',
  '68253be1-9bc3-4d4a-a638-d2ec5be0b3db',
  '4cee02cd-aeb3-453e-8502-26404870f4c0',
  '46169eaf-3423-489a-a61c-a4abd07a7dcc',
  'f8b83a0e-a5f8-47a9-ae08-39dbac07f96e',
  '6710b73e-1886-4ec1-8c16-4e34b599772c',
  '36adb336-b9ff-421d-8c32-47fc7effc757',
  '88b4be55-521b-485f-b763-5c2ddc80be5a',
  '05a241b7-5fd5-4c57-a7e1-53e96bc770c4',
  'b8a2e3a7-1a34-4c11-ad99-7f6fa71c5e67',
  'e6e3ee58-dc1a-4615-bc23-47cceff0833a',
  '6ed2a063-a4a6-4fef-bdae-e8980e9d771a',
  'c473b8fe-f5d3-4bdb-9a79-054f05c6c4da',
  'ac66a86d-d9a3-44ad-aedd-e4a7f4445efa',
  '682103d2-da50-4296-aeda-241d25974279',
  'e4a7ccd4-116a-48bc-9fda-c0c893523d1e',
  '5a23f8e8-6440-4fdb-8f08-4358455fafdc',
];

export const triggeringSubtaskIds = [
  'ef8b8ad8-107c-487f-bfa9-f861712677a5',
  '43195469-6460-410c-b0a3-38d00a608c63',
  'a93b95d6-50d0-4b1a-9157-443c295223e6',
  'da6414c2-44db-4dd2-be1b-fc3d0822cf03',
  'a7c5b383-b487-47a3-bb06-d6b0b825afbd',
  '3303d99b-82f3-4759-aa96-c50f78854747',
  'b67378b1-f71f-4086-900d-7ffa33dfbd6e',
  '2881b04f-9d95-473b-9b54-499641862832',
  '05fe1f27-c458-4a0b-acc1-d5216ca6087c',
  '89bc8456-9a75-4fc7-82e2-1b85c25682f5',
  '7bcc675d-7792-4c79-a5c4-d0f6c94cccfa',
  '4e01c1e8-2236-41d5-90f7-c3176c736dcc',
  '942bbdc2-5c64-454b-b413-b33937b9a8e8',
  'd7650299-f2b0-4cf8-acd2-9ba6374a86f1',
  '8f6a2d91-a67c-4b53-b7d9-f6785e609c19',
  '97041d11-00b4-41f7-a8c1-8ee26734379d',
  '45a17d7e-9eb9-44f7-a743-b5d44546f04e',
  '7ad468de-b420-480c-9bb6-d5da5c999ddb',
  'be531fad-d542-4298-b3c5-74a216dcb185',
  'ee03a004-648f-4f62-b330-854e436cdd81',
  '041f83e0-55bb-48e3-9cb8-be46417ae552',
  'e5ebb3fe-153c-4fd5-88ba-4c1fd9c792a7',
  '714ebbfc-483a-4823-96fb-5eecc8ce9fdf',
  '54b28946-d13b-42be-b59d-33660e17ca1c',
  '88ef6a5c-df70-445a-8f79-415d0105b93a',
  'b5b9cc11-f431-41d3-8917-93cf41bd32a9',
  '5ab9a679-f741-42e7-a925-c2507f347b8d',
  '447f8288-098c-4845-a3cf-17e7486aa440',
  '0c7ee1da-f4e2-4589-a15a-a5733b5ed5dc',
  '9748c369-18f5-4157-b120-1c78526c3fc6',
  '17f3a2c5-1595-4b17-82aa-6d1fcb6eff30',
  'a87456e8-9871-454a-966f-604071273e03',
  '92462f27-6c3a-4073-98c4-4b42a90fedda',
  '4cf3ab10-648e-4874-9999-73701d9ab57f',
  'bd2db05d-f433-4fd0-a759-48aa13738e5d',
  'c84d780e-821a-4f79-b615-962ea4cb1f51',
  'a81d08f4-a2e3-4a81-be7e-9dcfe350271a',
  'a11e1eba-cbde-41eb-9fcb-3e0cafa9eb9a',
  '72244fca-4b36-44b4-bf2f-50d217c90f27',
  '068bb620-2604-4bfa-811d-1736c1d1b0b5',
  '24a82163-cf7c-4ab8-ad94-4f36618d66aa',
  '381ea600-642c-45a1-ac5c-480e742f7861',
  'df766230-5d46-4794-a030-7175eacf3bd6',
  '4a65889b-6454-4982-9edb-2459f50baa90',
  '4d113e14-6140-4e87-86b3-a368c7e1030b',
  'e34d5e42-cd28-4e72-b6f1-425453ac7a3e',
  '7fdb43c1-5980-4a0d-a907-721f6eb1e383',
  '0d413ad5-161d-4d7c-8fab-a6c4bbf4d2cc',
  '9bd3804c-f250-442b-98ca-3152e6992ebd',
  'ed68bc80-4c91-4fcf-b23e-a49ef5d7ee72',
  '1c0ec77b-8653-4a1f-807c-f12a44854431',
  'e807cec2-8468-4f74-9836-12476b041b9e',
  '1b61058c-53cc-44bb-a289-7bce301b6fe9',
  '2ee7b713-58cf-4d0a-bf05-371de69ecfe8',
  '436ec0ef-6e53-40bb-8eac-f5b49a964efa',
  '5370a891-49b1-44b5-92be-eba35c6b3f2c',
  '1004ec5b-94e8-455a-9778-17c720d5c99a',
  'fdf53fc8-8682-4fe4-bc95-d588d06c1eab',
  'fa134627-78a3-4c6c-9c12-e464c9ad8462',
  '7cae0c18-3688-46e0-8113-474a1b5c66bd',
  '36625d00-e750-44ad-8d29-3cdcee39fdce',
  '5786b2ec-4bd2-4ab1-b7ea-5cfaf3ce6951',
  '85e67132-3249-44b3-925a-88680a7f1bb4',
  '9c164225-6337-4e4d-8d7b-67e8cd0fd4f6',
  '3b61d4cb-2596-4e27-ae5d-111059bae751',
  '5ebb9632-100b-4d8f-a611-d6aabb0de23a',
  '33861579-263d-48d4-9c3b-32762934d890',
  '3713a604-0254-4819-805e-1e00b2afec97',
  '67bf19cb-4331-4009-ae0c-fea7ac479fbf',
  '823103a8-19d5-4f87-9ef7-2d3976b3a18a',
  'c4a8b51d-368d-44a5-8f11-3aa1fda54a79',
  '10c642e3-4200-4f64-9ce8-0c7d50a9a840',
  '260ab776-5afa-4b05-ab77-4f5ac93378d3',
  '5951ae08-05fe-43e3-b1f2-dc5d838f7a3e',
  '13c2e8a3-edcf-4f10-84a5-36cd1735ef96',
  '7c9e2e8d-bd08-4f3d-98c6-712fcd1bddd0',
  'ca927245-2a63-40e9-85b1-1457fdd298c1',
  '2c60601a-affa-470d-9b3b-eb61184d7bf5',
  'efbd5ded-8635-417a-b172-2fba8cde169a',
  '666c5d11-e338-4486-9764-3a5faf1b6815',
  '510b5b03-edb3-4ba6-98ba-919e9c8d4f46',
  '659b6050-97f4-48cd-bafc-540af843ea3e',
  'cd89b73b-68a8-4da8-8a3b-0c757d3fbb3b',
  'e9f51043-c58a-4b24-87f2-3b9af0b20971',
  'aee725ee-3ff9-44b6-9f91-d0c8d8a6ecac',
  '05775284-664c-4f3c-8013-6a990304e711',
  '5030e1af-0be8-4b3e-93cb-48af7687748b',
  'e833af43-8d92-48ce-9acf-2a5fe8098de4',
  '70d6deaf-2a2b-4fb6-9253-a868d6ecc261',
  '6d7b1f93-f377-4e9d-b946-95151d5584b3',
  '0b4d5731-4273-41fa-a20f-d62dcc09b7d6',
  '2ec4198b-6ddb-43ac-9ed9-1bcf741e0d50',
  'bb3e488a-e548-4bd3-a8ab-aaa0a4d3acd4',
  'f58f1810-c051-4e49-a887-5aa6e626fe6f',
  '85087510-b2bb-4e8e-8c68-df0c278700e0',
  'a7809ab1-df67-4320-8bc7-be0a81514a34',
  '73a6c9e6-0bd5-45b8-8118-8cb6ff641e65',
  '67188c5a-dfb1-4394-bd04-c123129dee8b',
  'f9bed3b4-281a-4803-9f6c-741db3ac01c6',
  'd38dffe4-7908-4c9a-a14d-67c8cddf9730',
  '08b81fd8-faca-4326-a119-53516418c68a',
  '06a4e29a-3d5f-4da8-9ce2-ff8bb9138be2',
  '8c409d12-ecfc-41f1-97cf-233097eadaba',
  'e6285895-cfc7-419f-8f07-2a16ea47fc05',
  '0ffb6bac-fb6b-41a2-afd3-8d0259603354',
  '4b63c740-baca-45af-8b58-9f415c68890a',
  '55748ab9-5101-4f52-864d-6cf92415668e',
  '28a119dd-3782-4fdc-8a93-99459e84d093',
  'f8b90bcf-effa-40ee-a39a-35f92758a8af',
  'db33013d-3733-4979-9097-f39c40a9bc9e',
  '54f28038-3112-423a-befd-9480d52c592d',
  '337d7626-5fb3-469b-ace1-bf3f3900e189',
  'e9a45e00-7715-405f-950d-2ea23071c932',
  '9ee0bcd0-791d-4ce3-be3b-6c7fb7f9b4b1',
  '1b1d01e2-470b-4dee-9a32-04753d8822fa',
  '73f50218-b122-4a1b-b83f-9007147c1db6',
  '81ad5172-bf52-4c9c-ac74-992fe4e3bb11',
  '10b03fd9-4fd8-4115-acd8-e637c4f70ba0',
  '84b34399-e6d5-44e9-9044-8d92395767a6',
  '497a2007-a7a7-489b-91e1-d199b0916fe9',
  'e18ec76a-27ff-4b34-83ac-cfa5786a8fd4',
  '6ed2f314-71ef-40d8-9d73-9a4ce1e74a95',
  '417601b3-59dd-428b-9dbb-195126f3dc7b',
  '777917ff-c2ca-4470-bc48-c62f604b0ab7',
  '3667af9b-6a8f-482b-9e8c-9f6ccebfafaf',
  '32298656-3096-437d-95fd-699f2be5bb0c',
  '5abc1d2c-aa07-4e3c-9054-1d7da1b90b4c',
  'b02768b2-281d-4ae0-b29b-6cac2f4dcbe8',
  '7f06436a-6de0-4fd6-8e2a-af412f5b3d54',
  '76ef123b-0c3a-4025-90f1-533a890993a7',
  'b0724591-3f7d-4bb1-9e2a-5fa557ef2001',
  '8b7cd55f-8417-4c3d-83d2-98967239c84a',
  'fb8a6749-3e67-4da8-8247-626a510e229f',
  '52d16a35-fc8b-46d2-9c26-b6322b49dcc7',
  '8caabc34-d881-46e0-ac50-02617bcc5afc',
  '01dcd7db-8e0a-49fd-bf91-4a99376c58a7',
  'cc7b3f49-2d5c-4765-ade9-bda6aa780ed7',
  'fc208235-5150-4030-924e-d81ac01b7340',
  '33009d38-d00f-420b-9826-e02311bf3255',
  '0d24191b-9a54-4036-9892-e6f83564a832',
  '165d3519-aaa1-4413-9ecd-fc1882128f73',
  '90d3234c-4892-4cf4-8142-f9cc924a61fa',
  '93ea7152-d960-4edb-be47-a5c0fabbb3f1',
  '3b35ddfb-da3a-4600-9b91-6900dfd86c1e',
  'b470711b-f6ac-47a1-a281-f19961628736',
  'e8a84929-efc0-40fc-829b-3ef00445eb04',
  '511735ee-dc26-460e-ae07-6d2ff0b2e3d7',
  '0d266705-5486-4d47-b14e-46458302819e',
];
