import { mapDateToUI, mapToExactDate } from '@bridebook/toolbox';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { getLocalisation } from 'lib/i18n/selectors';
import { commonMapper } from 'lib/track-utils/mappers/common-mapper';
import { ITrackingMapper } from 'lib/track-utils/tracking-mappers';
import { getChecklistInitializationDate, getProfile } from 'lib/weddings/selectors';

export const initializedChecklistMapper: ITrackingMapper = (action, state) => {
  const [event, data] = commonMapper(action, state);
  const profile = getProfile(state);
  const weddingCountryCode = getLocalisation(state)?.country;
  const market = weddingCountryCode ? gazetteer.getMarketByCountry(weddingCountryCode) : null;
  const exactDate = profile && market ? mapToExactDate(mapDateToUI(profile.date), market) : 0;
  const checklistExactDate = profile ? new Date(exactDate || 0).toISOString() : undefined;

  return [
    event,
    {
      ...data,
      checklist_initialisation_date: getChecklistInitializationDate(state),
      checklist_weddingDate: checklistExactDate,
      country_code: market?.country || null,
    },
  ];
};
