import { ValidationError } from '@bridebook/toolbox/src';
import { IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';
import { DatePickerOptions } from '../types';

/**
 * Function `required`
 * throws ValidationError if property is missing
 *
 * @function required
 * @param {Object, String} - IDatePickerUI, datepicker prop name
 * @returns {Boolean}
 */
const required = (obj: IDatePickerUI, prop: keyof IDatePickerUI) => {
  if (!obj[prop]) {
    throw new ValidationError(`Please choose a ${prop}`, `${prop}-screen`);
  }
  return true;
};

/**
 * Function `validateYear`
 * validate year-screen part of DatePickerDate
 *
 * @function validateYear
 * @param {Object, Object} - IDatePickerUI, DatePickerOptions
 * @returns {Boolean}
 */
const validateYear = (datepickerDate: IDatePickerUI, options: DatePickerOptions): boolean => {
  const { yearUndecided } = datepickerDate;
  const { showUndecided } = options;
  if ((showUndecided && !yearUndecided) || !showUndecided) {
    return required(datepickerDate, 'year');
  }
  return true;
};

/**
 * Function `validateMonth`
 * validate month-screen part of DatePickerDate
 *
 * @function validateMonth
 * @param {Object, Object} - IDatePickerUI, DatePickerOptions
 * @returns {Boolean}
 */
const validateMonth = (datepickerDate: IDatePickerUI, options: DatePickerOptions): boolean => {
  const { monthUndecided, season, month } = datepickerDate;
  const { showSeason, showUndecided } = options;
  if ((showUndecided && !monthUndecided) || !showUndecided) {
    if (!showSeason) {
      return required(datepickerDate, 'month');
    } else if (!month && !season) {
      throw new ValidationError('Please choose a month or season', 'month-screen');
    } else {
      return true;
    }
  }
  return true;
};

/**
 * Function `validateDay`
 * validate day-screen part of DatePickerDate
 *
 * @function validateDay
 * @returns {Boolean}
 * @param datepickerDate
 * @param options
 */
const validateDay = (datepickerDate: IDatePickerUI, options: DatePickerOptions): boolean => {
  const { dayUndecided, weekDay, day } = datepickerDate;
  const { showUndecided, showDays } = options;
  if ((showUndecided && !dayUndecided) || !showUndecided) {
    if (!showDays) {
      return required(datepickerDate, 'day');
    } else if (!day && !weekDay) {
      throw new ValidationError('Please choose a day or weekday', 'day-screen');
    } else {
      return true;
    }
  }
  return true;
};

/**
 * Function `validateDatepickerDate`
 * validate DatepickerDate part depending on currently active datepicker screen tab
 *
 * @function validateDatepickerDate
 * @param {Object, Object} - IDatePickerUI, DatePickerOptions
 * @returns {Boolean}
 */
const validateDatepickerDate = (
  datepickerDate: IDatePickerUI,
  options: DatePickerOptions,
): boolean => {
  switch (options.activeTab) {
    case 'year-screen':
      return validateYear(datepickerDate, options);
    case 'month-screen':
      return validateMonth(datepickerDate, options);
    case 'day-screen':
      return validateDay(datepickerDate, options);
    default:
      return true;
  }
};

export default validateDatepickerDate;
