import { promiseAllDeep } from '../promises';
import Data from './data/polyglot.json';
import { DriverPayload as DriverPayloadGoogle } from './drivers/Google';
import { DriverPayload as DriverPayloadGroq } from './drivers/Groq';
import { DriverPayload as DriverPayloadLMStudio } from './drivers/LMStudio';
import { DriverPayload as DriverPayloadOpenAI } from './drivers/OpenAI';

/**
 * Supported language codes, generally consisting of its ISO 639-1 identifier.
 * In certain cases, BCP-47 codes including language + region identifiers are returned.
 */
export type Language =
  | 'af'
  | 'ak'
  | 'am'
  | 'ar'
  | 'as'
  | 'ay'
  | 'az'
  | 'be'
  | 'bg'
  | 'bho'
  | 'bm'
  | 'bn'
  | 'bs'
  | 'ca'
  | 'ceb'
  | 'ckb'
  | 'co'
  | 'cs'
  | 'cy'
  | 'da'
  | 'de'
  | 'doi'
  | 'dv'
  | 'ee'
  | 'el'
  | 'en'
  | 'eo'
  | 'es'
  | 'et'
  | 'eu'
  | 'fa'
  | 'fi'
  | 'fil'
  | 'fr'
  | 'fy'
  | 'ga'
  | 'gd'
  | 'gl'
  | 'gn'
  | 'gom'
  | 'gu'
  | 'ha'
  | 'haw'
  | 'he'
  | 'hi'
  | 'hmn'
  | 'hr'
  | 'ht'
  | 'hu'
  | 'hy'
  | 'id'
  | 'ig'
  | 'ilo'
  | 'is'
  | 'it'
  | 'iw'
  | 'ja'
  | 'jv'
  | 'jw'
  | 'ka'
  | 'kk'
  | 'km'
  | 'kn'
  | 'ko'
  | 'kri'
  | 'ku'
  | 'ky'
  | 'la'
  | 'lb'
  | 'lg'
  | 'ln'
  | 'lo'
  | 'lt'
  | 'lus'
  | 'lv'
  | 'mai'
  | 'mg'
  | 'mi'
  | 'mk'
  | 'ml'
  | 'mn'
  | 'mni-Mtei'
  | 'mr'
  | 'ms'
  | 'mt'
  | 'my'
  | 'ne'
  | 'nl'
  | 'no'
  | 'nso'
  | 'ny'
  | 'om'
  | 'or'
  | 'pa'
  | 'pl'
  | 'ps'
  | 'pt'
  | 'qu'
  | 'ro'
  | 'ru'
  | 'rw'
  | 'sa'
  | 'sd'
  | 'si'
  | 'sk'
  | 'sl'
  | 'sm'
  | 'sn'
  | 'so'
  | 'sq'
  | 'sr'
  | 'st'
  | 'su'
  | 'sv'
  | 'sw'
  | 'ta'
  | 'te'
  | 'tg'
  | 'th'
  | 'ti'
  | 'tk'
  | 'tl'
  | 'tr'
  | 'ts'
  | 'tt'
  | 'ug'
  | 'uk'
  | 'ur'
  | 'uz'
  | 'vi'
  | 'xh'
  | 'yi'
  | 'yo'
  | 'zh-CN'
  | 'zh-TW'
  | 'zh'
  | 'zu';

/**
 * Chat Completions
 */
export type Messages = [
  {
    /**
     * LLM System Prompt
     */
    content: string;
    role: 'system';
  },
  ...{
    /**
     * LLM System Prompt
     */
    content: string;
    role: 'system';
  }[],
];

export type TranslationJob<T extends { _translations?: Record<string, Record<string, any>> }> = {
  input: NonNullable<T['_translations']>[string];
  metadata?: Record<string, any>;
  source: TranslationJobOriginFirestore;
  language: {
    source: `${string}-${string}` | string | '*';
    target: `${string}-${string}` | string;
  };
};

export type TranslationJobOriginFirestore = {
  type: 'firestore';
  uri: string;
};

// const languageCode = /^[a-z]{2,3}(?:-[0-9a-zA-Z]{2,8})*$/;

type DriverPayload =
  | DriverPayloadGoogle
  | DriverPayloadGroq
  | DriverPayloadLMStudio
  | DriverPayloadOpenAI;

export type Lexicon = {
  [target: TranslationJob<any>['language']['target']]: {
    [source: TranslationJob<any>['language']['source']]: DriverPayload[];
  };
};

type TransformFunc<T> = (value: T) => Promise<T | null>;

function traverseAndTransform<T>(object: T, callback: TransformFunc<T>) {
  if (object != null && typeof object === 'object' && Array.isArray(object) !== true) {
    const result = {} as T;

    Object.keys(object).forEach((key) => {
      result[key as keyof T] = traverseAndTransform((object as any)[key], callback);
    });

    return result;
  }

  return callback(object);
}

export class Polyglot {
  readonly #data = Data as unknown as Lexicon;

  resolve<T extends Pick<TranslationJob<any>, 'language'>>(job: T): T['language'];
  resolve<T extends Pick<TranslationJob<any>, 'language'>>(
    job: T['language']['target'],
  ): T['language']['target'];

  /**
   * Returns the resolved canonical source and target languages for a given translation job.
   */
  resolve<T extends Pick<TranslationJob<any>, 'language'>>(
    job: T | T['language']['target'],
  ): T['language'] | T['language']['target'] {
    const result: T['language'] = {
      source: '',
      target: '',
    };

    if (typeof job === 'string') {
      const target = job.split('-');

      for (let i = target.length; i > 0; i--) {
        const tag = target.slice(0, i).join('-');

        if (this.#data[tag] != null) {
          result.target = tag;
        }

        if (result.target !== '') {
          break;
        }
      }

      if (result.target === '') {
        throw new Error(`Unsupported Polyglot language ${job}!`);
      }

      return result.target;
    }

    const target = job.language.target.split('-');

    for (let i = target.length; i > 0; i--) {
      const tag = target.slice(0, i).join('-');

      if (this.#data[tag] != null) {
        result.target = tag;
      }

      if (result.target !== '') {
        break;
      }
    }

    const source = job.language.source.split('-');

    for (let i = source.length; i > 0; i--) {
      const tag = source.slice(0, i).join('-');

      if (this.#data[result.target]?.[tag] != null) {
        result.source = tag;
      }

      if (result.source !== '') {
        break;
      }
    }

    /**
     * If the specific source language couldn't be matched, fallback to the catch-all language, if available.
     */
    if (result.source === '' && this.#data[result.target]?.['*']) {
      result.source = '*';
    }

    /**
     * We couldn't detect any matching driver in Polyglot for the source:target language pair.
     **/
    if (result.source === '' || result.target === '') {
      throw new Error(
        `Unsupported Polyglot language pair ${job.language.source}:${job.language.target}!`,
      );
    }

    return result;
  }

  async translate<T extends TranslationJob<any>>(job: T) {
    const pair = this.resolve(job);
    const drivers = this.#data[pair.target][pair.source];

    let result = job.input;

    for (const driver of drivers) {
      // @ts-expect-error - TODO: Get the typings right.
      let instance = null;

      switch (driver.driver) {
        case 'Google':
          instance = (await import('./drivers/Google')).default;
          break;

        case 'Groq':
          instance = (await import('./drivers/Groq')).default;
          break;

        case 'LMStudio':
          instance = (await import('./drivers/LMStudio')).default;
          break;

        case 'OpenAI':
          instance = (await import('./drivers/OpenAI')).default;
          break;
      }

      result = traverseAndTransform(result, (content: string) =>
        // @ts-expect-error - TODO: Get the typings right.
        instance.translate(content, driver.options),
      );
    }

    /**
     * Recursively resolve all promises in the map to
     * get the final translation object.
     */
    return promiseAllDeep(result);
  }
}

export default new Polyglot();
