import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { appError } from 'lib/app/actions';
import { EnquiriesActionTypes, IFetchEnquiryCount } from 'lib/enquiries/action-types';
import { fetchEnquiryCountSuccess } from 'lib/enquiries/actions';
import { IEpicDeps } from 'lib/types';

const fetchEnquiryCountEpic = (action$: Observable<IFetchEnquiryCount>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.FETCH_ENQUIRY_COUNT),
    debounceTime(500),
    withLatestFrom(state$),
    mergeMap(([action]) => {
      const supplierId = action.payload;
      const promise = authenticatedFetch(`/api/enquiries/count/${supplierId}`).then((res) => {
        if (!res.ok) {
          throw new Error('fetchEnquiryCountEpic: failed to fetch');
        }
        return res.json() as Promise<number>;
      });
      return from(promise).pipe(
        map(fetchEnquiryCountSuccess),
        catchError((error: Error) => of(appError({ error, feature: 'fetchEnquiryCountEpic' }))),
      );
    }),
  );

export default fetchEnquiryCountEpic;
