import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { Action, IEpicDeps } from 'lib/types';
import { triggerBookingConfirmationModal } from 'lib/venue-confirmation/actions';
import { WeddingActionTypes } from 'lib/weddings/action-types';

/**
 * Open venue confirmation popup when URL contains a param in the query
 * such as ?venueConfirmationPopup=true
 */
export const openVenuePopupFromUrlParam = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType('APP_STARTED', 'ROUTE_CHANGE_COMPLETE'),
    withLatestFrom(state$),
    filter(([, state]) => !!state.app.query.venueConfirmationPopup),
    mergeMap(([, state]) => {
      const togglePopupAction = triggerBookingConfirmationModal({
        location: 'venueConfirmationUrlParam',
        searchSupplierType: 'venue',
        showIntro: true,
      });
      // If user is logged in then toggle popup
      if (state.users.user) return of(togglePopupAction);
      // Otherwise, wait for the user to log in
      return action$.pipe(
        ofType(WeddingActionTypes.UPDATE_WEDDING),
        withLatestFrom(state$),
        filter(([, state]) => !!state.users.user),
        take(1),
        mergeMap(() => of(togglePopupAction)),
      );
    }),
  );
