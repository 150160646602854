import type { TrackEventBodyWeb } from '@bridebook/toolbox/src/types';
import getServerSideAnalyticsProps from 'lib/utils/get-server-side-analytics-props';
import type { IServerSideTrack } from 'lib/utils/server-side-track';

const bookedSupplierTrack = ({
  state,
  event,
  identifyProps,
  specificEventProps,
  bookedSupplier,
  category = '',
}: IServerSideTrack): Promise<any> => {
  const body: TrackEventBodyWeb = {
    event,
    identifyProps,
    genericProps: getServerSideAnalyticsProps(state),
    specificEventProps,
    source: 'web',
    bookedSupplier,
    category,
  };

  return fetch(`/api/a/t/booked`, {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    return response;
  });
};

export default bookedSupplierTrack;
