import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IVendorCommonProps } from '@bridebook/toolbox/src/elastic-sync/suppliers/types';
import { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { buildShortlistSupplier } from 'lib/shortlist/utils';

export type ISupplierL10n = {
  supplierCountryName?: string;
  supplierCountryCode?: CountryCodes;
  adminArea0?: string;
  adminArea1?: string;
};

/**
 * Depending on from where we call the analytics event,
 * we get different types of suppliers. Once directly
 * from ES (IVendorCommonProps), sometimes a mapped
 * supplier from ES with merged data from FS (IUISupplier)
 * and sometimes from FS only (ISupplier).
 */
export const getSupplierL10nAnalytics = (
  supplier: ISupplier | IUISupplier | IVendorCommonProps,
): ISupplierL10n => {
  if (!supplier) {
    return {};
  }

  let supplierCountryCode;
  let supplierCountryName;
  let adminArea0;
  let adminArea1;

  if (isIVendorCommonProps(supplier)) {
    supplierCountryCode = supplier.countryCode as CountryCodes;
    supplierCountryName = Gazetteer.getCountryName(supplierCountryCode);
    /**
     * Yes, you read it right - we flip the admin
     * areas when writing the data to ES. Check
     * `processCommonProps`.
     *
     * @link packages/toolbox/src/elastic-sync/suppliers/process-common-props.ts
     **/
    adminArea0 = supplier.adminAreaLevel2;
    adminArea1 = supplier.adminAreaLevel1;
  } else if (isIUISupplier(supplier)) {
    /**
     * Map the supplier from mixed type to a ISupplier, so
     * that we have a defined set of address properties to
     * work with.
     **/
    supplierCountryCode = supplier.countryCode;
    supplierCountryName = supplierCountryCode
      ? Gazetteer.getCountryName(supplierCountryCode)
      : undefined;
    const mappedSupplier = buildShortlistSupplier(supplier);
    adminArea0 = mappedSupplier.address?.adminArea?.[0];
    adminArea1 = mappedSupplier.address?.adminArea?.[1];
  } else {
    supplierCountryCode = supplier.l10n?.country;
    supplierCountryName = supplierCountryCode
      ? Gazetteer.getCountryName(supplierCountryCode)
      : undefined;
    adminArea0 = supplier.address?.adminArea?.[0];
    adminArea1 = supplier.address?.adminArea?.[1];
  }

  return {
    supplierCountryCode,
    supplierCountryName,
    adminArea0,
    adminArea1,
  };
};

const isIUISupplier = (supplier: unknown): supplier is IUISupplier =>
  Object.prototype.hasOwnProperty.call(supplier, 'countryCode');

const isIVendorCommonProps = (supplier: unknown): supplier is IVendorCommonProps =>
  Object.prototype.hasOwnProperty.call(supplier, 'adminAreaLevel1') &&
  Object.prototype.hasOwnProperty.call(supplier, 'adminAreaLevel2');
