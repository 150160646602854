import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { appError } from 'lib/app/actions';
import { EnquiriesActionTypes, IFetchEnquiryDates } from 'lib/enquiries/action-types';
import { fetchEnquiryDatesSuccess } from 'lib/enquiries/actions';
import { IEnquiryDate } from 'lib/enquiries/types';
import { IEpicDeps } from 'lib/types';

const fetchEnquiryDatesEpic = (action$: Observable<IFetchEnquiryDates>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.FETCH_ENQUIRY_DATES),
    debounceTime(500),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const { id: weddingId } = state.weddings.profile;
      const promise = authenticatedFetch(`/api/enquiries/get-dates/${weddingId}`, {
        headers: new Headers({ 'Content-Type': 'application/json' }),
      }).then((res) => {
        if (!res.ok) {
          throw new Error('fetchEnquiryDatesEpic: failed to fetch');
        }

        return res.json() as Promise<Record<string, IEnquiryDate>>;
      });

      return from(promise).pipe(
        map((payload) => fetchEnquiryDatesSuccess(payload)),
        catchError((error: Error) => of(appError({ error, feature: 'fetchEnquiryDatesEpic' }))),
      );
    }),
  );

export default fetchEnquiryDatesEpic;
