import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, pluck } from 'rxjs/operators';
import { sendEnquirySuccess } from 'lib/enquiries/actions';
import { AttemptToSendTalkJSMessagePayload, OnboardingAction } from 'lib/onboarding-new/actions';
import { CreateEnquiryResponse } from 'lib/onboarding-new/types';
import { ActionWithPayload } from 'lib/types';

type InputActions =
  | ActionWithPayload<CreateEnquiryResponse[]>
  | ActionWithPayload<AttemptToSendTalkJSMessagePayload>;

/**
 * This epic handles the case when sending onboarding enquiries is successful. It listens for
 * the `OnboardingAction.SendEnquiriesSuccess` action, which contains the payload (an array
 * of successfully sent enquiries). It then flattens this array into separate Observables using
 * the `mergeMap` operator and listens for the `OnboardingAction.
 * AttemptToSendTalkJSNotification` action. If the supplier data in the payload matches the
 * supplier data from the previous action, it dispatches a new action to update the store state
 * for the notification banner. This new action contains the supplier data, the boolean flag
 * indicating whether the inbox message was successfully sent to the supplier, and additional
 * boolean flags indicating whether to display the success and error notification banners.
 */
export const sendEnquirySuccessfulEpic = (action$: Observable<InputActions>) =>
  action$.pipe(
    ofType(OnboardingAction.SendEnquiriesSuccess),
    pluck<ActionWithPayload<CreateEnquiryResponse[]>, CreateEnquiryResponse[]>('payload'),
    mergeMap((enquiries) => enquiries),
    mergeMap(({ supplier }) =>
      action$.pipe(
        ofType(OnboardingAction.AttemptToSendTalkJSNotification),
        pluck<
          ActionWithPayload<AttemptToSendTalkJSMessagePayload>,
          AttemptToSendTalkJSMessagePayload
        >('payload'),
        filter(({ supplierId }) => supplier.id === supplierId),
        map(() => sendEnquirySuccess(supplier, true, true)),
      ),
    ),
  );
