import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Users } from '@bridebook/models';
import { appError } from 'lib/app/actions';
import { IEpicDeps } from 'lib/types';
import { IChangeCorrespondenceEmailAction, UserActionTypes } from 'lib/users/action-types';
import { changeCorrespondenceEmailError, changeCorrespondenceEmailSuccess } from '../actions';

export const changeCorrespondenceEmailEpic = (
  action$: Observable<IChangeCorrespondenceEmailAction>,
  { state$, validate }: IEpicDeps,
) =>
  action$.pipe(
    ofType(UserActionTypes.CHANGE_CORRESPONDENCE_EMAIL_START),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { fields, silent },
        },
        state,
      ]) => {
        const getPromise = async () => {
          await validate(fields).prop('email').required().email().promise;

          const { user } = state.users;
          if (!user) return;

          const response = await Users._.getById(user.id).set({
            contacts: { email: fields.email },
          });

          return response;
        };

        return from(getPromise()).pipe(
          mergeMap(() => of(changeCorrespondenceEmailSuccess(silent, fields.email))),
          catchError((error: Error) => of(changeCorrespondenceEmailError(error))),
        );
      },
    ),
    catchError((error: Error) => of(appError({ error, feature: 'Auth' }))),
  );
