import { values } from 'ramda';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { updateSubtasks } from 'lib/checklist/actions';
import { ChecklistGroupIDs, ChecklistSubtaskIDs } from 'lib/checklist/data/task-ids';
import { extractRawName } from 'lib/checklist/utils';
import {
  ChecklistConnectedActions,
  IChecklistConnectedAction,
} from 'lib/connected-actions/checklist/actions';
import { getGroupsTranslation } from 'lib/countries/data/groups';
import { getTasksTranslation } from 'lib/countries/data/tasks';
import { getI18n } from 'lib/i18n/getI18n';
import { Action, IApplicationState } from 'lib/types';

const getConnectedAction = (action: Action): IChecklistConnectedAction => {
  const connectedActions = ChecklistConnectedActions[action.type] || {};

  return Array.isArray(connectedActions)
    ? connectedActions.find((a) => a.triggerOnlyIf(action)) || {}
    : connectedActions;
};

export const checklistActionsHandler = (action: Action, state: IApplicationState) => {
  const { groupIds = [], subtaskIds = [], snackbar } = getConnectedAction(action) || {};

  if (!groupIds.length && !subtaskIds.length) {
    return [];
  }

  const {
    app: { groups },
    checklist: { tasks, tasksInitial },
  } = state;

  const getInitialTaskName = (taskId: string) => {
    const defaultName = tasksInitial[taskId].name;
    const key = tasksInitial[taskId].i18nKey;
    return getTasksTranslation()[key] || defaultName;
  };

  const getGroupName = (groupId: string) => {
    const group = groups[groupId];
    const translatedName = group?.i18nKey ? getGroupsTranslation[group.i18nKey]?.()?.name : null;

    return translatedName || group.name;
  };

  const subtasksToTickOff = values(tasks)
    .filter(
      (task) =>
        groupIds.includes(task.group as ChecklistGroupIDs) ||
        subtaskIds.includes(task.id as ChecklistSubtaskIDs),
    )
    .filter((task) => !task.done);

  if (!subtasksToTickOff.length) {
    return [];
  }

  /**
   * Priority for the task name in the snackbar message is:
   * 1. Subtask name from the defined subtaskId
   * 2. Group name from the defined groupId
   * 3. Group name from the last group in groupIds
   * 4. Subtask name from the last subtask in subtaskIds
   */
  const getSnackbarTaskName = () => {
    if (snackbar) {
      const { groupId, subtaskId } = snackbar;
      if (subtaskId && tasksInitial[subtaskId]) {
        return getInitialTaskName(subtaskId);
      }
      return getGroupName(groupId);
    }

    if (groupIds) {
      const groupId = groupIds[groupIds.length - 1];

      return getGroupName(groupId);
    }

    if (subtaskIds) {
      const subtaskId = subtaskIds[subtaskIds.length - 1];
      return getInitialTaskName(subtaskId);
    }
    return '';
  };

  const msg = `🎉 ${getI18n().t('common:completedTaskSnackbar')} ${extractRawName(
    getSnackbarTaskName() || '',
  )}`;

  const subtasks: Parameters<typeof updateSubtasks>[0] = subtasksToTickOff.map((subtask) => ({
    taskId: subtask.id,
    checked: true,
    name: subtask.custom
      ? subtask.name || ''
      : extractRawName(tasksInitial[subtask.id]?.name || ''),
    method: 'passive',
  }));

  return [updateSubtasks(subtasks), toggleSnackbar('success', msg, 2)];
};
