import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { IEpicDeps } from 'lib/types';
import { ShortlistActionTypes, UpdateShortlistedSupplierAction } from '../action-types';
import { saveSupplierInWeddingSuppliers } from '../utils';

export const updateShortlistedSupplierEpic = (
  action$: Observable<UpdateShortlistedSupplierAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ShortlistActionTypes.UPDATE_SHORTLISTED_SUPPLIER),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        weddings: {
          profile: { id: profileId },
        },
      } = state;

      const { supplier } = action.payload;
      if (!supplier) return of();

      const getPromise = saveSupplierInWeddingSuppliers(supplier, profileId);

      return from(getPromise).pipe(
        mergeMap(() => of()),
        catchError((error) => of(appError({ error, feature: 'Shortlist' }))),
      );
    }),
  );
