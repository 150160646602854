import { TSlugRecord } from 'components/quiz/at-your-venue-quiz/at-your-venue-quiz-steps/texts';
import { TOmittedSlugRecord } from 'components/quiz/at-your-venue-quiz/types';
import { imgixBaseURL } from 'lib/utils';

export const CATEGORIES_OPTIONS: TOmittedSlugRecord[] = [
  'photo',
  'florist',
  'catering',
  'music',
  'video',
  'decoration',
  'entertainment',
  'cakes',
  'beauty',
  'dress',
  'dress',
  'transport',
  'menswear',
  'stationery',
  'marquee',
];

export const categoriesImages: Omit<TSlugRecord, 'group' | 'jewellery' | 'planners' | 'venue'> = {
  photo: `${imgixBaseURL}/assets/at-your-venue/photographer.jpg`,
  florist: `${imgixBaseURL}/assets/at-your-venue/florist.jpg`,
  catering: `${imgixBaseURL}/assets/at-your-venue/caterer.jpg`,
  music: `${imgixBaseURL}/assets/at-your-venue/music.jpg`,
  video: `${imgixBaseURL}/assets/at-your-venue/videographer.jpg`,
  decoration: `${imgixBaseURL}/assets/at-your-venue/decorator.jpg`,
  entertainment: `${imgixBaseURL}/assets/at-your-venue/entertainment.png`,
  cakes: `${imgixBaseURL}/assets/at-your-venue/cake.jpg`,
  beauty: `${imgixBaseURL}/assets/at-your-venue/beauty.jpg`,
  dress: `${imgixBaseURL}/assets/at-your-venue/dress.jpg`,
  transport: `${imgixBaseURL}/assets/at-your-venue/transport.jpg`,
  menswear: `${imgixBaseURL}/assets/at-your-venue/menswear.jpg`,
  stationery: `${imgixBaseURL}/assets/at-your-venue/stationery.jpg`,
  marquee: `${imgixBaseURL}/assets/at-your-venue/marquee.jpg`,
};
