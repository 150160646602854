import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { fetchUserShortlistSuccess } from 'lib/shortlist/actions';
import { IEpicDeps, TShortlistList } from 'lib/types';
import { getWeddingProfileId } from 'lib/weddings/selectors';
import { enrichWeddingSuppliers, fetchWeddingSuppliers } from '../utils';

/**
 * This epic fetches all shortlisted suppliers from the wedding collection
 */
export const fetchShortlistedSuppliersEpic = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType('INIT_SHORTLIST_LISTENER'),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const getPromise = async () => {
        const weddingId = getWeddingProfileId(state);
        const weddingSuppliers = await fetchWeddingSuppliers(weddingId);
        const enrichedSuppliers = await enrichWeddingSuppliers(weddingSuppliers);

        return weddingSuppliers.reduce(
          (acc, supplier) => {
            const enrichedSupplier = enrichedSuppliers[supplier.id];
            if (!enrichedSupplier) return acc;
            if (supplier.shortlisted) acc.shortlisted[supplier.id] = enrichedSupplier;
            return acc;
          },
          { shortlisted: {} as TShortlistList },
        );
      };

      return from(getPromise()).pipe(
        mergeMap(({ shortlisted }) => [fetchUserShortlistSuccess({ shortlisted })]),
      );
    }),
  );
