import { BasicStorageManager } from '@bridebook/toolbox/src/storage-managers';

export interface IPerformedSearchStorage {
  mockCouplesNumber: string;
  performedSearch: boolean;
}

export const PerformedSearchStorage = BasicStorageManager<IPerformedSearchStorage>({
  storageKey: 'performed-search',
});
