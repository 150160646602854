import { differenceInDays } from 'date-fns';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { InAppReview } from '@capacitor-community/in-app-review';
import { appError } from 'lib/app/actions';
import { Action, IEpicDeps } from 'lib/types';

export const showAppRatePopupEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    filter((action: Action) => action.type === 'UPDATED_CHECKLIST_TOTALS'),
    withLatestFrom(state$),
    debounceTime(500),
    distinctUntilChanged(
      (
        [
          {
            payload: { done: prevTasksDone },
          },
        ],
        [
          {
            payload: { done: nextTasksDone },
          },
        ],
      ) => prevTasksDone === nextTasksDone,
    ),
    switchMap(
      ([
        {
          payload: { done: tasksDone },
        },
        state,
      ]) => {
        try {
          const {
            weddings: { profile },
            users: { user },
            app: {
              device: { isCordova },
            },
          } = state;

          // Show for logged-in APP users only
          if (!isCordova || !profile || !user) {
            return of();
          }

          const {
            suppliers,
            budget,
            guests: { count },
          } = profile;

          // Added at least one guest to guestlist
          const guestlistCondition = count > 0;
          // Shortlisted at least 1 supplier
          const shortlistCondition = (suppliers && suppliers.count) || 0 > 0;
          // Budget initialised
          const budgetCondition = budget && budget > 0;

          const daysSinceRegistration = differenceInDays(
            new Date(),
            user.registeredAt ? new Date(user.registeredAt) : new Date(),
          );

          const SESSION_KEY = 'app-rate-popup';
          const shownThisSession = window.sessionStorage.getItem(SESSION_KEY);

          /*
           * AFTER marking a checklist task as completed
           * IF the popup hasn't been shown in this session yet
           * AND at least 14 days have passed since registration
           * AND at least 3 tasks are done
           * AND EITHER
           *  budget is initialised
           *  OR shortlisted at least 1 item
           *  OR added at least 1 guest
           * THEN trigger app rate popup
           */
          if (
            !shownThisSession &&
            daysSinceRegistration >= 14 &&
            tasksDone >= 3 &&
            (guestlistCondition || shortlistCondition || budgetCondition)
          ) {
            InAppReview.requestReview();
            window.sessionStorage.setItem(SESSION_KEY, 'true');
          }

          return of();
        } catch (error) {
          return of(appError({ error, feature: 'AppRatePopup' }));
        }
      },
    ),
  );
