import { isEmpty } from 'ramda';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, mergeMap, pluck, switchMap, withLatestFrom } from 'rxjs/operators';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { appError } from 'lib/app/actions';
import { selectMarketWithFallback } from 'lib/i18n/selectors';
import { IFetchSearchSuggestionsAction, SearchActionTypes } from 'lib/search/action-types';
import { suggestionSuccess } from 'lib/search/actions';
import { IEpic } from 'lib/types';

export const suggestionsEpic: IEpic = (action$, { state$ }) =>
  action$.pipe(
    ofType(SearchActionTypes.FETCH_SEARCH_SUGGESTIONS),
    pluck<IFetchSearchSuggestionsAction, IFetchSearchSuggestionsAction['payload']>('payload'),
    filter(({ searchTerm }) => !isEmpty(searchTerm)),
    withLatestFrom(state$),
    mergeMap(([{ searchTerm, slug, size, lockCategory }, state]) => {
      const countryCode = selectMarketWithFallback(state).country;
      const promiseBody = JSON.stringify({
        searchTerm,
        type: slug,
        size,
        lockCategory,
        countryCode,
      });

      const promise: Promise<IUISupplier[]> = fetch(ApiEndpoint.search.suggestions, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: promiseBody,
      }).then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(
          `Failed to load suggestions. Response status: ${res.status}, request body: ${promiseBody}`,
        );
      });

      return from(promise).pipe(
        switchMap((payload) =>
          of(
            {
              type: SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_ANALYTICS_DEBOUNCED,
              payload: searchTerm,
            },
            suggestionSuccess(payload),
          ),
        ),
        catchError((error) => of(appError({ error, feature: 'Search' }))),
      );
    }),
  );
