import { where } from 'firebase/firestore';
import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { fetchSupplierPromise } from 'app-shared/lib/supplier/utils/fetch-supplier-promise';
import { appError } from 'lib/app/actions';
import {
  EnquiriesActionTypes,
  IFetchEnquiryConfirmationSupplier,
} from 'lib/enquiries/action-types';
import {
  fetchEnquiryConfirmationSupplierSuccess,
  toggleEnquiryConfirmation,
} from 'lib/enquiries/actions';
import { fetchSupplierData } from 'lib/supplier/actions';
import { IEpicDeps } from 'lib/types';

const fetchEnquiryConfirmationSupplierEpic = (
  action$: Observable<IFetchEnquiryConfirmationSupplier>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.FETCH_ENQUIRY_CONFIRMATION_SUPPLIER_START),
    withLatestFrom(state$),
    filter(([{ payload: supplierPublicId }]) => !!supplierPublicId),
    mergeMap(([{ payload: supplierPublicId }, state]) => {
      const { id: weddingId } = state.weddings.profile;

      const getPromise = async () => {
        // [bb-global][bb-locale] Do we need to pass locale here?
        const [supplier, supplierData] = await Promise.all([
          fetchSupplierPromise(supplierPublicId),
          fetchSupplierData(supplierPublicId),
        ]);
        const supplierId = supplier?.id;

        if (supplierId) {
          const suppliers = Weddings._.getById(weddingId).Suppliers;
          const enquired = await suppliers.query([where('enquired', '==', true)]).get();

          const enquiredArray = values(enquired);
          const enquiredSupplier = enquiredArray.find((enquiredVenue) => enquiredVenue.enquired);

          if (enquiredSupplier) {
            return {
              enquiryConfirmationSupplier: { ...supplier, supplierData },
              enquiredSuppliersCount: enquiredArray.length,
            };
          }
        }

        return { enquiryConfirmationSupplier: null, enquiredSuppliersCount: 0 };
      };

      return from(getPromise()).pipe(
        mergeMap(({ enquiryConfirmationSupplier, enquiredSuppliersCount }) => {
          if (enquiryConfirmationSupplier) {
            return of(
              fetchEnquiryConfirmationSupplierSuccess({
                enquiryConfirmationSupplier,
                enquiredSuppliersCount,
              }),
            );
          }

          return of(toggleEnquiryConfirmation({ show: false }));
        }),
        catchError((error: Error) => of(appError({ error, feature: 'Fetch enquiry supplier' }))),
      );
    }),
    catchError((error: Error) => of(appError({ error, feature: 'Fetch enquiry supplier' }))),
  );

export default fetchEnquiryConfirmationSupplierEpic;
