import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import getAuthStatus from 'lib/auth/utils/get-auth-status';
import { setPerformedSearch } from 'lib/search/actions';
import { PerformedSearchStorage } from 'lib/storage-manager/basic/performed-search';
import { Action, IEpicDeps } from 'lib/types';

export const setPerformedSearchEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType('ROUTE_CHANGE_COMPLETE'),
    withLatestFrom(state$),
    filter(
      ([
        {
          payload: { url, previousPath },
        },
        state,
      ]) => {
        const isPreviousSearch = state.app.previousPath.startsWith('/search');
        const isSameSearch = url === previousPath;

        return isPreviousSearch && !isSameSearch && getAuthStatus({ user: state.users.user });
      },
    ),
    mergeMap(() => {
      const performed = PerformedSearchStorage.get();
      if (performed && !performed.performedSearch) {
        const newPerformedStorage = { ...performed, performedSearch: true };
        PerformedSearchStorage.set(newPerformedStorage);

        return of(setPerformedSearch(newPerformedStorage));
      }

      return of();
    }),
  );
