import { combineEpics } from 'redux-observable';
import { attemptToSendTalkJSMessageEpic } from 'lib/onboarding-new/epics/attempt-to-send-talkjs-message-epic';
import { failureOnboardingEnquiriesEpic } from 'lib/onboarding-new/epics/send-enquiry-failure-analytics-event-epic';
import { sendEnquirySuccessfulAnalyticsEventEpic } from 'lib/onboarding-new/epics/send-enquiry-successful-analytics-event-epic';
import { sendEnquirySuccessfulEpic } from 'lib/onboarding-new/epics/send-enquiry-successful-epic';
import { sendLoadedSearchResultsAnalyticsEvent } from 'lib/onboarding-new/epics/send-loaded-search-results-analytics-event';
import { sendOnboardingEnquiriesEpic } from 'lib/onboarding-new/epics/send-onboarding-enquiries-epic';
import { sendStartOnboardingAnalyticsEventEpic } from 'lib/onboarding-new/epics/send-start-onboarding-analytics-event-epic';
import { sendSuccessfulCordovaTrackerEventEpic } from 'lib/onboarding-new/epics/send-successful-cordova-tracker-event-epic';

export const newOnboardingEpics = combineEpics(
  sendStartOnboardingAnalyticsEventEpic,
  sendOnboardingEnquiriesEpic,
  sendSuccessfulCordovaTrackerEventEpic,
  sendEnquirySuccessfulAnalyticsEventEpic,
  failureOnboardingEnquiriesEpic,
  attemptToSendTalkJSMessageEpic,
  sendEnquirySuccessfulEpic,
  sendLoadedSearchResultsAnalyticsEvent,
);
