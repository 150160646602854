import { deleteFilesSuccess, fetchFilesSuccess } from './actions';

export enum FileManagementActionTypes {
  DELETE_FILES_SUCCESS = '[File Management] Delete files success',
  FETCH_FILES_SUCCESS = '[File Management] Fetch files success',
  FETCH_FILES = '[File Management] Fetch files',
}

export type FetchFilesSuccessAction = ReturnType<typeof fetchFilesSuccess>;
export type DeleteFilesSuccessAction = ReturnType<typeof deleteFilesSuccess>;
