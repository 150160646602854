import Router from 'next/router';
import { isEmpty } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { Countries, Users, Weddings } from '@bridebook/models';
import { appError } from 'lib/app/actions';
import { TaskActionTypes } from 'lib/task/action-types';
import {
  taskFlowDefinitionInitialized,
  userTasksLoaded,
  weddingTasksLoaded,
} from 'lib/task/actions';
import { IApplicationState, IEpicDeps } from 'lib/types';
import { noopAction } from 'lib/utils';

export const initWeddingTodayTaskListener = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(TaskActionTypes.INIT_TODAY_TASK_LISTENER),
    withLatestFrom(state$),
    filter(([_, state]: [any, IApplicationState]) => Boolean(state.weddings.profile.id)),
    mergeMap(([_, state]: [any, IApplicationState]) => {
      const weddingId = state.weddings.profile.id;
      return Weddings._.getById(weddingId)
        .Tasks.queryTodayTasks()
        .observe(true, false)
        .pipe(
          mergeMap((tasks) => of(weddingTasksLoaded(tasks))),
          takeUntil(action$.pipe(ofType(TaskActionTypes.STOP_TODAY_TASK_LISTENER))),
        );
    }),
    catchError((error: Error) =>
      of(appError({ error, feature: 'Init wedding today task listener error' })),
    ),
  );

export const initUserTodayTaskListener = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(TaskActionTypes.INIT_TODAY_TASK_LISTENER),
    withLatestFrom(state$),
    filter(([_, state]: [any, IApplicationState]) => Boolean(state.users.user?.id)),
    mergeMap(([_, state]: [any, IApplicationState]) => {
      const userId = state.users.user?.id;
      if (!userId) throw new Error('Something went wrong');
      return Users._.getById(userId)
        .Tasks.queryTodayTasks()
        .observe(true, false)
        .pipe(
          mergeMap((tasks) => of(userTasksLoaded(tasks))),
          takeUntil(action$.pipe(ofType(TaskActionTypes.STOP_TODAY_TASK_LISTENER))),
        );
    }),
    catchError((error: Error) =>
      of(appError({ error, feature: 'Init user today task listener error' })),
    ),
  );

export const initActiveTodayTaskDefinition = (action$: Observable<any>) =>
  action$.pipe(
    ofType(TaskActionTypes.INIT_TODAY_TASK_LISTENER),
    switchMap(() => {
      const todayTaskFlowId = Router?.query['todayTaskFlowId'] as string | undefined;

      if (todayTaskFlowId) {
        // eslint-disable-next-line no-console
        console.log('Selected task flow active: ' + todayTaskFlowId);
        return Countries._.getById('*')
          .TodayTaskFlows.getById(todayTaskFlowId)
          .observe(true, true)
          .pipe(
            map((response) => {
              // Check if the response is from cache and if the result is empty. If both conditions are true, exit the function to prevent issues when the client is offline and the cache data is empty.
              if (response.fromCache && isEmpty(response.result)) {
                return noopAction();
              }

              const taskFlowDefinitions = response.result;

              return taskFlowDefinitionInitialized({
                definition: taskFlowDefinitions,
              });
            }),
            takeUntil(action$.pipe(ofType(TaskActionTypes.STOP_TODAY_TASK_LISTENER))),
          );
      } else {
        return Countries._.getById('*')
          .TodayTaskFlows.active.observe(true, true)
          .pipe(
            map((response) => {
              // Check if the response is from cache and if the result is empty. If both conditions are true, exit the function to prevent issues when the client is offline and the cache data is empty.
              if (response.fromCache && isEmpty(response.result)) {
                return noopAction();
              }

              const taskFlowDefinitions = response.result;

              return taskFlowDefinitionInitialized({
                definition: Object.values(taskFlowDefinitions)[0],
              });
            }),
            takeUntil(action$.pipe(ofType(TaskActionTypes.STOP_TODAY_TASK_LISTENER))),
          );
      }
    }),
    catchError((error: Error) =>
      of(appError({ error, feature: 'Init wedding today task listener error' })),
    ),
  );
