import { ofType } from 'redux-observable';
import { first, mapTo, mergeMap, take } from 'rxjs/operators';
import { AuthActionTypes } from 'lib/auth/action-types';
import { NewOnboardingAnalyticsAction, analytics } from 'lib/onboarding-new/analytics-actions';
import { IEpic } from 'lib/types';

/**
 * An epic that sends an analytics event when the user starts onboarding.
 *
 * Waits for the `AuthActionTypes.REDIRECT_AFTER_AUTH_DONE` action to be dispatched,
 * indicating that the user has been authenticated and redirected to the onboarding page.
 * Then, listens for the `NewOnboardingAnalyticsAction.StartedUserOnboarding` action,
 * indicating that the user has started onboarding, and sends an analytics event.
 */
export const sendStartOnboardingAnalyticsEventEpic: IEpic = (action$, { muteActions }) => {
  const mutedAction$ = muteActions.actions();
  const redirectAfterAuthDone$ = action$.pipe(
    ofType(AuthActionTypes.REDIRECT_AFTER_AUTH_DONE),
    take(1),
  );

  const onboardingStart$ = redirectAfterAuthDone$.pipe(
    mergeMap(() =>
      mutedAction$.pipe(
        ofType(NewOnboardingAnalyticsAction.StartedUserOnboarding),
        first(),
        mapTo(analytics.startedUserOnboarding()),
      ),
    ),
  );

  return onboardingStart$;
};
