import { UserScopedVersionableStorageManager } from '@bridebook/toolbox/src/storage-managers/managers/user-scoped-versionable-storage-manager';

export interface ISupplierInterestConfirmationStorage {
  suppliersShown?: Record<string, boolean>;
  lastShown?: string;
}

export const SupplierInterestConfirmationStorage =
  UserScopedVersionableStorageManager<ISupplierInterestConfirmationStorage>({
    storageKey: 'supplier-interest-confirmation',
    version: '1.0',
  });
