import { Weddings } from '@bridebook/models';
import {
  IGuest,
  IGuest_Address,
  IGuest_Event,
} from '@bridebook/models/source/models/Weddings/Guests.types';
import { TranslatedError } from '@bridebook/toolbox/src';
import { GuestlistActionTypes } from 'lib/guestlist/action-types';
import { addedGuestAnalytics } from 'lib/guestlist/analytics/actions';
import { InvitationTypes } from 'lib/guestlist/constants';
import { getGuestsByGroupId } from 'lib/guestlist/utils';
import { getI18n } from 'lib/i18n/getI18n';
import { AddressComponentForm } from 'lib/search/types';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import { IDeps } from 'lib/types';

export const editNewGuest = <K extends keyof Required<IGuest>>(
  name: K,
  value: Required<IGuest>[K],
) => ({
  type: GuestlistActionTypes.EDIT_GUEST,
  payload: { name, value, method: 'popover' },
});

export const editGuestContacts = <K extends keyof Required<IGuest>['contacts']>(
  name: K,
  value: Required<IGuest>['contacts'][K],
) => ({
  type: GuestlistActionTypes.EDIT_GUEST_CONTACTS,
  payload: { name, value },
});

export const editGuestAddress = <K extends keyof IGuest_Address>(
  name: K,
  value: IGuest_Address[K],
) => ({
  type: GuestlistActionTypes.EDIT_GUEST_ADDRESS,
  payload: { name, value },
});

export const editGuest =
  (guest: IGuest, method: string) =>
  ({ dispatch }: IDeps) => {
    dispatch({
      type: 'CLICKED_EDIT_GUEST_ANALYTICS',
      payload: { method, guest },
    });
    return {
      type: GuestlistActionTypes.EDIT_GUEST_FORM,
      payload: { guest },
    };
  };

export const guestAddressManualEnter = (show: boolean) => ({
  type: GuestlistActionTypes.GUESTLIST_ADDRESS_MANUAL_ENTER,
  payload: show,
});

export const guestDiscardChangesAnalyticsAction = () => ({
  type: GuestlistActionTypes.CANCEL_EDIT_GUEST_ANALYTICS,
});

export const cancelGuestEdit =
  () =>
  ({ dispatch }: IDeps) => {
    dispatch(guestAddressManualEnter(false));
    dispatch(guestDiscardChangesAnalyticsAction());
    return {
      type: GuestlistActionTypes.CANCEL_EDIT_GUEST,
    };
  };

export const makeHead =
  (guest: IGuest) =>
  ({ getState }: IDeps) => {
    const state = getState();
    const allGuests = state.guestlist.list;
    const weddingId = state.weddings.profile.id;
    const i18n = getI18n();

    if (!guest.head) {
      throw new TranslatedError(
        'Guest is already a Group Head',
        i18n.t('guestlist:errorGuestGroupHead'),
      );
    }

    const otherGuestsInGroup = getGuestsByGroupId(guest.head, allGuests).filter(
      (g) => g.id !== guest.id,
    );

    const Guests = Weddings._.getById(weddingId).Guests;

    // Begin batch operation
    Guests.begin();

    // Make guest the Head of the group by removing his reference to any other Head,
    // and add references in other guests in the group.
    Guests.getById(guest.id).set({
      head: null,
    });

    for (const otherGuest of otherGuestsInGroup) {
      Guests.getById(otherGuest.id).set({
        head: guest.id,
      });
    }

    Guests.commit();

    return {
      type: GuestlistActionTypes.MAKE_GROUP_HEAD,
    };
  };

export const setGuestLocation = (location: AddressComponentForm<string>) => ({
  type: GuestlistActionTypes.SET_GUEST_LOCATION,
  payload: location,
});

export const addPlusOne = (plusOne: IGuest) => ({
  type: GuestlistActionTypes.ADD_PLUS_ONE,
  payload: plusOne,
});

export const removePlusOne = (id: string) => ({
  type: GuestlistActionTypes.REMOVE_PLUS_ONE,
  payload: id,
});

export const saveCustomPlusOne =
  (plusOne: IGuest) =>
  ({ getState, dispatch, cordovaTracker }: IDeps) => {
    const weddingId = getState().weddings.profile.id;
    Weddings._.getById(weddingId).Guests.push(plusOne.id).create(plusOne);

    cordovaTracker.track(TrackingEvent.AddedGuest);
    dispatch(addedGuestAnalytics({ edit: true, method: 'plus1', guest: plusOne }));

    return {
      type: GuestlistActionTypes.SAVE_CUSTOM_PLUS_ONE,
      payload: plusOne,
    };
  };

export const createPlusOneAndAssign =
  (plusOne: IGuest) =>
  ({ dispatch }: IDeps) => {
    dispatch(saveCustomPlusOne(plusOne));
    dispatch(addPlusOne(plusOne));

    return {
      type: GuestlistActionTypes.CREATE_PLUS_ONE_AND_ASSIGN,
    };
  };

export const editInvitationType = (invitation: InvitationTypes) => ({
  type: GuestlistActionTypes.EDIT_GUEST_INVITATION_TYPE,
  payload: invitation,
});

export const editRsvpStatus = (status: IGuest_Event['attending']) => ({
  type: GuestlistActionTypes.EDIT_GUEST_RSVP_STATUS,
  payload: status,
});
