export const getWindowSizes = (isMobileUA: boolean, isTabletUA: boolean) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isLandscape = width > height;
  const isMobile = width < 641;
  const isTablet = width > 641 && width < 1025;
  const isDesktop = width > 1024;
  const isDesktopDesign = isDesktop || (isTablet && isLandscape);

  return {
    width,
    height,
    isMobile: isMobileUA && isLandscape ? true : isMobile,
    isTablet: isTabletUA && isLandscape ? true : isTablet,
    isLandscape,
    isDesktop,
    isDesktopDesign,
  };
};
