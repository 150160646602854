import { ofType } from 'redux-observable';
import { map, take } from 'rxjs/operators';
import { OnboardingAction, SearchResultsLoadedPayload } from 'lib/onboarding-new/actions';
import { analytics } from 'lib/onboarding-new/analytics-actions';
import { Action, IEpic } from 'lib/types';
import { withCurrentUser } from 'lib/utils/operators/with-current-user';

type InputAction = Required<Action<SearchResultsLoadedPayload>>;

export const sendLoadedSearchResultsAnalyticsEvent: IEpic<InputAction> = (action$, { state$ }) =>
  action$.pipe(
    ofType(OnboardingAction.SearchResultsLoaded),
    withCurrentUser(state$),
    map(({ user, action }) =>
      analytics.loadedSearchResults({
        searchActiveFilters: action.payload.searchActiveFilters,
        searchSupplierCategory: action.payload.searchSupplierCategory,
        searchSortedBy: action.payload.searchSortedBy,
        searchResultsTotal: action.payload.searchResultsTotal,
        list: action.payload.list,
        searchTerm: action.payload.searchTerm,
        userId: user.id,
      }),
    ),
    take(1),
  );
