import { ofType } from 'redux-observable';
import { Observable, Observer, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { Action } from 'lib/types';
import { saveVenueLocation } from 'lib/weddings/actions';
import { ShortlistActionTypes, ShortlistBookedSupplierAction } from '../action-types';

export const shortlistBookSupplierUpdateLocationEpic = (
  action$: Observable<ShortlistBookedSupplierAction>,
): Observable<any> =>
  action$.pipe(
    ofType(ShortlistActionTypes.BOOK_SUPPLIER),
    mergeMap(({ payload: { item } }: Action) =>
      Observable.create((observer: Observer<any>) => {
        if (item.slug === 'venue' || item.type === 'venue') {
          observer.next(
            saveVenueLocation(
              {
                adminArea: [item.county, item.country],
                city: item.town,
                name: item.country,
                country: item.countryCode,
              },
              item.customSupplier,
            ),
          );
        }
      }),
    ),
    catchError((error) => of(appError({ error, feature: 'Shortlist' }))),
  );
