import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  ChecklistActionTypes,
  IUpdateSubtaskAction,
  IUpdateTaskSuccessAction,
} from 'lib/checklist/action-types';
import { IEpicDeps } from 'lib/types';
import { triggerBookingConfirmationModal } from 'lib/venue-confirmation/actions';
import {
  triggeringMainTaskIds,
  triggeringSubtaskIds,
} from 'lib/venue-confirmation/data/triggering-checklist-tasks';
import { hasShownPopupToday } from 'lib/venue-confirmation/utils';
import { getVenueBooked } from 'lib/weddings/selectors';

/**
 * Opens Venue Confirmation Popup when user completes any MAIN TASK from triggers list
 */
export const openVenuePopupOnChecklistMainTaskChange = (
  action$: Observable<IUpdateTaskSuccessAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(ChecklistActionTypes.UPDATE_TASK_SUCCESS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const {
        payload: {
          task: { id: checklistTaskId },
          checked,
        },
      } = action;

      if (hasShownPopupToday()) return of();

      const venueBooked = getVenueBooked(state);
      const isMainTask = Boolean(state.app.groups[checklistTaskId]);

      if (
        !venueBooked &&
        isMainTask &&
        checked &&
        triggeringMainTaskIds.includes(checklistTaskId)
      ) {
        return of(triggerBookingConfirmationModal({ showIntro: true, location: 'checklist' }));
      }

      return of();
    }),
  );

/**
 * Opens Venue Confirmation Popup when user completes any SUBTASK from triggers list
 */
export const openVenuePopupOnChecklistSubtaskChange = (
  action$: Observable<IUpdateSubtaskAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(ChecklistActionTypes.UPDATE_SUBTASKS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      if (hasShownPopupToday()) return of();

      const {
        payload: { tasks },
      } = action;

      const venueBooked = getVenueBooked(state);
      const taskIds = tasks.filter((task) => task.checked).map((task) => task.taskId);
      const containsTriggeringTasks = taskIds.some((id) => triggeringSubtaskIds.includes(id));

      if (!venueBooked && containsTriggeringTasks) {
        return of(triggerBookingConfirmationModal({ showIntro: true, location: 'checklist' }));
      }

      return of();
    }),
  );
