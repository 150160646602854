import { createSelector } from 'reselect';
import { RequiredTiersManager } from 'lib/premium/required-tiers-manager';
import {
  getCurrentLoadingSupplierId,
  getCurrentLoadingSupplierType,
  getPremium,
} from 'lib/supplier/selectors';
import { IApplicationState } from 'lib/types';
import { getWeddingLocation } from 'lib/weddings/selectors';
import { RWState } from './types';

const getRealWeddings = (state: IApplicationState) => state.realWeddings;
export const getRealWeddingsLoading = (state: IApplicationState) => state.realWeddings.loading;

export const getRealWeddingsList = (state: IApplicationState) => state.realWeddings.list;
export const getRealWeddingsListType = (state: IApplicationState) => state.realWeddings.listType;
export const getRealWeddingsLoaded = (state: IApplicationState) => state.realWeddings.loaded;

export const getHasRequiredTierForRealWeddings = createSelector(
  [getPremium, getCurrentLoadingSupplierType],
  (premium, supplierType) => {
    const requiredTier = RequiredTiersManager.supplier.realWeddings(supplierType);
    return requiredTier <= 0 || !!(premium?.tier && premium.tier >= requiredTier);
  },
);

export const getIsRealWeddingsLoading = createSelector(
  [getRealWeddings],
  (realWeddings) => realWeddings.loading,
);

// Get only those real weddings that supplier is allowed to display based on their tier (returns all if no supplierId)
export const getRealWeddingsToShow = createSelector(
  [getRealWeddings, getHasRequiredTierForRealWeddings, getCurrentLoadingSupplierId],
  (realWeddings, hasRequiredTier, supplierId) =>
    !hasRequiredTier && realWeddings.listType === 'supplier'
      ? realWeddings.list.filter((realWedding) => realWedding.createdBy !== supplierId)
      : realWeddings.list,
);

export const REAL_WEDDINGS_MIN_TILES: Record<Exclude<RWState['listType'], null>, number> = {
  supplier: 1,
  venue: 4,
  near: 4,
};

// This selector is different from `getHasRealWeddingsCarousel` because in some cases,
// after fetching the data the carousel is hidden
export const getShowRealWeddingsCarousel = createSelector(
  [
    getRealWeddings,
    getRealWeddingsToShow,
    getCurrentLoadingSupplierId,
    getHasRequiredTierForRealWeddings,
  ],
  (realWeddings, realWeddingsToShow, supplierId, hasRequiredTier) => {
    // Error fetching real weddings
    if (realWeddings.error) return false;

    // Not enough real weddings per supplier type
    if (
      realWeddingsToShow.length <
      (realWeddings.listType ? REAL_WEDDINGS_MIN_TILES[realWeddings.listType] : 4)
    )
      return false;

    // For list type "near" or "venue" we don't want to check the tier/tagged logic
    if (realWeddings.listType !== 'supplier') return true;

    // If the supplier has any tagged real weddings - display them. If not - check the required tier for the feature
    const hasTaggedRealWeddings = realWeddingsToShow.some(
      (realWedding) => realWedding.createdBy !== supplierId,
    );

    return hasTaggedRealWeddings || hasRequiredTier;
  },
);

export const getNearLocation = createSelector([getWeddingLocation], (location) => {
  const adminArea = location?.adminArea;
  const county = adminArea?.length === 2 ? adminArea[0] : null;

  return county;
});
