import { IBookingFile } from '@bridebook/toolbox/src/types';
import { FileManagementActionTypes } from 'lib/files/action-types';

export const deleteFilesSuccess = (files: IBookingFile[]) => ({
  type: FileManagementActionTypes.DELETE_FILES_SUCCESS,
  payload: { files },
});

export const fetchFiles = () => ({
  type: FileManagementActionTypes.FETCH_FILES,
});

export const fetchFilesSuccess = (payload: { files: IBookingFile[] }) => ({
  type: FileManagementActionTypes.FETCH_FILES_SUCCESS,
  payload,
});
