import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { env } from 'lib/env';

export const createMicrosite = async ({
  weddingId,
  id,
}: {
  weddingId: string;
  id: string;
}): Promise<{ success: boolean; error: string }> =>
  authenticatedFetch(`/api/microsite/create`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ query: { weddingId, id } }),
  }).then((res) => res.json());

export const deleteMicrosite = async ({ weddingId }: { weddingId: string }): Promise<void> =>
  authenticatedFetch(`/api/microsite/delete`, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ query: { weddingId } }),
  }).then(() => {
    return;
  });

export const validateMicrosite = (validate: Function, fields: Object): Promise<any> =>
  validate(fields).prop('microsite').urlFriendly().promise;

/**
 * Returns an object with an URL to a microsite or address collector with a given id,
 * and also specific parts of that URL so it can be combined afterwards (for highlighting a specific part).
 *
 * @param micrositeId
 * @param prefix
 * @param addressCollectorUrl - if true, the url will lead to address collector
 */
export const getMicrositeUrl = ({
  micrositeId,
  locale,
  addressCollectorUrl,
}: {
  micrositeId: string;
  locale: string;
  addressCollectorUrl?: boolean;
}) => {
  const micrositeSubpage = addressCollectorUrl ? `/guest` : '';
  const micrositeUrlBase = env.MICROSITE_URL + '/' + locale + '/';
  const micrositeFullUrl = micrositeUrlBase + micrositeId + micrositeSubpage;

  return {
    // Full absolute URL
    micrositeFullUrl,
    // Base URL without id or subpage
    micrositeUrlBase,
    // Just subpage part, eg. to address collector
    micrositeSubpage,
  };
};
