import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { mapEventToAppsFlyerEvent } from 'lib/track-utils/mappers/event-to-appsflyer-event';
import { ITrackingMapper } from 'lib/track-utils/tracking-mappers';

export const viewedSupplierProfileMapper: ITrackingMapper<ISupplier> = ({ event, payload }) => [
  mapEventToAppsFlyerEvent(event),
  {
    af_content_id: payload.id,
    af_country: payload.address.country,
    af_region: payload.address.adminArea[1],
    af_content_type: payload.type[0],
  },
];
