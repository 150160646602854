import { IDeps } from 'lib/types';
import { BudgetActionTypes } from '../action-types';

export const toggleCustomCostModal = (groupId: string | null = null) => {
  return ({ getState, dispatch }: IDeps) => {
    const show = !getState().budget.showCustomCostModal;
    if (show) {
      dispatch({
        type: 'ADD_NEW_SUPPLIER_BUDGET_ANALYTICS',
        payload: { id: groupId },
      });
    }

    return {
      type: BudgetActionTypes.TOGGLE_CUSTOM_COST_MODAL,
      payload: { show, groupId },
    };
  };
};
