import { UnreadConversation } from 'talkjs/types/talk.types';
import { isArchivedByCouple } from '@bridebook/toolbox/src/inbox/utils';
import { WeddingActionTypes } from 'lib/weddings/action-types';

export const updateInboxUnread = (conversations: UnreadConversation[]) => {
  const activeConversations = conversations.filter(
    // Filter out archived conversations
    (unread) => !isArchivedByCouple(unread.conversation),
  );
  return {
    type: WeddingActionTypes.UPDATE_INBOX_UNREAD,
    payload: {
      unreadCount: activeConversations.length,
    },
  };
};
