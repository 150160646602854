import { find, isEmpty, filter as rfilter, values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { IGroup } from '@bridebook/models/source/models/Countries/Groups.types';
import { ICost } from '@bridebook/models/source/models/Weddings/Costs.types';
import { IBudgetInitForm } from 'lib/budget/types';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import { IDeps, IEpicDeps } from 'lib/types';
import { WebAnalyticsContext } from '../../bbcommon/utils/bridebook-analytics';
import { BudgetActionTypes, IInitialiseSuccessAction } from '../action-types';
import { BudgetAnalyticsActionTypes } from './action-types';

const getOptionalCategories = (fields: IBudgetInitForm['fields']) =>
  Object.keys(rfilter((cat) => cat, fields.optionalCategories));

const filterBudgetProps = (props: any) => {
  const newProps = { ...props };

  delete newProps.budgetItemNote;
  return newProps;
};

export const budgetAnalyticsEpic = (
  action$: Observable<IInitialiseSuccessAction>,
  { cordovaTracker }: IEpicDeps,
) => {
  const initialiseDynamicBudgetSuccess$ = action$.pipe(
    ofType(BudgetActionTypes.INITIALISE_DYNAMIC_BUDGET_SUCCESS),
  );

  return action$.pipe(
    ofType(BudgetActionTypes.SAVE_BUDGET_SUCCESS),
    withLatestFrom(initialiseDynamicBudgetSuccess$),
    tap(() => cordovaTracker.track(TrackingEvent.CalculatedBudget)),
    mergeMap(() => [
      {
        type: BudgetAnalyticsActionTypes.INITIALISE_DYNAMIC_BUDGET_CALCULATED_ANALYTICS,
      },
    ]),
  );
};

export default function budgetAnalytics(
  action: any,
  bridebookAnalytics: WebAnalyticsContext,
  getState: IDeps['getState'],
): void {
  const { type } = action;
  const payload = action.payload || {};
  const getBudgetInitialTarget = () => {
    const {
      weddings: {
        profile: { budget },
      },
      budget: {
        budgetInitForm: {
          fields: { budget: budgetInitForm },
        },
      },
    } = getState();

    return budget || Number(budgetInitForm);
  };

  const findBudgetVenueItem = (item: Record<string, ICost>) => {
    const venueItem = find((item) => item.name === 'Venue', values(item));
    return {
      ...venueItem,
      actual: venueItem ? Math.floor(venueItem.actual) : 0,
      estimate: venueItem ? Math.floor(venueItem.estimate) : 0,
    };
  };

  const budgetPropertiesGeneric = () => {
    const {
      weddings: {
        profile: { costs: budgetTotals, budget },
      },
      budget: { costs },
    } = getState();
    const { actual = 0, estimate = 0 } = budgetTotals || {};
    let budgetVenueActual = 0;
    let budgetVenueEstimate = 0;

    if (!isEmpty(values(costs))) {
      const budgetVenueItem = findBudgetVenueItem(costs);
      budgetVenueActual = budgetVenueItem.actual;
      budgetVenueEstimate = budgetVenueItem.estimate;
    }

    return {
      budgetEstimateTotal: estimate,
      budgetActualTotal: actual,
      budgetOverBudget: estimate - (budget || 0),
      budgetVenueActual,
      budgetVenueEstimate,
    };
  };

  const budgetInitialPropertiesGeneric = () => {
    const {
      weddings: {
        profile: {
          budget,
          l10n: { currency },
        },
      },
      budget: {
        budgetInitForm: { fields },
      },
    } = getState();
    return {
      budgetInitialTarget: budget ? Number(budget) : Number(fields.budget),
      budgetInitialGuests: fields.guestsEstimate,
      budgetInitialDay: fields.timeOfWeek,
      budgetInitialTimeOfYear: fields.timeOfYear,
      budgetInitialYear: fields.year,
      budgetInitialOptionalCategories: getOptionalCategories(fields),
      currency,
    };
  };

  const getBudgetItemPropertiesGeneric = (cost: ICost) => {
    const {
      app: { groups },
    } = getState();
    const costGroup = values(groups).find((group) => group.id === cost.group) || ({} as IGroup);
    return {
      budgetItemId: cost.id,
      budgetItemCategory: cost.supplierType || cost.name,
      budgetItemSection: costGroup.name,
      budgetItemEstimate: cost.estimate,
      budgetItemActual: cost.actual,
      budgetItemNote: cost.notes || '',
    };
  };

  const getCalculateBudgetGenerics = () => ({
    ...budgetPropertiesGeneric(),
    ...budgetInitialPropertiesGeneric(),
    budgetInitialTarget: getBudgetInitialTarget(),
  });

  const getBudgetItemGenerics = (cost: ICost) => ({
    ...budgetPropertiesGeneric(),
    ...budgetInitialPropertiesGeneric(),
    ...getBudgetItemPropertiesGeneric(cost),
  });

  const { track } = bridebookAnalytics.getMethods('Budget', filterBudgetProps);

  switch (type) {
    case BudgetAnalyticsActionTypes.SET_BUDGET_INIT_ANALYTICS:
      track({
        event: 'Entered target budget',
        ...budgetPropertiesGeneric(),
        ...budgetInitialPropertiesGeneric(),
        enteredTargetBudgetMethod: payload,
      });
      break;
    case BudgetAnalyticsActionTypes.SET_BUDGET_INIT_FORM_FIELD_ANALYTICS: {
      const { name, value } = payload;
      track({
        event: 'Selected budget initialisation options',
        ...budgetPropertiesGeneric(),
        ...budgetInitialPropertiesGeneric(),
        selectedBudgetInitialisationOptionsName: name,
        selectedBudgetInitialisationOptionsValue: value,
      });
      break;
    }
    case 'TOGGLE_BUDGET_INIT_FORM_CATEGORY_ANALYTICS': {
      const { name, currentVal } = payload;
      track({
        event: 'Toggled optional categories in budget initialisation',
        ...budgetPropertiesGeneric(),
        ...budgetInitialPropertiesGeneric(),
        toggledOptionalCategoryName: name,
        toggledOptionalCategoryValue: !currentVal,
      });
      break;
    }
    case BudgetAnalyticsActionTypes.INITIALISE_DYNAMIC_BUDGET_CALCULATED_ANALYTICS:
      track({
        event: 'Calculated budget',
        ...getCalculateBudgetGenerics(),
      });
      break;
    case 'INITIALISE_DYNAMIC_BUDGET_FAILED_TO_CALCULATE_ANALYTICS':
      track({
        event: 'Failed to calculate budget',
        ...getCalculateBudgetGenerics(),
        failedToCalculateBudgetReason: payload.reason,
      });
      break;
    case 'ADD_NEW_SUPPLIER_BUDGET_ANALYTICS':
      track({
        event: 'Clicked add budget item',
        ...budgetPropertiesGeneric(),
        ...budgetInitialPropertiesGeneric(),
      });
      break;
    case BudgetAnalyticsActionTypes.CLICKED_UPDATE_BUDGET_ITEMS: {
      const { costs } = getState().budget;
      const cost = costs[payload.itemId];

      track({
        event: 'Clicked update budget item',
        ...getBudgetItemGenerics(cost),
      });
      break;
    }
    case BudgetAnalyticsActionTypes.CLICKED_DELETE_BUDGET_ITEM_ANALYTICS:
      track({
        event: 'Clicked delete budget item',
        ...getBudgetItemGenerics(payload),
      });
      break;
    case BudgetAnalyticsActionTypes.EDIT_SUPPLIER_BUDGET_ADDED_ANALYTICS:
      track({
        event: 'Added budget item',
        ...getBudgetItemGenerics(payload),
      });
      break;
    case 'FAILED_TO_ADD_BUDGET_ITEM_ANALYTICS': {
      const { message, item } = payload;
      track({
        event: 'Failed to add budget item',
        failedToAddBudgetItemReason: message,
        ...budgetPropertiesGeneric(),
        ...budgetInitialPropertiesGeneric(),
        ...getBudgetItemPropertiesGeneric(item),
      });
      break;
    }
    case BudgetAnalyticsActionTypes.DELETE_SUPPLIER_BUDGET_ANALYTICS:
      track({
        event: 'Deleted budget item',
        ...getBudgetItemGenerics(payload),
      });
      break;
    case BudgetAnalyticsActionTypes.UPDATED_BUDGET_ITEM_ANALYTICS:
      track({
        event: 'Updated budget item',
        ...getBudgetItemGenerics(payload),
      });
      break;
    case BudgetAnalyticsActionTypes.CLICKED_RESET_BUDGET_ANALYTICS:
      track({
        event: 'Clicked reset budget',
        ...budgetPropertiesGeneric(),
        ...budgetInitialPropertiesGeneric(),
      });
      break;
    case BudgetAnalyticsActionTypes.RESET_BUDGET_ANALYTICS: {
      track({
        event: 'Reset budget',
        ...budgetPropertiesGeneric(),
        ...budgetInitialPropertiesGeneric(),
      });
      break;
    }
    case BudgetAnalyticsActionTypes.FOLLOWED_LINK_ON_BUDGET_ANALYTICS: {
      const { cost, link, category } = payload;
      track({
        event: 'Followed link on budget item',
        ...getBudgetItemGenerics(cost),
        followedLinkOnBudgetItemUrl: link,
        followedLinkOnBudgetItemMethod: category,
      });
      break;
    }
    case 'CLICKED_TO_EDIT_TARGET_BUDGET_ANALYTICS':
      track({
        event: 'Clicked to edit target budget',
        ...budgetPropertiesGeneric(),
        ...budgetInitialPropertiesGeneric(),
      });
  }
}
