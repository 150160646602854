import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { IUser } from '@bridebook/models/source/models/Weddings/Users.types';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { IDeps } from 'lib/types';
import { updateWedding } from 'lib/weddings/actions';

export const onAccessControlListener = (acl: Record<string, IUser>) => ({
  type: AccessControlActionTypes.ON_ACCESS_CONTROL_LISTENER,
  payload: acl,
});

export const updateAccessControlSuccess =
  (activeWedding: IWedding) =>
  ({ dispatch }: IDeps) => {
    dispatch(updateWedding(activeWedding));
    return {
      type: AccessControlActionTypes.UPDATE_ACCESS_CONTROL_SUCCESS,
    };
  };
