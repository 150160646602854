import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Users } from '@bridebook/models';
import { appError } from 'lib/app/actions';
import getAuthStatus from 'lib/auth/utils/get-auth-status';
import { Action, IEpicDeps } from 'lib/types';

export const viewedShortlistEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType('ROUTE_CHANGE_COMPLETE'),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { payload } = action;
      const {
        users: { user },
      } = state;
      const isLoggedIn = getAuthStatus({ user });

      // save visited shortlist and set addToBooked to true if booked tab is opened so that suggested and custom suppliers would be added as booked
      if (payload && payload.url === '/shortlist' && isLoggedIn && user) {
        const getPromise = () =>
          Users._.getById(user.id).set({
            actions: { hasViewedShortlist: true },
          });

        return of(getPromise()).pipe(
          mergeMap(() =>
            of({
              type: 'SET_VIEWED_SHORTLIST_DONE',
            }),
          ),
        );
      }

      return of();
    }),
    catchError((error) => of(appError({ error, feature: 'Shortlist' }))),
  );
