import { IGroup } from '@bridebook/models/source/models/Countries/Groups.types';
import { commonMapper } from 'lib/track-utils/mappers/common-mapper';
import { ITrackingMapper } from 'lib/track-utils/tracking-mappers';

interface IUpdateMainTaskMapperPayload {
  taskId: IGroup;
}

export const updateMainTaskMapper: ITrackingMapper<IUpdateMainTaskMapperPayload> = (
  action,
  state,
) => {
  const [event, data] = commonMapper(action, state);
  const {
    payload: { taskId },
  } = action;
  return [
    event,
    {
      ...data,
      task_id: taskId,
    },
  ];
};
