import { keys } from 'ramda';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { BudgetActionTypes } from 'lib/budget/action-types';
import { ChecklistGroupIDs, ChecklistSubtaskIDs } from 'lib/checklist/data/task-ids';
import { slugEquals } from 'lib/connected-actions/checklist/helpers';
import { GuestlistActionTypes } from 'lib/guestlist/action-types';
import { ScrapbookActionTypes } from 'lib/scrapbook/action-types';
import { ShortlistActionTypes } from 'lib/shortlist/action-types';

export interface IChecklistConnectedAction {
  // Groups within which all the tasks should be ticked off as done
  // Important! By default, the last groupId will be used for
  // snackbar message and opening interior.
  groupIds?: Array<ChecklistGroupIDs>;
  // You can also select individual subtasks to be ticked off
  subtaskIds?: Array<ChecklistSubtaskIDs>;
  // Optional snackbar config
  snackbar?: {
    // Used for opening interior
    groupId: ChecklistGroupIDs;
    // Override snackbar message with this subtask name
    subtaskId?: ChecklistSubtaskIDs;
  };
}

interface IChecklistConnectedActionMultiple extends IChecklistConnectedAction {
  // If the same action can trigger many tasks, add an extra condition
  // when the task should be triggered based on the action's payload.
  // Trigger must be unique within one parent action.
  triggerOnlyIf: (action: any) => boolean;
}

export const ChecklistConnectedActions: Record<
  string,
  IChecklistConnectedAction | Array<IChecklistConnectedActionMultiple>
> = {
  [AccessControlActionTypes.INVITE_COLLABORATOR_SUCCESS]: {
    groupIds: [ChecklistGroupIDs['Invite Your Partner']],
  },
  [GuestlistActionTypes.SAVE_GUEST_DRAFT]: {
    groupIds: [ChecklistGroupIDs['Start Guest List']],
  },
  [BudgetActionTypes.BUDGET_INIT_COMPLETED]: {
    groupIds: [ChecklistGroupIDs['Outline Your Budget']],
  },
  [ScrapbookActionTypes.SAVE_NOTE_TO_FIREBASE_SUCCESS]: {
    groupIds: [ChecklistGroupIDs['Start Saving Notes']],
  },
  [GuestlistActionTypes.EXPORT_GUESTLIST_CSV]: {
    subtaskIds: [ChecklistSubtaskIDs['Finalise and export guest list']],
    snackbar: {
      groupId: ChecklistGroupIDs['Send Your Save-The-Dates'],
      subtaskId: ChecklistSubtaskIDs['Finalise and export guest list'],
    },
  },
  [ShortlistActionTypes.ADDED_TO_BOOKED_SUPPLIERS]: [
    {
      groupIds: [
        ChecklistGroupIDs['Begin Exploring Venues'],
        ChecklistGroupIDs['Start Researching Venues'],
        ChecklistGroupIDs['Schedule Visits of Venues'],
        ChecklistGroupIDs['Choose Your Venue'],
        ChecklistGroupIDs['Book Your Venue'],
      ],
      triggerOnlyIf: slugEquals('venue'),
    },
    {
      groupIds: [ChecklistGroupIDs['Snap up a Photographer']],
      triggerOnlyIf: slugEquals('photo'),
    },
    {
      groupIds: [ChecklistGroupIDs['Find Your Florist']],
      triggerOnlyIf: slugEquals('florist'),
    },
    {
      groupIds: [ChecklistGroupIDs['Shop for Wedding Dresses']],
      triggerOnlyIf: slugEquals('dress'),
    },
    {
      groupIds: [ChecklistGroupIDs['Book Your Caterer']],
      triggerOnlyIf: slugEquals('catering'),
    },
    {
      groupIds: [ChecklistGroupIDs['Book Your Reception Music']],
      triggerOnlyIf: slugEquals('music'),
    },
    {
      groupIds: [ChecklistGroupIDs['Book Your Cake Maker']],
      triggerOnlyIf: slugEquals('cakes'),
    },
    {
      groupIds: [ChecklistGroupIDs['Select Your Stationery']],
      triggerOnlyIf: slugEquals('stationery'),
    },
    {
      groupIds: [ChecklistGroupIDs['Choose Your Transport']],
      triggerOnlyIf: slugEquals('transport'),
    },
    {
      groupIds: [ChecklistGroupIDs['Shop for Men Fashion']],
      triggerOnlyIf: slugEquals('menswear'),
    },
    {
      groupIds: [ChecklistGroupIDs['Buy Wedding Rings']],
      triggerOnlyIf: slugEquals('jewellery'),
    },
    {
      groupIds: [ChecklistGroupIDs['Book Beauty Stylists']],
      triggerOnlyIf: slugEquals('beauty'),
    },
  ],
};

export const checklistConnectedActionTypes = keys(ChecklistConnectedActions);
