import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { IEpicDeps } from 'lib/types';
import enquiriesServerSideTrack from '../../utils/enquiries-server-side-track';
import {
  EnquiriesAnalyticsActionTypes,
  EnquiriesServerSideTrackStartAction,
} from '../action-types';

const enquiriesServerSideTrackEpic = (
  action$: Observable<EnquiriesServerSideTrackStartAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(EnquiriesAnalyticsActionTypes.ENQUIRIES_SERVER_SIDE_TRACK),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { event, supplier, infoProps = {} },
        },
        state,
      ]) => {
        const promise = enquiriesServerSideTrack({
          state,
          contactedSupplier: supplier,
          event,
          infoProps,
        });

        return from(promise).pipe(
          map(() => ({
            type: EnquiriesAnalyticsActionTypes.ENQUIRIES_SERVER_SIDE_TRACK_SUCCESS,
          })),
          catchError((error: Error) =>
            of(appError({ error, feature: 'enquiriesServerSideTrackEpic' })),
          ),
        );
      },
    ),
  );

export default enquiriesServerSideTrackEpic;
