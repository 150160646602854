import { IUISupplier, Slug } from '@bridebook/toolbox/src/types';
import { CreateEnquiryResponse } from 'lib/onboarding-new/types';

export enum OnboardingAction {
  SendEnquiries = '[Onboarding] Send enquiries',
  SendEnquiriesSuccess = '[Onboarding] Send enquiries - success',
  SendEnquiriesFailure = '[Onboarding] Send enquiries - failure',
  AttemptToSendTalkJSNotification = '[Onboarding] Attempt to send TalkJS notification',
  SearchResultsLoaded = '[Onboarding] Search results loaded',
}

export type SendEnquiriesPayload = {
  suppliers: IUISupplier[];
  message: string;
  executeRecaptcha(action?: string | undefined): Promise<string>;
};

export const sendEnquiries = (payload: SendEnquiriesPayload) => ({
  type: OnboardingAction.SendEnquiries,
  payload,
});

export const sendEnquiriesSuccess = (payload: CreateEnquiryResponse[]) => ({
  type: OnboardingAction.SendEnquiriesSuccess,
  payload,
});

export const sendEnquiriesFailure = (payload: IUISupplier[]) => ({
  type: OnboardingAction.SendEnquiriesFailure,
  payload,
});

export interface SearchResultsLoadedPayload {
  searchActiveFilters: Record<string, any>;
  searchSortedBy: 'popular' | 'favourites';
  searchResultsTotal: number;
  list: IUISupplier[];
  searchSupplierCategory?: Slug;
  searchTerm?: string;
}

export const searchResultsLoaded = (payload: SearchResultsLoadedPayload) => ({
  type: OnboardingAction.SearchResultsLoaded,
  payload,
});

export interface AttemptToSendTalkJSMessagePayload {
  inboxMessageSent: boolean;
  supplierId: string;
}
export const attemptToSendTalkJSMessage = (payload: AttemptToSendTalkJSMessagePayload) => ({
  type: OnboardingAction.AttemptToSendTalkJSNotification,
  payload,
});
