import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { extractLocationName } from 'app-shared/lib/search/utils/utils';
import { AuthActionTypes } from 'lib/auth/action-types';
import { setSearchLocationEnd } from 'lib/search/actions';
import { Action, IEpicDeps } from 'lib/types';
import { noopAction } from 'lib/utils';
import { withCurrentWeddingCountry } from 'lib/utils/operators/with-current-wedding-locale';
import {
  IUpdateWeddingAction,
  IUpdateWeddingFieldAction,
  WeddingActionTypes,
} from 'lib/weddings/action-types';
import formatArea from '../utils/format-prediction-area';

export const updateSearchLocationOnWeddingLocationChange = (
  action$: Observable<IUpdateWeddingFieldAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(WeddingActionTypes.UPDATE_WEDDING_FIELD_SUCCESS),
    withCurrentWeddingCountry(state$),
    mergeMap(({ action, weddingCountry }) => {
      const { payload } = action;
      const hasLocationInPayload = !!(
        payload &&
        payload.name === 'location' &&
        payload.value?.location
      );

      if (hasLocationInPayload) {
        const location = payload.value.location as IWedding['location'];
        const weddingLocation = extractLocationName(location);
        const countryCode = Gazetteer.isValidCountryCode(payload.value.location?.country)
          ? payload.value.location?.country
          : weddingCountry;

        return of(
          setSearchLocationEnd({
            status: 'initialized',
            draft: {
              searchType: 'autocomplete',
              searchText: weddingLocation,
            },
            selected: {
              placeId: location?.provider?.id || '',
              area: weddingLocation,
              searchText: weddingLocation,
              searchType: 'autocomplete',
              countryCode,
            },
          }),
        );
      }

      return of(noopAction());
    }),
  );

// this is called only when search location was not loaded from local storage
export const setDefaultLocationToWeddingLocationWhenSearchLocationNotInitialized = (
  action$: Observable<IUpdateWeddingAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType<Action>(WeddingActionTypes.UPDATE_WEDDING, AuthActionTypes.USER_SETUP_COMPLETED),
    withCurrentWeddingCountry(state$),
    mergeMap(({ action, state, weddingCountry }) => {
      const { wedding } = action.payload;
      const searchLocation = state.search.searchLocation;

      if (searchLocation.status === 'initialized' && searchLocation.draft.searchText) {
        return of();
      } else {
        const weddingLocation = extractLocationName(wedding.location);
        const label = formatArea(weddingLocation);
        const countryCode = Gazetteer.isValidCountryCode(wedding.location?.country)
          ? wedding.location?.country
          : weddingCountry;

        return of(
          setSearchLocationEnd({
            status: 'initialized',
            draft: {
              searchType: 'autocomplete',
              searchText: weddingLocation,
            },
            selected: {
              placeId: wedding.location?.provider?.id || '',
              searchType: 'autocomplete',
              searchText: label,
              area: weddingLocation,
              countryCode,
            },
          }),
        );
      }
    }),
  );
