import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, ignoreElements, mergeMap, withLatestFrom } from 'rxjs/operators';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { appError } from 'lib/app/actions';
import { HideSplashScreenAction, MobileAppActionTypes } from 'lib/mobile-app/action-types';
import { getIsCordova, getIsIOS } from 'lib/mobile-app/selectors';
import { IEpicDeps } from 'lib/types';
import { isCordovaApp } from 'lib/utils';

/*
 * Hide splash screen after nextjs app is ready and initialised
 */
export const hideSplashScreenEpic = (
  action$: Observable<HideSplashScreenAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(MobileAppActionTypes.HIDE_SPLASH_SCREEN),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const isCordovaDevice = getIsCordova(state) || isCordovaApp();
      const isIOS = getIsIOS(state);

      const getPromise = async () => {
        if (isCordovaDevice) {
          if (isIOS) {
            await StatusBar.setStyle({
              style: Style.Light,
            });
          }

          return SplashScreen.hide();
        }
        return true;
      };

      return from(getPromise()).pipe(ignoreElements());
    }),
    catchError((error: Error) => of(appError({ error, feature: 'Mobile App' }))),
  );
