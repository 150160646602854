export function getBaseURL() {
  if (process.env.NEXT_PUBLIC_COUPLESIDE_URL != null) {
    return process.env.NEXT_PUBLIC_COUPLESIDE_URL;
  }

  if (process.env.VERCEL_ENV === 'production') {
    return 'https://bridebook.com';
  }

  return 'http://bridebook-dev.herokuapp.com';
}
