import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { useUserAgent } from 'next-useragent';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Appsflyer } from '@ionic-native/appsflyer';
import { env } from 'lib/env';
import { getIsIOS as getIsIos } from 'lib/mobile-app/selectors';
import { ITrackEventAction } from 'lib/track-utils/actions';
import { trackingMappers } from 'lib/track-utils/tracking-mappers';
import { ignore } from 'lib/track-utils/with-inner-stream';
import { IApplicationState, IEpic } from 'lib/types';

/**
 * Cordova tracking epic is using cordovaTracker service. Whenever a tracking event is dispatch
 * with cordovaTracker.track method, it is handled inside this epic by obtaining an event name and
 * data accordingly to the Appsflyer requirements.
 */
export const trackCordovaEpic: IEpic = (_, { cordovaTracker, state$ }) =>
  ignore(cordovaTracker.actions().pipe(withLatestFrom(state$), tap(sendTrackingEvent)));

const sendTrackingEvent = async ([action, state]: [
  ITrackEventAction<unknown>,
  IApplicationState,
]) => {
  const { isIos: isIosUserAgent } = useUserAgent(window.navigator.userAgent);
  const isIosPlatform = getIsIos(state);
  const isIos = isIosUserAgent || isIosPlatform;

  // AppTrackingTransparency is ios only plugin
  if (!isIos) return;

  if (!AppTrackingTransparency) return;

  const { status } = await AppTrackingTransparency.getStatus();
  if (status !== 'authorized') {
    return;
  }

  const [event, props] = trackingMappers[action.event](action, state);

  // For testing purposes allow to console log events with props for env other than prod
  if (!env.LIVE) {
    /* eslint-disable no-console */
    console.log('Appsflyer.logEvent has been called with:', event, props);
  }
  Appsflyer.logEvent(event, props);
};
