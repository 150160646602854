import { find, forEach, values } from 'ramda';
import uuid from 'uuid-random';
import { Identified } from '@bridebook/models/source/abstract/_';
import { IS3File } from '@bridebook/ui';
import { ScrapbookActionTypes } from 'lib/scrapbook/action-types';
import { IDeps } from 'lib/types';
import { INote, IScrapbookPhoto, ScrapbookNoteEditMode } from './types';

/**
 * Scrapbooknotes fetched/updated from firebase
 * @param {*} snaphot
 */

export const onFirebaseData = (data: { [id: string]: INote & Identified }) => ({
  type: ScrapbookActionTypes.ON_FIREBASE_SCRAPBOOK_NOTES,
  payload: { notes: values(data) },
});

/**
 * creates empty note
 */
export const createNote = () => () => {
  const note: Partial<INote> = {
    id: uuid(),
    title: '',
    body: '', // only text
    // photos: null,
    // createdAt: 0,
    // updatedAt: null,
  };
  return {
    type: ScrapbookActionTypes.CREATE_EMPTY_NOTE,
    payload: { note },
  };
};

/**
 * Triggers note popup to open
 * @param {string} id
 * @param { 'add' | 'view' | 'edit' | 'closed '} mode
 */
export const openNotePopup = (id: string, mode: ScrapbookNoteEditMode) => ({
  type: ScrapbookActionTypes.OPEN_NOTE_POPUP,
  payload: { id, mode },
});

/**
 * Trigger deleting photo from the firebase and S3
 * @param {string} id
 * @param {Object} photo
 */
export const deleteNotePhoto = (id: string, photo: IScrapbookPhoto) => ({
  type: ScrapbookActionTypes.DELETE_NOTE_PHOTO_FROM_S3,
  payload: { photo, id },
});

/**
 * Closes popup
 */
export const closeNotePopup =
  () =>
  ({ dispatch, getState }: IDeps) => {
    const {
      form: { note },
      notes,
    } = getState().scrapbook;
    const isNewNote = note && notes && !find((n: INote) => n.id === note.id)(notes);
    if (isNewNote && note && note.photos) {
      forEach((photo: IScrapbookPhoto) => {
        dispatch(deleteNotePhoto(note.id, photo));
        // @ts-ignoreFIXME
      })(Object.keys(note.photos).map((key: string) => note.photos[key]));
    }
    return {
      type: ScrapbookActionTypes.CLOSE_NOTE_POPUP,
      payload: { mode: 'closed' },
    };
  };

/**
 * Whenever validation error happens this function runs
 * you can add another trigger if needed, by filtering this action
 * @param {Error} error
 */
export const scrapbookValidationError = (error: Error) => ({
  type: ScrapbookActionTypes.SCRAPBOOK_VALIDATION_ERROR,
  payload: { error },
});

/**
 * triggers validation and save to epics action
 *  @param {ScrapbookNote} note
 */

export const validateAndSaveNote = (note: INote) => ({
  type: ScrapbookActionTypes.SCRAPBOOK_NOTE_VALIDATE,
  payload: { note },
});

/**
 *
 * @param {INote} note
 */
export const validitionSuccess = (note: INote) => ({
  type: ScrapbookActionTypes.SCRAPBOOK_NOTE_VALIDATE_SUCCESS,
  payload: { note },
});

/**
 *
 * @param {INote} note
 */
export const firebaseSaveSuccess =
  (note: INote) =>
  ({ dispatch }: IDeps) => {
    /** Can be FieldValue OR Timestamp, luckily both support isEqual() */
    if (note.updatedAt?.isEqual(note.createdAt)) {
      dispatch({
        type: ScrapbookActionTypes.ADDED_NOTE_ANALYTICS,
        payload: {
          note,
        },
      });
    } else {
      dispatch({
        type: ScrapbookActionTypes.EDITED_NOTE_ANALYTICS,
        payload: {
          note,
        },
      });
    }

    return {
      type: ScrapbookActionTypes.SAVE_NOTE_TO_FIREBASE_SUCCESS,
      payload: { note },
    };
  };

/**
 * Start deleting note epics
 * @param {string} id
 */
export const deleteNoteFromDB = (id: string) => ({
  type: ScrapbookActionTypes.DELETE_NOTE_FROM_FIREBASE,
  payload: { id },
});

/**
 * Start deleting note epics
 * @param {string} id
 */
export const deleteSuccess = (id: string) => ({
  type: ScrapbookActionTypes.DELETE_NOTE_FROM_FIREBASE_SUCCESS,
  payload: { id },
});

/**
 * Start deleting note epics
 * @param {string} fieldName
 * @param {string} value
 */
export const editField = (fieldName: string, value: any) => ({
  type: ScrapbookActionTypes.EDIT_FIELD,
  payload: { fieldName, value },
});

/**
 * User clicks on add note button
 * ; triggers new note creation and popup to open
 * ; new note will be created and popup is opened when no popup is present
 */
export const addNoteTrigger =
  () =>
  ({ dispatch }: IDeps) => {
    dispatch(createNote());
    dispatch(openNotePopup('empty_note', ScrapbookNoteEditMode.Add));
    dispatch({ type: ScrapbookActionTypes.ADD_NOTE_TRIGGER_ANALYTICS });

    return { type: ScrapbookActionTypes.ADD_NOTE_TRIGGER };
  };

/**
 * User clicks for opening note
 * ; triggers opening note if possible
 * if popup is already open, validationmessage triggers
 *  * @param {string} id
 */
export const openNoteTrigger =
  (id: string) =>
  ({ getState, dispatch }: IDeps) => {
    const {
      scrapbook: { mode },
    } = getState();
    if (mode !== 'closed') {
      dispatch(
        scrapbookValidationError(
          new Error(`Popup is already open with another note in ${mode} mode`),
        ),
      );
    } else {
      dispatch(openNotePopup(id, ScrapbookNoteEditMode.View));
    }

    dispatch({
      type: ScrapbookActionTypes.OPEN_NOTE_TRIGGER_ANALYTICS,
      payload: { id },
    });

    return {
      type: ScrapbookActionTypes.OPEN_NOTE_TRIGGER,
      payload: { id },
    };
  };

/**
 * User clicks on edit note button
 * Will open edit mode for note
 * opens popup if not opened yet
 * if another note popup is already open with differnt id, validationmessage occurs
 *  * @param {string} id
 */
export const editNoteTrigger =
  (id: string) =>
  ({ dispatch, getState }: IDeps) => {
    const {
      scrapbook: {
        mode,
        form: { note },
      },
    } = getState();
    if (mode !== ScrapbookNoteEditMode.Closed && note && id !== note.id) {
      dispatch(
        scrapbookValidationError(
          new Error(`Popup is already open with another note in ${mode} mode`),
        ),
      );
    } else {
      dispatch(openNotePopup(id, ScrapbookNoteEditMode.Edit));
    }

    dispatch({
      type: ScrapbookActionTypes.EDIT_NOTE_TRIGGER_ANALYTICS,
      payload: { note },
    });

    return {
      type: ScrapbookActionTypes.EDIT_NOTE_TRIGGER,
      payload: { id },
    };
  };

export const saveNoteTrigger =
  (id: string, mode: ScrapbookNoteEditMode) =>
  ({ dispatch, getState }: IDeps) => {
    const {
      scrapbook: {
        form: { dirty, note },
      },
    } = getState();
    if (!dirty) {
      dispatch({ type: ScrapbookActionTypes.SWITCH_TO_VIEW, payload: {} });
    }
    if (note && id !== note.id) {
      dispatch(
        scrapbookValidationError(
          new Error(
            'You want to save another note than the currently selected one. Action not permitted.',
          ),
        ),
      );
    } else if (!note) {
      dispatch(scrapbookValidationError(new Error('There is no note to save')));
    } else if (dirty) {
      dispatch(validateAndSaveNote(note));
    }
    return {
      type: ScrapbookActionTypes.SAVE_NOTE_TRIGGER,
      payload: { id, dirty, mode },
    };
  };

export const addPhotoAnalytics = (photo: IScrapbookPhoto) => ({
  type: ScrapbookActionTypes.ADD_PHOTO_TO_NOTE_ANALYTICS,
  payload: { photo },
});

export const addPhotoErrorAnalytics = (error: string) => ({
  type: ScrapbookActionTypes.ADD_PHOTO_TO_NOTE_ERROR_ANALYTICS,
  payload: { error },
});

export const deletePhotoAnalytics = (note: INote, photo: IScrapbookPhoto) => ({
  type: ScrapbookActionTypes.DELETE_PHOTO_FROM_NOTE_ANALYTICS,
  payload: { note, photo },
});

export const deletePhotoSuccess =
  (note: INote, photo: IScrapbookPhoto) =>
  ({ dispatch }: IDeps) => {
    dispatch(deletePhotoAnalytics(note, photo));

    return {
      type: ScrapbookActionTypes.DELETE_PHOTO_FROM_NOTE_SUCCESS,
      payload: { note },
    };
  };

export const multiUploadFinish = (s3files: Array<IS3File>, photos?: INote['photos']) => ({
  type: ScrapbookActionTypes.SCRAPBOOK_MULTIUPLOAD_FINISH,
  payload: { s3files, photos },
});
