import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  toggleCustomSupplierFormModal,
  toggleGoogleSearchModal,
  toggleSupplierAddModal,
} from 'lib/shortlist/actions';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';

export const closeAddSupplierModalsEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType('ROUTE_CHANGE_COMPLETE'),
    withLatestFrom(state$),
    mergeMap(([, state]: [Action, IApplicationState]) => {
      const { showCustomSupplierFormModal, addSupplierModalOpened, showSearchGoogleModal } =
        state.shortlist;

      const actions = [];
      if (showCustomSupplierFormModal) {
        actions.push(toggleCustomSupplierFormModal(false, false, 'browserBackButton'));
      }

      if (addSupplierModalOpened) {
        actions.push(toggleSupplierAddModal(false));
      }

      if (showSearchGoogleModal) {
        actions.push(toggleGoogleSearchModal({ show: false }));
      }

      return of(...actions);
    }),
  );
