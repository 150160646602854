import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SearchActionTypes } from 'lib/search/action-types';
import { Action } from 'lib/types';

export const searchUrlUpdateEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(SearchActionTypes.UPDATE_SEARCH_URL),
    switchMap(() => {
      const resultList = document.getElementById('search-list-results');

      if (resultList) {
        resultList.scrollTo(0, 0);
      }

      return of();
    }),
  );
