import { Timestamp } from 'firebase/firestore';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IFeedback } from '@bridebook/models/source/models/Suppliers/Feedback.types';
import { IFeedbackSerialized } from 'app-shared/lib/supplier/types';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { ReviewFormFields } from 'lib/types';

export const addSupplierFeedback = async (
  supplier: ISupplier,
  formFields: ReviewFormFields,
  userId?: string,
) => {
  const feedback: Omit<IFeedback, 'id' | 'createdAt'> = {
    type: 'review',
    stars: formFields.stars,
    dateWedding: Timestamp.fromDate(new Date(formFields.weddingDate)),
    from: formFields.from as IFeedbackSerialized['from'],
    body: formFields.message,
    name: formFields.name,
    title: formFields.title,
    email: formFields.email,
  };

  if (userId) {
    feedback.user = userId;
  }

  if (formFields.photo && 'public_id' in formFields.photo && formFields.photo.public_id) {
    feedback.photos = [
      {
        path: `${formFields.photo.public_id}.${formFields.photo.ext}`,
        portrait: formFields.photo.portrait,
      },
    ];
  }

  const response = await fetch(ApiEndpoint.supplier.feedback.add, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ supplierId: supplier.id, feedback }),
  });

  return response.json();
};
