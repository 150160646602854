export enum ChecklistGroupIDs {
  'Invite Your Partner' = '74256d95-d112-4065-af12-6efc7e12d5be',
  'Start Guest List' = 'cac1bd5b-5feb-49c2-a785-8636a31191a4',
  'Outline Your Budget' = '43a84475-5ae8-4019-8ad5-d402641b6151',
  'Start Saving Notes' = 'dde13c2a-9bf5-4db1-9e14-d5d345885ebb',
  'Send Your Save-The-Dates' = 'b44c6063-118c-4225-84f0-6b40dc64063c',
  'Begin Exploring Venues' = 'c4bacd65-bbfd-4918-90af-7cc55dd40ec5',
  'Start Researching Venues' = 'a39eed10-7b3b-41bf-b379-7b12470048b8',
  'Schedule Visits of Venues' = '598e2dbb-fb09-4797-a005-ba24ba805dc3',
  'Choose Your Venue' = 'efdabbd1-d29b-4714-a762-add2e153c7c2',
  'Book Your Venue' = '577a3fb5-b544-4b78-a3e8-656f46b7373a',
  'Snap up a Photographer' = 'ec371c84-7a08-4c11-88ae-b02ceb8b34f1',
  'Find Your Florist' = '8614f58b-4107-4e62-af55-71a7df0c5a1a',
  'Shop for Wedding Dresses' = 'b567a64e-f1a2-4a67-ad52-5c506c968cce',
  'Book Your Caterer' = 'cafc879d-79b7-42cf-afb4-9cedbeae3866',
  'Book Your Reception Music' = 'cc062883-e803-4c9f-9994-85adbb011a7d',
  'Book Your Cake Maker' = '230fff48-cd95-4f88-b62b-1b50aefabdfe',
  'Select Your Stationery' = '2046d975-6f22-411f-890b-c016d5d8b239',
  'Choose Your Transport' = '9c09be02-6189-4556-99d2-8535af79cc86',
  'Shop for Men Fashion' = '1e45102d-c3af-4c7d-bcb1-0e13700108db',
  'Buy Wedding Rings' = '438b8e6b-abb5-4558-b4a8-6e85b86bda08',
  'Book Beauty Stylists' = '4fb727b2-991a-43d5-8146-2b82b8e607a1',
}

export enum ChecklistSubtaskIDs {
  'Click invite collaborator' = 'cd13184f-ebaa-46ae-88f0-db08e9832081',
  'Add partners email' = 'e9ce1154-3c04-4d52-9fc1-10887d0f8d37',
  'Start adding guests' = '1c6eada0-57d1-447b-861a-e212c5a4b875',
  'Activate budget' = 'd012806b-a7fc-4759-89e1-0657fc72050d',
  'Activate scrapbook' = '3c81abd0-4a87-4a1c-b522-6e8277124712',
  'Use scrapbook' = 'cf50c732-d640-4ec1-9ba4-246b6cea8ae9',
  'Finalise and export guest list' = '714ebbfc-483a-4823-96fb-5eecc8ce9fdf',
  'Mark venue as booked' = 'da6414c2-44db-4dd2-be1b-fc3d0822cf03',
  'Mark photographer as booked' = '97041d11-00b4-41f7-a8c1-8ee26734379d',
  'Mark florist as booked' = '4cf3ab10-648e-4874-9999-73701d9ab57f',
  'Mark dress as booked' = 'df2d0071-0155-4b86-8cba-3565b61a1027',
  'Mark catering as booked' = '17f3a2c5-1595-4b17-82aa-6d1fcb6eff30',
  'Mark music as booked' = 'a11e1eba-cbde-41eb-9fcb-3e0cafa9eb9a',
  'Mark cakes as booked' = 'ed68bc80-4c91-4fcf-b23e-a49ef5d7ee72',
  'Mark stationery as booked' = '381ea600-642c-45a1-ac5c-480e742f7861',
  'Mark transport as booked' = '260ab776-5afa-4b05-ab77-4f5ac93378d3',
  'Mark men fashion as booked' = '08cb6305-f983-439e-ad64-c943f5e2bc46',
  'Mark wedding rings as booked' = '1004ec5b-94e8-455a-9778-17c720d5c99a',
  'Mark beauty stylists as booked' = 'e43d6807-b087-45dc-ae59-f12af810ff1a',
}
