import { ofType } from 'redux-observable';
import { Observable, Subscriber, combineLatest, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import { Action } from '@reduxjs/toolkit';
import { AuthActionTypes, UserSetupCompletedAction } from 'lib/auth/action-types';
import { orderSupplierTypesByBookingPriority } from 'lib/search-landing/utils';
import { updateSearchCategory } from 'lib/search/actions';
import { isSearchResultsPage } from 'lib/search/utils';
import { FetchUserShortlistSuccessAction, ShortlistActionTypes } from 'lib/shortlist/action-types';
import { getBookedSupplierCategories } from 'lib/shortlist/selectors';
import { getListOfRemainingSuppliers } from 'lib/supplier/utils/get-list-of-remaining-suppliers';
import { IApplicationState, IEpicDeps } from 'lib/types';
import { withMarket } from 'lib/utils/operators/with-market';
import { IUpdateWeddingAction, WeddingActionTypes } from 'lib/weddings/action-types';
import { isBrideOnlyWedding, isGroomOnlyWedding } from 'lib/weddings/selectors';

type ObserverNextType = ReturnType<typeof updateSearchCategory>;

const nextSupplierCategory = (
  state: IApplicationState,
  observer: Subscriber<ObserverNextType>,
  market: Market,
  wedding?: IWedding,
) => {
  if (wedding) {
    const supplierTypesByBookingPriority = orderSupplierTypesByBookingPriority(market.suppliers);
    const booked = getBookedSupplierCategories(state) ?? [];
    const isGroomOnly = isGroomOnlyWedding(state);
    const isBrideOnly = isBrideOnlyWedding(state);
    const prevCategory = state.search.request.slug;
    const remainingSuppliers = getListOfRemainingSuppliers(
      supplierTypesByBookingPriority,
      booked,
      isGroomOnly,
      isBrideOnly,
    );

    const nextCategory = remainingSuppliers[0] || 'venue';
    if (nextCategory !== prevCategory) {
      observer.next(updateSearchCategory(nextCategory, isSearchResultsPage(state.app.pathname)));
    }
  }
};

export const updateSearchbarCategoryOnProfileChangeEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<Action> => {
  const shortlistSuccess$ = action$.pipe<FetchUserShortlistSuccessAction>(
    ofType(ShortlistActionTypes.FETCH_USER_SHORTLIST_SUCCESS),
  );
  const weddingUpdate$ = action$.pipe<IUpdateWeddingAction>(
    ofType(WeddingActionTypes.UPDATE_WEDDING),
  );
  const userSetupCompleted$ = action$.pipe<UserSetupCompletedAction>(
    ofType(AuthActionTypes.USER_SETUP_COMPLETED),
  );

  return combineLatest([shortlistSuccess$, weddingUpdate$, userSetupCompleted$]).pipe(
    withLatestFrom(state$),
    mergeMap(([[_, weddingUpdateAction, userSetupCompletedAction]]) =>
      withMarket(state$)(of(weddingUpdateAction || userSetupCompletedAction)).pipe(
        mergeMap(({ state, market }) => {
          const actionPayload = (weddingUpdateAction || userSetupCompletedAction).payload;
          const { wedding } = actionPayload;
          return new Observable<Action>((observer) => {
            nextSupplierCategory(state, observer, market, wedding);
            observer.complete();
          });
        }),
      ),
    ),
  );
};
