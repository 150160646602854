import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import slugify from 'slugify';
import { PremiumTiers } from '@bridebook/toolbox';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { fetchLocationData } from 'app-shared/lib/search/utils';
import { CATEGORIES_OPTIONS } from 'components/quiz/at-your-venue-quiz/at-your-venue-quiz-steps/constants';
import { appError } from 'lib/app/actions';
import { getCountryCode } from 'lib/i18n/selectors';
import { IChangedSearchLocationAnalytics, SearchActionTypes } from 'lib/search/action-types';
import { fetchSearchPromise } from 'lib/search/actions';
import { getWeddingVenue } from 'lib/shortlist/selectors';
import { IEpicDeps, IFetchSearchPromiseResult } from 'lib/types';
import { identifyVenueRecommendedSuppliers } from 'lib/ui/actions';
import {
  pickMaxThreeSuppliersPerType,
  shuffleRecommendedSuppliers,
} from './recommended-supplier-pick-helpers';

export const recommendedSuppliers = (
  action$: Observable<IChangedSearchLocationAnalytics>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      //  TODO: [i18n][bb-global] Hook cannot be used inside epic
      const weddingCountry = state.weddings.profile.l10n.country;
      const market = gazetteer.getMarketByCountry(weddingCountry);
      const bookedVenue = getWeddingVenue(state);
      const area = action.payload.newSearchTerm;

      if (bookedVenue) return of();

      const searchParams = {
        slug: undefined,
        oldSlug: true,
        isLoggedIn: true,
      };
      const isValidResult = (suppliers: IUISupplier[]) => {
        const validTierResults = suppliers.filter(
          (s) => (s?.tier === undefined ? -1 : s.tier) >= PremiumTiers.Tier_0,
        );
        return validTierResults.length > 0;
      };

      const countryCode = getCountryCode(state);

      const getPromise = async () => {
        const locationData = await fetchLocationData({
          area: slugify(area),
          market,
        });
        if (!countryCode) return of();

        const query = {
          ...searchParams,
          area,
          locationData,
          isLoggedIn: true,
          countryCode,
          size: 100,
        };
        return fetchSearchPromise(query);
      };

      return from(getPromise()).pipe(
        mergeMap((list) => {
          const filteredList = pickMaxThreeSuppliersPerType(
            shuffleRecommendedSuppliers(
              [
                ...(list as IFetchSearchPromiseResult).results
                  .filter((item) => item.type !== 'venue')
                  .filter((item) => item.profileScore < 0.7),
              ],
              ['photo'],
            ),
            new Set(CATEGORIES_OPTIONS),
          );

          if (isValidResult(filteredList)) {
            return of(identifyVenueRecommendedSuppliers(filteredList, 'recommendedLocalSuppliers'));
          }

          return of();
        }),
        catchError((error: Error) =>
          of(appError({ error, feature: 'Venue recommended suppliers' })),
        ),
      );
    }),
  );
