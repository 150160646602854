import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { universalLinksRedirect } from 'lib/app/utils/universal-links-redirect';
import { AuthActionTypes } from 'lib/auth/action-types';
import { Action, IEpicDeps } from 'lib/types';
import { isCordovaApp } from 'lib/utils';
import { appOpenedFromUrl, appUrlOpenListenerRegistered } from '../actions';

let universalLinksSubscribed = false;

export const clearUniversalLinksSubscribedFlag = () => {
  universalLinksSubscribed = false;
};

/**
 * An epic that listens for the `REDIRECT_AFTER_AUTH_DONE` and
 * `USER_IS_SIGNED_OUT` action to register a listener for the
 * `appUrlOpen` event emitted by Capacitor's `App` plugin.
 *
 * When the event is triggered, the URL is decoded and passed to the
 * `universalLinksRedirect` function, which redirects the user to the
 * appropriate page or the In-App-Browser. The epic then dispatches the
 * `appOpenedFromUrl` action with the event data.
 */
export const appUrlOpenEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
): Observable<any> => {
  const addAppUrlOpenListener = () =>
    new Observable((observer) => {
      if (universalLinksSubscribed) {
        return;
      }

      universalLinksSubscribed = true;

      App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
        if (data.url) {
          universalLinksRedirect(decodeURIComponent(data.url));
          observer.next(appOpenedFromUrl(data));
        }
      });

      observer.next(appUrlOpenListenerRegistered());
    });

  /**
   * You may ask, why is it important to start the listener if the
   * user is signed out? The reason is that to make collaborator
   * invitations work, we need to be able to navigate the user to the
   * invitation link, even if the user is signed out.
   */
  return action$.pipe(
    ofType(AuthActionTypes.USER_SETUP_COMPLETED, AuthActionTypes.USER_IS_SIGNED_OUT),

    withLatestFrom(state$),
    filter(([, state]) => state.app.device.isCordova || isCordovaApp()),
    mergeMap(() => addAppUrlOpenListener()),
  );
};
