import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Users } from '@bridebook/models';
import { AuthActionTypes, UserSetupCompletedAction } from 'lib/auth/action-types';
import { IEpicDeps } from 'lib/types';
import { onUserListener } from 'lib/users/actions';

export const initUserListener = (
  action$: Observable<UserSetupCompletedAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(AuthActionTypes.USER_SETUP_COMPLETED),
    withLatestFrom(state$),
    mergeMap(([{ payload }]) => {
      const userId = payload.firebaseUser.uid;

      if (!userId) {
        return of();
      }

      return Users._.getById(userId)
        .observe()
        .pipe(
          filter((data) => data && data.createdAt !== null),
          switchMap((data) => {
            if (data === null) {
              return of(onUserListener(null));
            }
            return of(onUserListener(data));
          }),
          takeUntil(action$.pipe(ofType(AuthActionTypes.ON_FIREBASE_SIGN_OUT))),
        );
    }),
  );
