import { INote } from '@bridebook/models/source/models/Weddings/Notes.types';

export interface StylingProps {
  isMobile: boolean;
  isTablet: boolean;
  isLandscape: boolean;
}

export type { INote };

export interface IScrapbookPhoto {
  path: string;
}

export enum ScrapbookNoteEditMode {
  Edit = 'edit',
  Add = 'add',
  View = 'view',
  Closed = 'closed',
}

export interface IScrapbookState {
  notes: Array<INote>;
  mode: ScrapbookNoteEditMode;
  uninitialized: boolean;
  form: {
    note: INote | null;
    dirty: boolean;
    error?: Error | null;
    shownError?: Error | null;
    showConfirmation?: boolean;
  };
}
