import { ofType } from 'redux-observable';
import { from, merge, partition } from 'rxjs';
import { ignoreElements, map, mergeMap, pluck, tap } from 'rxjs/operators';
import { openedLostLeadModalAnalytics } from 'lib/lost-lead/analytics/actions';
import {
  ISupplierInterestConfirmationStorage,
  SupplierInterestConfirmationStorage,
} from 'lib/storage-manager/basic/supplier-interest-confirmation';
import { IEpic, TUIShortlistSupplier, VenueConfirmSlides } from 'lib/types';
import {
  ConfirmSupplierInterestAction,
  DenySupplierInterestAction,
  SaveSupplierIdToConfirmAction,
  VenueConfirmActionTypes,
  VenueConfirmChangeSlideAction,
} from 'lib/venue-confirmation/action-types';
import {
  closeBookingConfirmationModal,
  saveSupplierIdToConfirm,
  venueConfirmChangeSlide,
} from 'lib/venue-confirmation/actions';
import { confirmOrDenyInterestAnalytics } from 'lib/venue-confirmation/analytics/actions';

type InputActions = ConfirmSupplierInterestAction | DenySupplierInterestAction;
type OutputActions = SaveSupplierIdToConfirmAction | VenueConfirmChangeSlideAction | any; // because of thunks returned

/**
 * An Epic that handles confirming or denying supplier interest.
 */
export const confirmOrDenySupplierInterestEpic: IEpic<InputActions, OutputActions> = (action$) => {
  const [confirm$, denies$] = partition<InputActions>(
    action$.pipe(
      ofType(
        VenueConfirmActionTypes.CONFIRM_SUPPLIER_INTEREST,
        VenueConfirmActionTypes.DENY_SUPPLIER_INTEREST,
      ),
    ),
    isConfirmation,
  );

  return merge(
    merge(confirm$, denies$).pipe(
      pluck<InputActions, TUIShortlistSupplier>('payload'), // Extracts the 'payload' property from actions
      mergeMap((data) =>
        from(SupplierInterestConfirmationStorage.get()).pipe(
          map<
            ISupplierInterestConfirmationStorage | null,
            [TUIShortlistSupplier, ISupplierInterestConfirmationStorage | null]
          >((storedData) => [data, storedData]),
          tap(([supplier, storedData]) => {
            // Mark the supplier as shown in stored data
            SupplierInterestConfirmationStorage.set({
              ...storedData,
              suppliersShown: {
                ...storedData?.suppliersShown,
                [supplier.id]: true,
              },
            });
          }),
          ignoreElements(),
        ),
      ),
    ),
    // Handle confirmations
    confirm$.pipe(
      pluck<InputActions, TUIShortlistSupplier>('payload'),
      mergeMap((supplier) => [
        closeBookingConfirmationModal(),
        saveSupplierIdToConfirm(null),
        confirmOrDenyInterestAnalytics(true, supplier),
      ]),
    ),
    // Handle denies
    denies$.pipe(
      pluck<InputActions, TUIShortlistSupplier>('payload'),
      mergeMap((supplier) => [
        venueConfirmChangeSlide(VenueConfirmSlides.lostLead),
        openedLostLeadModalAnalytics('venueSpecificPopup', supplier),
        confirmOrDenyInterestAnalytics(false, supplier),
      ]),
    ),
  );
};

const isConfirmation = ({ type }: InputActions) =>
  type === VenueConfirmActionTypes.CONFIRM_SUPPLIER_INTEREST;
