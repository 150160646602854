import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import slugify from 'slugify';
import { PremiumTiers } from '@bridebook/toolbox';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { fetchLocationData } from 'app-shared/lib/search/utils';
import { CATEGORIES_OPTIONS } from 'components/quiz/at-your-venue-quiz/at-your-venue-quiz-steps/constants';
import { appError } from 'lib/app/actions';
import { getCountryCode } from 'lib/i18n/selectors';
import { fetchSearchPromise } from 'lib/search/actions';
import { AddedToBookedSuppliersAction, ShortlistActionTypes } from 'lib/shortlist/action-types';
import { fetchVenueRecommendedSuppliersSuccess } from 'lib/shortlist/actions';
import { IEpic } from 'lib/types';
import { identifyVenueRecommendedSuppliers } from 'lib/ui/actions';
import { noopAction } from 'lib/utils';
import { getLocationName } from 'lib/weddings/selectors';
import {
  filterVenueRecommendedSupplierOnly,
  pickMaxThreeSuppliersPerType,
  shuffleRecommendedSuppliers,
} from './recommended-supplier-pick-helpers';

export const venueRecommendedSuppliers: IEpic<AddedToBookedSuppliersAction, any> = (
  action$,
  { state$ },
) =>
  action$.pipe(
    ofType(ShortlistActionTypes.ADDED_TO_BOOKED_SUPPLIERS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const area = getLocationName(state) || 'UK';
      const booked = action.payload.item;
      const isVenue = action.payload.item.type === 'venue';
      const weddingCountry = getCountryCode(state);
      const market = gazetteer.getMarketByCountry(weddingCountry as CountryCodes);
      const profileScoreThreshold = 0.7;
      const numberOfRecommendedSuppliers = 20;

      if (!booked || !isVenue || booked.customSupplier) return of(noopAction());

      const isValidResult = (suppliers: IUISupplier[]) => {
        if (!suppliers || suppliers.length === 0) return false;
        const validTierResults = suppliers.filter(
          (s) => (s?.tier === undefined ? -1 : s.tier) >= PremiumTiers.Tier_0,
        );
        return validTierResults.length > 0;
      };

      const getPromise = async () => {
        const locationData = await fetchLocationData({
          area: slugify(area),
          market,
          slug: 'venue',
        });

        const query = {
          slug: undefined,
          area,
          locationData,
          isLoggedIn: true,
          countryCode: weddingCountry as CountryCodes,
          size: numberOfRecommendedSuppliers,
          ourVenue: booked.publicId,
        };
        return fetchSearchPromise(query);
      };

      return from(getPromise()).pipe(
        filter((list) => !!list?.results.length),
        mergeMap((list) => {
          const filteredList = pickMaxThreeSuppliersPerType(
            shuffleRecommendedSuppliers(
              [
                ...list.results
                  .filter((item) => item.type !== 'venue')
                  .filter((item) => item.profileScore < profileScoreThreshold)
                  .filter(filterVenueRecommendedSupplierOnly(booked.id)),
              ],
              ['photo'],
            ),
            new Set(CATEGORIES_OPTIONS),
          );

          if (isValidResult(filteredList)) {
            return of(
              fetchVenueRecommendedSuppliersSuccess(filteredList),
              identifyVenueRecommendedSuppliers(filteredList),
            );
          }

          return of();
        }),
        catchError((error: Error) =>
          of(appError({ error, feature: 'Venue recommended suppliers' })),
        ),
      );
    }),
  );
