import { SentryMinimal } from '@bridebook/toolbox/src/sentry';

type Opts = {
  error: unknown;
  extra?: Record<string, unknown>;
  location: string;
};

/**
 * This is used to get more information of the Capacitor state to try to find
 * the reason for this issue: LIVE-14722
 */
export const logDeviceInfoError = ({ error, location, extra }: Opts) => {
  if (!process.browser && process.env.NODE_ENV !== 'test') return;

  const capacitorContent = Object.keys(window.Capacitor || {});
  const capacitorPlugins = Object.keys(window.Capacitor?.Plugins || {});

  SentryMinimal().captureException(error, {
    tags: {
      // This allows to filter by this in Sentry in case there are multiple
      // errors
      ticket: 'LIVE-14722',
      source: 'logDeviceInfoError',
    },
    extra: {
      ...extra,
      capacitorContent,
      capacitorPlugins,
      location,
    },
  });
};
