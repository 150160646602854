import { isBefore, sub } from 'date-fns';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { VenueConfirmationStorage } from 'lib/storage-manager/basic/venue-confirmation';
import { IEpicDeps } from 'lib/types';
import { triggerBookingConfirmationModal } from 'lib/venue-confirmation/actions';
import { IUpdateWeddingAction, WeddingActionTypes } from 'lib/weddings/action-types';
import { getVenueBooked } from 'lib/weddings/selectors';

export const openVenuePopupAfterTimePeriod = (
  action$: Observable<IUpdateWeddingAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(WeddingActionTypes.UPDATE_WEDDING),
    withLatestFrom(state$),
    filter(([, state]) => !!state.users.user),
    take(1),
    mergeMap(([, state]) => {
      const isOldAccount = isBefore(
        state.users.user?.registeredAt as number,
        sub(Date.now(), { months: 2 }),
      );
      const venueBooked = getVenueBooked(state);
      const popupShownDate = VenueConfirmationStorage.get();

      const minTwoWeeksPassed = popupShownDate
        ? isBefore(new Date(popupShownDate), sub(Date.now(), { weeks: 2 }))
        : true;

      if (isOldAccount && !venueBooked && minTwoWeeksPassed) {
        return of(
          triggerBookingConfirmationModal({
            showIntro: true,
            location: 'venueConfirmationReminder',
            searchSupplierType: 'venue',
          }),
        );
      }

      return of();
    }),
  );
