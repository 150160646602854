import Router from 'next/router';
import { AnyAction } from 'redux';
import { ofType } from 'redux-observable';
import { combineLatest, partition } from 'rxjs';
import {
  filter,
  first,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  repeatWhen,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import {
  EnquiriesActionTypes,
  IEnquiryFormToggleAction,
  ISendEnquirySuccessAction,
} from 'lib/enquiries/action-types';
import { selectEnquiryFormContext } from 'lib/enquiries/selectors';
import { getIsShortlistedSuppliersLimitReached } from 'lib/shortlist/selectors';
import { isSupplierVenue } from 'lib/supplier/utils';
import { IEpic } from 'lib/types';
import { getAsPathNormalized } from 'lib/utils/url';

/**
 * Opens multi enquiry popup when user send non-venue enquiry. Prevents from reopening popup
 * when recommended enquiries are being send.
 */
export const openSupplierMultiEnquiryPopupOnEnquiryUpdate: IEpic<
  ISendEnquirySuccessAction | IEnquiryFormToggleAction,
  AnyAction
> = (action$, { state$ }) => {
  // Divide form toggle actions into two different sources.
  const [enquiryFormClose$, enquiryFormOpen$] = partition<IEnquiryFormToggleAction['payload']>(
    action$.pipe(
      ofType(EnquiriesActionTypes.ENQUIRY_FORM_TOGGLE),
      pluck<IEnquiryFormToggleAction, IEnquiryFormToggleAction['payload']>('payload'),
      filter(({ supplier }) => !!(supplier && isNonVenueSupplier(supplier))),
      // check if shortlisted supplier limit is reached
      withLatestFrom(state$),
      filter(([, state]) => !getIsShortlistedSuppliersLimitReached(state)),
      // map payload to expected format
      map(([data]) => data),
    ),
    ({ show }) => !show,
  );

  const succesfullEnquirie$ = action$.pipe(
    ofType(EnquiriesActionTypes.SEND_ENQUIRY_SUCCESS),
    pluck<ISendEnquirySuccessAction, IUISupplier>('payload'),
  );

  // Listen to form openings...
  return enquiryFormOpen$.pipe(
    // ...take one.
    first(),
    mergeMap(() =>
      // Wait for succesfull enquiry and form close...
      combineLatest([succesfullEnquirie$, enquiryFormClose$, state$]).pipe(
        // ...take one.
        first(),
        tap(([{ publicId }, , state]) => {
          const [asPath] = getAsPathNormalized().split('?');
          const { contextName } = selectEnquiryFormContext(state);
          const query = Router.query;
          const { searchParams, slug, id, ...filters } = query;

          // store existing filter params if any
          const filterParams = new URLSearchParams(filters as Record<string, string>).toString();

          // store full query
          const fullQueryParams = new URLSearchParams(query as Record<string, string>);
          // add params required to open multienquiry popup
          fullQueryParams.append('multiEnquiry', 'true');
          fullQueryParams.append('enquirySupplierId', publicId);
          fullQueryParams.append('enquiryContext', contextName || 'sideForm');

          Router.push(
            `${asPath}?${fullQueryParams.toString()}`,
            `${asPath}${filterParams.length ? '?' + filterParams : ''}`,
            {
              shallow: true,
              scroll: false,
            },
          );
        }),
      ),
    ),
    // Repeat when inner observable completes and form is closed.
    repeatWhen(() => enquiryFormClose$),
    ignoreElements(),
  );
};

const isNonVenueSupplier = (supplier: IUISupplier) => !isSupplierVenue(supplier);
