import { GuestlistActionTypes } from 'lib/guestlist/action-types';
import { clickedAddGuestsAnalytics } from 'lib/guestlist/analytics/actions';
import { CategoryDetailsPopupState, IGuestlistCategory } from 'lib/guestlist/types';
import { IDeps } from 'lib/types';

export const toggleCategoryDetailsPopup =
  (isVisible: boolean = false, category: IGuestlistCategory | null = null) =>
  ({ dispatch }: IDeps) => {
    if (isVisible) {
      dispatch({
        type: 'CLICKED_TO_EDIT_GUEST_CATEGORY_ANALYTICS',
        payload: { category },
      });
    }

    const payload: CategoryDetailsPopupState = {
      isVisible,
      category: isVisible ? category : null,
    };
    return {
      type: GuestlistActionTypes.TOGGLE_CATEGORY_DETAILS_POPUP,
      payload,
    };
  };

export const toggleAddGuestsPopup =
  (isVisible: boolean = false) =>
  ({ dispatch }: IDeps) => {
    if (isVisible) {
      dispatch(clickedAddGuestsAnalytics('bulkAddGuestPopup'));
    }
    return {
      type: GuestlistActionTypes.TOGGLE_ADD_GUESTS_POPUP,
      payload: isVisible,
    };
  };
