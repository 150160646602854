import { BudgetActionTypes } from 'lib/budget/action-types';
import { clickedResetBudgetAnalytics } from 'lib/budget/analytics/actions';
import { getI18n } from 'lib/i18n/getI18n';
import { IDeps } from 'lib/types';

export const triggerResetBudget =
  () =>
  ({ dispatch, warningModal }: IDeps) => {
    dispatch(clickedResetBudgetAnalytics());
    warningModal.toggleWarningModal({
      title: getI18n().t('resetWarning.heading'),
      subtitle: getI18n().t('resetWarning.subtitle'),
      onConfirm: () => dispatch(resetBudget()),
      confirmText: getI18n().t('button.reset'),
    });
    return {
      type: BudgetActionTypes.TRIGGER_RESET_BUDGET,
    };
  };

export const resetBudget = () => ({
  type: BudgetActionTypes.RESET_BUDGET,
});
