import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { IApplicationState, IEpicDeps } from 'lib/types';
import { onAccessControlListener } from '../actions';

export const initAccessControlListener = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType('INIT_WEDDING_LISTENER'),
    withLatestFrom(state$),
    filter(([_, state]: [any, IApplicationState]) => Boolean(state.weddings.profile.id)),
    mergeMap(([_, state]: [any, IApplicationState]) => {
      const weddingId = state.weddings.profile.id;

      return Weddings._.getById(weddingId)
        .Users.query()
        .observe()
        .pipe(
          map((data) => onAccessControlListener(data)),
          takeUntil(action$.pipe(ofType('STOP_WEDDING_LISTENER'))),
        );
    }),
  );
