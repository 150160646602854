import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import {
  AccessControlActionTypes,
  IOnAccessControlListenerAction,
} from 'lib/access-control/action-types';
import { fetchCollaboratorsEmailsSuccess } from 'lib/access-control/actions';
import { appError } from 'lib/app/actions';
import { IEpicDeps } from 'lib/types';
import { ICollaborator } from '../types';

export const fetchCollaboratorsEmails = (
  action$: Observable<IOnAccessControlListenerAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(AccessControlActionTypes.ON_ACCESS_CONTROL_LISTENER),
    withLatestFrom(state$),
    mergeMap(([{ payload: acl }, state]) => {
      if (!acl) {
        return of();
      }

      const { id: weddingId } = state.weddings.profile;

      const getPromise = async () => {
        const response = await authenticatedFetch('/api/nonces/fetch-collaborators', {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({
            weddingId,
          }),
        });

        if (!response.ok || response.status !== 200) {
          throw Error(`could not fetch collaborators for ${weddingId}`);
        }

        const responsePayload = await response.json();
        const { collaborators } = responsePayload;
        return collaborators;
      };

      return from(getPromise()).pipe(
        mergeMap((collaborators: ICollaborator[]) => {
          if (collaborators) {
            return of(fetchCollaboratorsEmailsSuccess(collaborators));
          }

          return of();
        }),
        catchError((error: Error) => of(appError({ error, feature: 'Access Control' }))),
      );
    }),
    catchError((error: Error) => of(appError({ error, feature: 'Access Control' }))),
  );
