import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { isISupplierType } from 'lib/supplier/utils';
import { mapEventToAppsFlyerEvent } from 'lib/track-utils/mappers/event-to-appsflyer-event';
import { ITrackingMapper } from 'lib/track-utils/tracking-mappers';

export const shortlistedSupplierMapper: ITrackingMapper<ISupplier | IUISupplier> = ({
  event,
  payload,
}) => [
  mapEventToAppsFlyerEvent(event),
  isISupplierType(payload)
    ? {
        af_content_id: payload.id,
        af_country: payload.address?.country,
        af_region: payload.address?.adminArea?.[1],
        af_content_type: payload.type[0],
      }
    : {
        af_content_id: payload.id,
        af_country: payload.country || payload.address?.country,
        af_region: payload.county || payload.address?.adminArea?.[1],
        af_content_type: payload.type,
      },
];
