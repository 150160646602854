import { BasicKeyedStorageManager } from '@bridebook/toolbox/src/storage-managers';

export interface IWeddingCountryChangedStorage {
  countryChanged: boolean;
}

export const WeddingCountryChangedStorage = (id: string) =>
  new BasicKeyedStorageManager<IWeddingCountryChangedStorage>({
    storageKey: 'wedding-country-changed-show-info',
    id,
  });
