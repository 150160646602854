import { AppsflyerEvent } from '@ionic-native/appsflyer';
import { ITrackEventAction } from 'lib/track-utils/actions';
import { budgetMapper } from 'lib/track-utils/mappers/calculate-budget-mapper';
import { commonMapper } from 'lib/track-utils/mappers/common-mapper';
import { initializedChecklistMapper } from 'lib/track-utils/mappers/initialized-checklist-mapper';
import { sendEnquiryMapper } from 'lib/track-utils/mappers/send-enquiry-mapper';
import { shortlistedSupplierMapper } from 'lib/track-utils/mappers/shortlisted-supplier-mapper';
import { updateMainTaskMapper } from 'lib/track-utils/mappers/update-main-task-mapper';
import { viewedSupplierProfileMapper } from 'lib/track-utils/mappers/viewed-supplier-profile-mapper';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import { IApplicationState } from 'lib/types';

export type ITrackingMapper<P = unknown> = (
  action: ITrackEventAction<P>,
  state: IApplicationState,
) => [string, AppsflyerEvent];

/**
 * A list of mappers following ITrackingMapper interface, that maps an event with its payload
 * to a format required by Appsflyer events:
 * https://support.appsflyer.com/hc/en-us/articles/115005544169-AppsFlyer-Rich-In-App-Events-Android-and-iOS#event-types
 */
export const trackingMappers: Record<TrackingEvent, ITrackingMapper<any>> = {
  [TrackingEvent.ViewedSupplierProfile]: viewedSupplierProfileMapper,
  [TrackingEvent.ShortlistedSupplier]: shortlistedSupplierMapper,
  [TrackingEvent.InitializedChecklist]: initializedChecklistMapper,
  [TrackingEvent.UpdatedBudget]: budgetMapper,
  [TrackingEvent.CalculatedBudget]: budgetMapper,
  [TrackingEvent.AddedGuest]: commonMapper,
  [TrackingEvent.AddedMultipleGuests]: commonMapper,
  [TrackingEvent.UpdatedMainTasks]: updateMainTaskMapper,
  [TrackingEvent.UpdatedSubtasks]: commonMapper,
  [TrackingEvent.InitializedGuestList]: commonMapper,
  [TrackingEvent.SendEnquiry]: sendEnquiryMapper,
  [TrackingEvent.UpdatedBudgetItem]: budgetMapper,
};
