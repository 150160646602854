import { getAuth, reauthenticateWithCredential, updateEmail } from 'firebase/auth';
import { EmailAuthProvider } from 'firebase/auth';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { AuthActionTypes, IChangeAuthEmailAction } from 'lib/auth/action-types';
import { AuthBridebookError } from 'lib/auth/types';
import authValidate from 'lib/auth/utils/auth-validate';
import { IEpicDeps } from 'lib/types';
import { changeAuthEmailError, changeAuthEmailSuccess } from '../actions';

export const changeAuthEmailEpic = (
  action$: Observable<IChangeAuthEmailAction>,
  { firebaseApp }: IEpicDeps,
) =>
  action$.pipe(
    ofType(AuthActionTypes.CHANGE_AUTH_EMAIL),
    mergeMap(({ payload: { fields, oldEmail } }) => {
      const getPromise = async () => {
        await authValidate(fields);
        const { currentUser } = getAuth(firebaseApp);
        const credential = EmailAuthProvider.credential(oldEmail, fields.password);

        if (!currentUser) {
          throw new Error('firebaseAuth().currentUser is null');
        }

        await reauthenticateWithCredential(currentUser, credential);

        const response = await updateEmail(currentUser, fields.email);

        return { fields, response };
      };

      return from(getPromise()).pipe(
        mergeMap(({ fields }) => of(changeAuthEmailSuccess(fields))),
        catchError((error: AuthBridebookError) => of(changeAuthEmailError(fields, error))),
      );
    }),
    catchError((error: Error) => of(appError({ error, feature: 'Auth' }))),
  );
